import {observer} from 'mobx-react-lite';
import {useRoot} from '../../../Root/hooks';
import {useStyles} from '../../../styling';
import {
  AppRouteName,
  BottomTabRouteName,
} from '../../../Navigation/AppRouteName';
import {useGetScreenTitle} from '../../../Navigation/hooks/useGetRouteSegments';
import React, {useCallback, useMemo} from 'react';
import {View} from 'react-native';
import getTabIcon from '../MobileTabBar/getTabIcon';
import AsideButton from './AsideButton';
import Typography from '../../Typography';
import useNotificationsCount from '../../../Navigation/BottomTab/useNotificationsCount';
import useGoToAddWorker from '../../../Navigation/BottomTab/useGoToAddWorker';
import {AppForceParamList} from '../../../Navigation/AppForceParamList';
import {NavigationHelpers} from '@react-navigation/native';
import {BottomTabNavigationEventMap} from '@react-navigation/bottom-tabs';

export type AppRoutesProps = {
  navigation: NavigationHelpers<AppForceParamList, BottomTabNavigationEventMap>;
  isExpanded: boolean;
  focusedRoute?: AppRouteName;
};

export default observer(function AppRoutes({
  navigation,
  isExpanded,
  focusedRoute,
}: AppRoutesProps) {
  const {debug} = useRoot();
  const getTitle = useGetScreenTitle();
  const goToAddWorker = useGoToAddWorker();
  const notificationsCount = useNotificationsCount();
  const routes = useMemo(() => {
    const result: BottomTabRouteName[] = [
      'MockAddWorker',
      'Dashboard',
      'Statistics',
      'Withdraw',
      'Notifications',
    ];
    if (debug.debugEnabled) {
      result.push('Debug');
    }
    return result;
  }, [debug.debugEnabled]);
  const onPress = (routeName: BottomTabRouteName, isFocused: boolean) => {
    if (routeName === 'MockAddWorker') {
      goToAddWorker();
    } else {
      const event = navigation.emit({
        type: 'tabPress',
        target: routeName,
        canPreventDefault: true,
      });
      if (!isFocused && !event.defaultPrevented) {
        navigation.navigate(routeName);
      }
    }
  };
  const renderIcon = useCallback(
    (routeName: BottomTabRouteName) => {
      const size = routeName === 'MockAddWorker' ? 40 : 30;
      const isActive = routeName === focusedRoute;
      const Icon = getTabIcon(routeName);
      if (!Icon) {
        return null;
      }
      if (routeName === 'Notifications') {
        return (
          <View>
            <Icon width={size} height={size} active={isActive} />
            <NotificationBadge count={notificationsCount} />
          </View>
        );
      }
      return <Icon width={size} height={size} active={isActive} />;
    },
    [focusedRoute, notificationsCount],
  );
  return (
    <View>
      {routes.map((routeName) => {
        const isFocused = routeName === focusedRoute;
        const press = () => onPress(routeName, isFocused);
        const disabled = routeName === focusedRoute;
        const title = getTitle(routeName);
        const testId = routeName.toLowerCase().replaceAll('/', '_').replaceAll(' ', '_');

        return (
          <AsideButton
            testID={`menu_${testId}`}
            key={routeName}
            routeName={routeName}
            disabled={disabled}
            isFocused={isFocused}
            renderIcon={() => renderIcon(routeName)}
            isExpanded={isExpanded}
            title={title}
            onPress={press}
          />
        );
      })}
    </View>
  );
});

type NotificationBadgeProps = {
  count?: number;
};

const NotificationBadge = observer(({count}: NotificationBadgeProps) => {
  const styles = useStyles((theme) => ({
    root: {
      position: 'absolute',
      right: -5,
      top: -5,
      height: 18,
      minWidth: 18,
      borderRadius: 9,
      backgroundColor: theme.colors.primaryAttractive,
      justifyContent: 'center',
      alignItems: 'center',
    },
    text: {
      textAlign: 'center',
      color: theme.colors.primaryBlack,
      fontSize: 12,
      lineHeight: 14,
    },
  }));
  if (!count) {
    return null;
  }
  return (
    <View style={styles.root}>
      <Typography style={styles.text} type="body" weight="500">
        {count}
      </Typography>
    </View>
  );
});
