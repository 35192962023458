import {useCallback} from 'react';
import {useRoot, useStrings} from '../Root/hooks';
import sleep from '../utils/sleep';
import {Platform} from 'react-native';

export default function useHSHBalance() {
  const {
    share,
    configuration: {values},
    auth,
    hshAffiliate,
    clipboard,
    flashMessages,
  } = useRoot();
  const strings = useStrings();
  const shareLink = useCallback(async () => {
    const message = hshAffiliate.shareText;

    const source = Platform.select({
      android: 'fpa',
      ios: 'fpi',
      default: 'fpw',
    });
    const ref = auth.accountId;

    const refLink = `${values.initUrl}?utm_source=${source}&utm_medium=bonus_str&utm_ref=${ref}`;
    const pageLink = `${values.tryProductsUrl}?utm_source=${source}&utm_medium=bonus_try&aid=${ref}`;
    if (!message) {
      return;
    }

    const messageWithLink = message
      .replace('{{ref_link}}', refLink)
      .replace('{{page_link}}', pageLink);

    try {
      clipboard.setString(messageWithLink);
      flashMessages.showMessage({
        title: strings['action.theValueHasBeenCopiedToClipboard'],
        variant: 'success',
        single: true,
      });
      await sleep(1000);
    } catch (ignore) {}

    try {
      await share.shareMessage(messageWithLink);
    } catch (ignore) {}
  }, [hshAffiliate, values, auth, share, clipboard, flashMessages, strings]);

  return {shareLink};
}
