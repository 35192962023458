import React from 'react';
import {observer} from 'mobx-react-lite';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {useStrings} from '../Root/hooks';
import {StyleSheet, View} from 'react-native';
import {variance} from '../styling';
import {BREAKPOINT_PX} from './constants';
import {Divider} from '../components/atoms';
import useSafePaddingBottom from '../components/templates/AdaptiveModal/useSafePaddingBottom';

export type FooterProps = {
  goToAffiliate: () => void;
  bordered: boolean;
};

export default observer(function Footer({
  goToAffiliate,
  bordered,
}: FooterProps) {
  const strings = useStrings();
  const paddingBottom = useSafePaddingBottom(16);
  return (
    <View style={{paddingBottom}}>
      {bordered && <Divider />}
      <View style={styles.content}>
        <ButtonContainerView>
          <AppButton variant={ButtonVariant.Primary} onPress={goToAffiliate} testID="modal_open_affiliate_btn">
            {strings['withdrawalHistory.affiliateButton']}
          </AppButton>
        </ButtonContainerView>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  content: {
    padding: 16,
    paddingBottom: 0,
  },
});

const ButtonContainerView = variance(View)((theme) => ({
  root: {
    ...theme.mediaQuery({
      [BREAKPOINT_PX]: {
        width: 328,
        alignSelf: 'center',
      },
    }),
  },
}));
