import React from 'react';
import {observer} from 'mobx-react-lite';
import {SubscriptionOffer} from '../TotalPriceView';
import {SubscriptionCandidateKind} from '../../ProposalsState';
import TotalPriceBtnAndGift from './TotalPriceBtnAndGift';
import {getPluralDayLocalKey} from './shared';
import {useIntlFormatter, useStrings, useTemplates} from '../../Root/hooks';
import getDaysByUnitAndValue from '../../InAppPurchaseManager/getDaysByUnitAndValue';

export type TotalPriceSmSubscriptionProps = {
  subscription: SubscriptionOffer;
  submitSubscriptionText: string;
  onAdditionalOfferPurchasePress?: () => void;
  onImprovePress?: () => void;
  onManagePress?: () => void;
  hideGift?: boolean;
};

export default observer(function TotalPriceSubscription(
  props: TotalPriceSmSubscriptionProps,
) {
  const {
    subscription,
    onAdditionalOfferPurchasePress,
    onImprovePress,
    hideGift,
    onManagePress,
    submitSubscriptionText,
  } = props;
  const templates = useTemplates();
  const {intFormatter} = useIntlFormatter();
  const strings = useStrings();
  if (!subscription) {
    return null;
  }
  return subscription.kind === SubscriptionCandidateKind.Available ? (
    <TotalPriceBtnAndGift
      hideGift={hideGift}
      onPress={onAdditionalOfferPurchasePress}
      subtitle={
        subscription.trial
          ? templates['totalPrice.trial.subtitle']({
              price: `${intFormatter(subscription.uiPrice)} ${
                subscription.currency
              }`,
              duration: `${subscription.interval} ${strings['common.shortMonth']}`,
            })
          : templates.subscriptionWarning({
              duration: `${subscription.interval} ${strings['common.shortMonth']}`,
            })
      }
      btnText={`${submitSubscriptionText}  ${
        subscription.trial
          ? templates[
              getPluralDayLocalKey(
                getDaysByUnitAndValue(
                  subscription.trial.value,
                  subscription.trial.unit,
                ),
              )
            ]({
              value: getDaysByUnitAndValue(
                subscription.trial.value,
                subscription.trial.unit,
              ),
            })
          : templates['totalPrice.subscriptionTitlePrice']({
              price: intFormatter(subscription.uiPrice),
              currency: subscription.currency,
              interval: subscription.interval,
            })
      }`}
    />
  ) : subscription.kind === SubscriptionCandidateKind.CanBeImproved &&
    onImprovePress !== undefined ? (
    <TotalPriceBtnAndGift
      onPress={onImprovePress}
      hideGift={hideGift}
      btnText={strings['totalPrice.improveSubscription']}
    />
  ) : subscription.kind === SubscriptionCandidateKind.CanBeManaged &&
    onManagePress !== undefined ? (
    <TotalPriceBtnAndGift
      hideGift={hideGift}
      onPress={onManagePress}
      btnText={strings['totalPrice.manageSubscription']}
    />
  ) : null;
});
