import React, {useCallback, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {RootStackBindingProps} from './RootStackBindingProps';
import {SignUpScreen} from '../../SignUpScreen';
import {useResetToDashboard} from '../hooks';

export type AuthBindingProps = RootStackBindingProps<'Auth'>;

export default observer((props: AuthBindingProps) => {
  const {navigation, route} = props;
  const navigationRef = useRef(navigation);
  navigationRef.current = navigation;
  const routeRef = useRef(route);
  routeRef.current = route;
  const goToOAuthSplash = useCallback(
    () => navigationRef.current.navigate('OAuthSplash'),
    [],
  );

  const onSuccess = useResetToDashboard(route.params?.onSuccess);

  const goToThisScreen = useCallback(
    () => navigationRef.current.navigate(routeRef.current),
    [],
  );
  const onRegisterNewFarmPress = useCallback(() => {
    navigation.navigate('CreateNewFarmWarning', {onSuccess});
  }, [navigation, onSuccess]);
  const getFarmId = useCallback(() => routeRef.current.params?.farmId, []);

  return (
    <SignUpScreen
      goToOAuthSplash={goToOAuthSplash}
      onSuccess={onSuccess}
      goToThisScreen={goToThisScreen}
      getFarmId={getFarmId}
      variant={route.params?.variant}
      onRegisterNewFarmPress={onRegisterNewFarmPress}
    />
  );
});
