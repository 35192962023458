import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {useTheme, variance} from '../../styling';
import {StyleSheet, View} from 'react-native';
import {AppButton, ButtonVariant} from '../../components/AppButton';
import {Body, Paragraph} from '../../components';
import {useRoot, useStrings} from '../../Root/hooks';
import {sized} from '../../Svg';
import GiftSvg from '../../assets/svg/colorless/gift-rounded.svg';
import {
  LG_BREAKPOINT,
  useGetDimensions,
  XL_BREAKPOINT,
} from '../../WindowDimensions/useDimensions';
import {expr} from 'mobx-utils';
import {VerticalDivider} from './shared';
import {AppButtonText} from '../../components/AppButton/AppButton';

export type TotalPriceMobileProps = {
  btnText: string;
  onPress?: () => void;
  subtitle?: string;
  hideGift?: boolean;
};

export default observer(function TotalPriceBtnAndGift(
  props: TotalPriceMobileProps,
) {
  const {btnText, subtitle, hideGift, onPress} = props;
  const {gift} = useRoot();
  const theme = useTheme();
  const string = useStrings();

  const {name, isDoubleGift} = useMemo(
    () => ({
      name: gift.poolGift?.pool_miner_config?.name || gift.poolGift?.plan?.name,
      isDoubleGift: gift.poolGift?.type === 'double',
    }),
    [gift.poolGift],
  );
  const getDimension = useGetDimensions();
  const isLg = expr(() => getDimension('lg'));
  return (
    <Container withoutGift={hideGift}>
      <BtnContainer>
        <AppButton variant={ButtonVariant.Primary} onPress={onPress}>
          {(hoverStyle, style) => (
            <View style={styles.submitButtonContentView}>
              <AppButtonText style={[hoverStyle, style]}>
                {btnText}
              </AppButtonText>
            </View>
          )}
        </AppButton>
        {subtitle && (
          <Subtitle
            style={[styles.submitButtonSubtitle]}
            type="paragraph"
            weight="400">
            {subtitle}
          </Subtitle>
        )}
      </BtnContainer>
      {isLg && <VerticalDivider />}
      {(name !== undefined || gift.isLoading) && !hideGift && (
        <GiftRow>
          <GiftTitleContainer>
            <GiftTitle type="body" weight="500">
              +
            </GiftTitle>
            <GiftIcon color={theme.colors.uiSecondary} />
            <GiftTitle offsetLeft type="body" weight="500">
              {string['purchase.gift']}
            </GiftTitle>
          </GiftTitleContainer>
          {name !== undefined && (
            <GiftValueContainer red={isDoubleGift}>
              <GiftValue
                numberOfLines={1}
                red={isDoubleGift}
                ellipsizeMode="tail"
                weight="500"
                type="body">
                {name}
              </GiftValue>
              {isDoubleGift && (
                <GiftValueBadge>
                  <SmGiftIcon color={theme.colors.primaryError} />
                </GiftValueBadge>
              )}
            </GiftValueContainer>
          )}
        </GiftRow>
      )}
    </Container>
  );
});

const GiftIcon = sized(GiftSvg, 20, 20);
const SmGiftIcon = sized(GiftSvg, 18, 18);

const styles = StyleSheet.create({
  submitButtonContentView: {
    rowGap: 4,
  },
  submitButtonSubtitle: {
    fontSize: 12,
    lineHeight: 15,
  },
});

const Container = variance(View)((theme) => ({
  root: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: theme.colors.backgroundGround,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row',
        paddingHorizontal: 0,
        paddingVertical: 0,
        height: '100%',
        flex: 7,
        alignItems: 'center',
      },
      [XL_BREAKPOINT]: {
        flex: 5,
      },
    }),
  },
  withoutGift: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: 4,
      },
      [XL_BREAKPOINT]: {
        flex: 4,
      },
    }),
  },
}));

const BtnContainer = variance(View)((theme) => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        padding: 16,
        flex: 1,
      },
    }),
  },
}));

const Subtitle = variance(Paragraph)(() => ({
  root: {
    textAlign: 'center',
    marginTop: 6,
  },
}));

const GiftRow = variance(View)((theme) => ({
  root: {
    marginTop: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 25,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        height: 70,
        marginTop: 16,
        margin: 16,
        alignItems: 'flex-start',
        flexDirection: 'column',
        borderRadius: 6,
        backgroundColor: theme.colors.uiAdditional1,
        padding: 8,
        width: 208,
      },
    }),
  },
}));

const GiftTitleContainer = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const GiftTitle = variance(Body)((theme) => ({
  root: {
    color: theme.colors.uiSecondary,
    fontSize: 14,
  },
  offsetLeft: {
    marginLeft: 6,
  },
}));

const GiftValueContainer = variance(View)((theme) => ({
  root: {
    paddingVertical: 2,
    paddingHorizontal: 6,
    backgroundColor: theme.colors.primarySuccess,
    borderRadius: 6,
    flexDirection: 'row',
    alignItems: 'center',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginLeft: 0,
        flex: 1,
        paddingHorizontal: 12,
        paddingVertical: 4,
        marginTop: 8,
        width: 192,
      },
    }),
  },
  red: {
    maxWidth: 200,
    backgroundColor: theme.colors.primaryError,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        maxWidth: undefined,
        paddingRight: 4,
      },
    }),
  },
}));

const GiftValue = variance(Body)((theme) => ({
  root: {
    color: theme.colors.primaryWhite,
    fontSize: 12,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        fontSize: 16,
      },
    }),
  },

  red: {
    flexShrink: 1,
  },
}));

const GiftValueBadge = variance(View)((theme) => ({
  root: {
    paddingHorizontal: 5,
    backgroundColor: theme.colors.backgroundGround,
    borderRadius: 4,
    marginLeft: 4,
  },
}));
