import React, {useCallback, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {MenuScreen} from '../../MenuScreen';
import {useOpenLink} from '../../Links';
import {useRoot} from '../../Root/hooks';
import {
  useSafelyGoToWithdraw,
  useSafelyGoToAffiliate,
  useNavigationGetIsFocused,
} from '../hooks';
import {
  NavigationState,
  PartialState,
} from '@react-navigation/routers/src/types';
import {RootParamList} from './index';
import {useOpenAffiliateOnUniqueToken} from './WithdrawalHistoryBinding';
import {createWithdrawState} from '../BottomTab/WithdrawBinding';
import {nanoid} from 'nanoid';
import {autorun} from 'mobx';
import {RootStackBindingProps} from './RootStackBindingProps';
import {AffiliateVariant} from '../hooks/useSafelyGoToAffiliate';

export type MenuBindingProps = RootStackBindingProps<'Menu'>;

export default observer((props: MenuBindingProps) => {
  const {navigation, route} = props;
  const {configuration, dashboardStore, auth, rateApp} = useRoot();
  const goToAuth = useCallback(() => navigation.navigate('Auth'), [navigation]);

  const goToLanguage = useCallback(
    () => navigation.navigate('PickLanguage'),
    [navigation],
  );
  const goToPlan = useCallback(() => navigation.navigate('Plan'), [navigation]);
  const goToWithdraw = useCallback(
    () => navigation.navigate('Root', {screen: 'Withdraw'}),
    [navigation],
  );

  const goToSignOut = useCallback(
    () => navigation.navigate('LogoutConfirm'),
    [navigation],
  );

  const goToAuthAndThenToWithdraw = useCallback(
    () => navigation.navigate('Auth', {onSuccess: createWithdrawState()}),
    [navigation],
  );
  const safelyGoToWithdraw = useSafelyGoToWithdraw(
    goToWithdraw,
    goToPlan,
    goToAuthAndThenToWithdraw,
  );
  const goToFarmSwitch = useCallback(
    () => navigation.navigate('AccountSwitch'),
    [navigation],
  );
  const goToQRCodeScanner = useCallback(
    () => navigation.navigate('QRCodeScanner'),
    [navigation],
  );
  const goToAuthAndThenOpenAffiliate = useCallback(
    () =>
      navigation.navigate('Auth', {
        onSuccess: createMenuState(nanoid()),
        variant: 'affiliate',
      }),
    [navigation],
  );
  const {safelyGoToAffiliate, getAffiliateIsPending} = useSafelyGoToAffiliate(
    goToAuthAndThenOpenAffiliate,
    AffiliateVariant.AppAbout,
  );
  const goToWithdrawalHistory = useCallback(
    () => navigation.navigate('WithdrawalHistory'),
    [navigation],
  );
  const goToDeleteAccount = useCallback(
    () => navigation.navigate('DeleteAccountConfirm'),
    [navigation],
  );
  const goToTutorial = useCallback(
    () => navigation.navigate('Tutorial'),
    [navigation],
  );
  const goToDescription = useOpenLink(configuration.values.aboutUrl);
  const {goToAffiliateToken} = route.params ?? {};
  useOpenAffiliateOnUniqueToken(goToAffiliateToken, safelyGoToAffiliate);
  const getIsFocused = useNavigationGetIsFocused();
  useEffect(
    () =>
      autorun(() => {
        if (getIsFocused() && auth.isConnected) {
          // noinspection JSIgnoredPromiseFromCall
          dashboardStore.updateBalance();
        }
      }),
    [auth, dashboardStore, getIsFocused],
  );

  return (
    <MenuScreen
      goToAuth={goToAuth}
      goToPlan={goToPlan}
      safelyGoToWithdraw={safelyGoToWithdraw}
      goToFarmSwitch={goToFarmSwitch}
      getAffiliateIsPending={getAffiliateIsPending}
      safelyGoToAffiliate={safelyGoToAffiliate}
      goToWithdrawalHistory={goToWithdrawalHistory}
      goToTutorial={goToTutorial}
      goToDescription={goToDescription}
      goToQRCodeScanner={goToQRCodeScanner}
      goToLanguage={goToLanguage}
      openRate={rateApp.openModal}
      goToSignOut={goToSignOut}
      goToDeleteAccount={goToDeleteAccount}
    />
  );
});

export const createMenuState = (
  goToAffiliateToken?: string,
): PartialState<NavigationState<RootParamList>> => {
  return {
    index: 0,
    routes: [
      {
        name: 'Root',
        state: {
          index: 0,
          routes: [{name: 'Dashboard', params: {goToAffiliateToken}}],
        },
      },
      {
        name: 'Menu',
        state: {
          index: 0,
          routes: [{name: 'Menu', params: {goToAffiliateToken}}],
        },
      },
    ],
  };
};
