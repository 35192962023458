import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {
  ImageSourcePropType,
  StyleProp,
  View,
  Image,
  ViewProps,
  ViewStyle,
  StyleSheet,
  Platform,
  TextStyle,
  TextProps,
  ImageProps,
  LayoutRectangle,
} from 'react-native';
import {SvgProps} from 'react-native-svg';
import {variance, useStyles, useTheme} from '../../styling';
import {Paragraph, Header} from '../Typography';
import PressableOpacity from '../PressableOpacity';
import {sized} from '../../Svg';
import CloseSvg from '../../assets/svg/colorless/close.svg';
import useLayout from '../../ReactNativeUtil/useLayout';

export type ColoredBannerProps = ViewProps & {
  title: string;
  text?: string | (() => React.ReactNode);
  shadowColor?: ShadowColor;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  titleStyle?: StyleProp<TextStyle>;
  icon?: ImageSourcePropType;
  iconVisible?: (layout: LayoutRectangle | undefined) => boolean;
  iconViewProps?: ViewProps;
  iconProps?: Partial<ImageProps>;
  background: ImageSourcePropType;
  actions?: React.ReactNode[];
  contrast?: boolean;
  titleProps?: TextProps;
  descriptionProps?: TextProps;
  descriptionVisible?: (layout: LayoutRectangle | undefined) => boolean;
  onClose?: () => void;
};

export enum ShadowColor {
  Orange,
  Green,
  Purple,
  Red,
  Blue,
}

export const YELLOW_BACKGROUND = require('../../assets/banners/backgrounds/yellow.png');
export const ORANGE_BACKGROUND = require('../../assets/banners/backgrounds/orange.png');
export const LIGHT_BLUE_BACKGROUND = require('../../assets/banners/backgrounds/light-blue.png');
export const GREEN_BACKGROUND = require('../../assets/banners/backgrounds/green.png');
export const DARK_GREEN_BACKGROUND = require('../../assets/banners/backgrounds/dark-green.png');
export const PURPLE_BACKGROUND = require('../../assets/banners/backgrounds/purple.png');
export const YELLOW2_BACKGROUND = require('../../assets/banners/backgrounds/yellow2.png');
export const BLUE_BACKGROUND = require('../../assets/banners/backgrounds/blue.png');
export const RED_BACKGROUND = require('../../assets/banners/backgrounds/red.png');
export const SEA_BACKGROUND = require('../../assets/banners/backgrounds/sea.png');
export const EMERALD_BACKGROUND = require('../../assets/banners/backgrounds/emerald.png');
export const GOLDEN_BACKGROUND = require('../../assets/banners/backgrounds/golden.png');
export const AFF_COINS_ICON = require('../../assets/banners/images/affCoins.png');
export const MONEY_CASE_ICON = require('../../assets/banners/images/money-case.png');
export const MONEY_ICON = require('../../assets/banners/images/money.png');
export const PERCENT_ICON = require('../../assets/banners/images/percent.png');
export const CHIP_ICON = require('../../assets/banners/images/chip.png');
export const DOLLAR_ICON = require('../../assets/banners/images/dollar.png');
export const FAN_ICON = require('../../assets/banners/images/fan.png');
export const SERVER_ICON = require('../../assets/banners/images/server.png');
export const LOCK_ICON = require('../../assets/banners/images/lock.png');
export const QUESTION_ICON = require('../../assets/banners/images/question.png');
export const LIGHT_ICON = require('../../assets/banners/images/lights.png');
export const ARROWS_ICON = require('../../assets/banners/images/arrows.png');
export const ORANGE_BITCOIN_BACKGROUND = require('../../assets/banners/orangeBitcoin.png');
export const BLUE_GRADIENT = require('../../assets/banners/backgrounds/blue-gradient.png');

export default observer(function ColoredBanner({
  title,
  shadowColor,
  style,
  titleStyle,
  textStyle,
  text,
  icon,
  iconVisible,
  iconViewProps,
  iconProps,
  background = YELLOW_BACKGROUND,
  actions = [],
  contrast = false,
  titleProps,
  descriptionProps,
  descriptionVisible,
  onClose,
}: ColoredBannerProps) {
  const [layout, onLayout] = useLayout();
  const rootStyle = useRootStyle(shadowColor);
  const {style: iconViewStyle, ...iconViewRest} = iconViewProps ?? {};
  const {style: iconStyle, ...iconRest} = iconProps ?? {};
  const theme = useTheme();
  const isDescriptionVisible =
    descriptionVisible !== undefined
      ? descriptionVisible(layout)
      : layout && typeof text === 'string'
      ? layout.width < 400 && text.length > 70
      : true;
  const isIconVisible = iconVisible ? iconVisible(layout) : true;
  return (
    <RootView onLayout={onLayout} style={[rootStyle, style]}>
      <View style={styles.backgroundView}>
        <Image
          style={styles.backgroundImage}
          source={background}
          resizeMode="cover"
        />
      </View>
      <ContentView style={{paddingRight: onClose ? 36 : 0}}>
        <TitleText
          style={titleStyle}
          type="header"
          size="h5"
          contrast={contrast}
          {...titleProps}>
          {title}
        </TitleText>
        {text !== undefined && isDescriptionVisible ? (
          typeof text !== 'function' ? (
            <DescriptionText
              style={textStyle}
              type="paragraph"
              size="large"
              weight="500"
              contrast={contrast}
              {...descriptionProps}>
              {text}
            </DescriptionText>
          ) : (
            text()
          )
        ) : null}
        {actions.length !== 0 && (
          <ButtonView>
            {actions.map((item, index) => (
              <ButtonContentView key={index} offset={index !== 0}>
                {item}
              </ButtonContentView>
            ))}
          </ButtonView>
        )}
      </ContentView>
      {icon && isIconVisible && (
        <View style={[styles.iconView, iconViewStyle]} {...iconViewRest}>
          <Image
            style={[styles.iconImage, iconStyle]}
            source={icon}
            resizeMode="contain"
            {...iconRest}
          />
        </View>
      )}
      {onClose && (
        <CloseButton onPress={onClose}>
          <ActionCloseSvg color={theme.colors.primaryUIDirtyBlue} />
        </CloseButton>
      )}
    </RootView>
  );
});

function useRootStyle(shadowColor?: ShadowColor) {
  const themedStyles = useStyles((theme) => ({
    defaultShadow: {
      ...Platform.select({
        web: {
          boxShadow: '0px 14px 24px -8px rgba(75, 179, 75, 0.4)',
        },
        default: {
          shadowOffset: {
            width: 0,
            height: 6,
          },
          shadowOpacity: 0.4,
          shadowRadius: 8,
          elevation: 12,
          shadowColor: '#4BB34B',
        },
      }),
    },
    orangeShadow: {
      ...Platform.select({
        web: {
          boxShadow: '0px 18px 24px -8px rgba(255, 209, 49, 0.3)',
        },
        default: {
          shadowColor: '#FFD131',
        },
      }),
    },
    greenShadow: {
      ...Platform.select({
        web: {
          boxShadow: '0px 18px 24px -8px rgba(75, 179, 75, 0.3)',
        },
        default: {
          shadowColor: theme.colors.primarySuccess,
        },
      }),
    },
    purpleShadow: {
      ...Platform.select({
        web: {
          boxShadow: '0px 12px 24px -8px rgba(137, 47, 161, 0.4)',
        },
        default: {
          shadowColor: '#892FA1',
        },
      }),
    },
    redShadow: {
      ...Platform.select({
        web: {
          boxShadow: '0px 14px 24px -8px rgba(255, 51, 71, 0.4)',
        },
        default: {
          shadowColor: '#FF3347',
        },
      }),
    },
    blueShadow: {
      ...Platform.select({
        web: {
          boxShadow: '0px 14px 24px -8px rgba(78, 156, 255, 0.4)',
        },
        default: {
          shadowColor: '#4E9CFF',
        },
      }),
    },
    offsetLeft: {
      marginLeft: 5,
    },
  }));
  return useMemo(() => {
    switch (shadowColor) {
      case ShadowColor.Orange:
        return [themedStyles.defaultShadow, themedStyles.orangeShadow];
      case ShadowColor.Green:
        return [themedStyles.defaultShadow, themedStyles.greenShadow];
      case ShadowColor.Red:
        return [themedStyles.defaultShadow, themedStyles.redShadow];
      case ShadowColor.Purple:
        return [themedStyles.defaultShadow, themedStyles.purpleShadow];
      case ShadowColor.Blue:
        return [themedStyles.defaultShadow, themedStyles.blueShadow];
      default:
        return themedStyles.defaultShadow;
    }
  }, [shadowColor, themedStyles]);
}

export type DefaultButtonProps = {
  onPress: () => void;
  Icon?: React.ComponentType<SvgProps>;
  contrast?: boolean;
  title: string;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
  testID?: string;
};

export const DefaultButton = observer(
  ({
    onPress,
    Icon,
    contrast = false,
    title,
    disabled = false,
    style,
    testID,
  }: DefaultButtonProps) => {
    const theme = useTheme();
    const iconColor = contrast
      ? theme.colors.primaryWhite
      : theme.colors.primaryBlack;
    return (
      <BannerButton style={style} disabled={disabled} onPress={onPress} testID={testID}>
        {Icon && <Icon color={iconColor} />}
        <BannerText
          type="paragraph"
          size="large"
          weight="600"
          contrast={contrast}
          style={Icon && {marginLeft: 10}}>
          {title}
        </BannerText>
      </BannerButton>
    );
  },
);

const styles = StyleSheet.create({
  iconView: {
    width: 130,
  },
  backgroundView: {
    ...StyleSheet.absoluteFillObject,
    borderRadius: 12,
    overflow: 'hidden',
  },
  backgroundImage: {
    height: '100%',
    width: '100%',
  },
  iconImage: {
    flex: 1,
  },
});

const RootView = variance(View)((theme) => ({
  root: {
    flexDirection: 'row',
    position: 'relative',
    backgroundColor: theme.colors.uiMain,
    borderRadius: 12,
    paddingTop: 12,
    paddingBottom: 10,
    paddingHorizontal: 16,
    overflow: 'hidden',
  },
}));

const ContentView = variance(View)(() => ({
  root: {
    flex: 1,
    flexGrow: 1,
    zIndex: 2,
  },
}));

const DescriptionText = variance(Paragraph)((theme) => ({
  root: {
    color: theme.colors.primaryBlack,
    fontSize: 13,
    lineHeight: 17,
    letterSpacing: -0.2,
    opacity: 0.8,
    marginBottom: 12,
  },
  contrast: {
    opacity: 1,
    color: theme.colors.primaryWhite,
  },
}));

const TitleText = variance(Header)((theme) => ({
  root: {
    fontSize: 14,
    lineHeight: 17,
    color: theme.colors.primaryBlack,
    marginBottom: 4,
  },
  contrast: {
    color: theme.colors.primaryWhite,
  },
}));

const ButtonView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 'auto',
  },
}));

const ButtonContentView = variance(View)(() => ({
  root: {},
  offset: {
    marginLeft: 10,
  },
}));

const BannerButton = variance(PressableOpacity)(() => ({
  root: {
    paddingHorizontal: 15,
    paddingVertical: 6,
    borderRadius: 12,
    marginTop: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255, .3)',
  },
  offsetRight: {
    marginRight: 8,
  },
}));

const BannerText = variance(Paragraph)((theme) => ({
  root: {
    color: theme.colors.primaryBlack,
  },
  contrast: {
    color: theme.colors.primaryWhite,
  },
}));

const CloseButton = variance(PressableOpacity)((theme) => ({
  root: {
    position: 'absolute',
    top: 5,
    right: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: 28,
    height: 28,
    borderRadius: 28,
    backgroundColor: theme.colors.uiMain,
    opacity: 0.5,
  },
}));

const ActionCloseSvg = sized(CloseSvg, 10);
