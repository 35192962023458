import React from 'react';
import {observer} from 'mobx-react-lite';
import {useRoot} from '../Root/hooks';
import {AuthStatus} from '../Auth';
import {StyleSheet, View} from 'react-native';
import {SplashScreen} from '../SplashScreen';
import {expr} from 'mobx-utils';
import {DebugScreen} from '../DebugScreen';

export default observer(() => {
  const {connection, auth, dashboardStore, debug} = useRoot();
  const visible = expr(
    () =>
      !connection.isInitialized ||
      (connection.isConnected && !auth.initialized) ||
      auth.status !== AuthStatus.Idle ||
      (dashboardStore.dashboardRequest.value === undefined &&
        dashboardStore.isLoading),
  );
  if (!visible) {return;}
  return (
    <View style={styles.overlay}>
      {debug.debugEnabled ? <DebugScreen /> : <SplashScreen />}
    </View>
  );
});
const styles = StyleSheet.create({
  overlay: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 1000,
    flex: 1,
  },
});
