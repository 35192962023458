import {observer} from 'mobx-react-lite';
import React from 'react';
import {StyleSheet, View, ViewProps} from 'react-native';
import {useIntlFormatter, useRoot, useStrings} from '../Root/hooks';
import {useTheme, variance} from '../styling';
import Typography from './Typography';
import BtcSvg from '../assets/svg/twoTone/btc.svg';
import SpeedometerSvg from '../assets/svg/twoTone/speedometer.svg';
import FlashSvg from '../assets/svg/twoTone/flash.svg';
import {expr} from 'mobx-utils';
import getTotalWorkersSpeed from '../DashboardScreen/getters/getTotalWorkersSpeed';
import {Divider, TwoToneIcon} from './atoms';
import {SvgProps} from 'react-native-svg';
import {colored, sized} from '../Svg';
import getTotalOnlineWorkers from '../DashboardScreen/getters/getTotalOnlineWorkers';
import getTotalOfflineWorkers from '../DashboardScreen/getters/getTotalOfflineWorkers';
import getTotalWorkersMined from '../DashboardScreen/getters/getTotalWorkersMined';
import toFixedTrunc from '../utils/toFixedTrunc';
import {CryptoLoader} from './atoms/CryptoLoader';
import useTutorialMeasure from '../InteractiveTutorial/useTutorialMeasure';
import {StackElementKey} from '../InteractiveTutorial';

const TUTORIAL_KEY: StackElementKey = 'DASHBOARD_BALANCE';

export default observer(function FarmInfoCard(props: ViewProps) {
  const {currentSubscriptionState, workerStateRegistry, dashboardStore} =
    useRoot();
  const [ref, onLayout, getForceKey] = useTutorialMeasure(TUTORIAL_KEY);
  const {intFormatter} = useIntlFormatter();
  const workers = [...dashboardStore.workers.values()];
  const strings = useStrings();
  const totalHashrate = expr(() =>
    getTotalWorkersSpeed(workers, workerStateRegistry),
  );
  const onlineWorkerCount = expr(() =>
    getTotalOnlineWorkers(workers, workerStateRegistry),
  );
  const offlineWorkerCount = expr(() =>
    getTotalOfflineWorkers(workers, workerStateRegistry),
  );
  const poolWorkerCount = expr(
    () => currentSubscriptionState.subscription?.poolMiners ?? 0,
  );
  const totalMined = expr(() =>
    getTotalWorkersMined(workers, dashboardStore.getWorkerBalanceById),
  );
  const formattedBtc = intFormatter(toFixedTrunc(totalMined, 8));
  const formattedHashrate = intFormatter(totalHashrate.toFixed(0));
  return (
    <RootView ref={ref} onLayout={onLayout} key={getForceKey()} {...props}>
      {dashboardStore.isRefreshing || !dashboardStore.isLoadedIn ? (
        <View style={styles.loader}>
          <CryptoLoader />
        </View>
      ) : (
        <View style={styles.content}>
          <TopRowView>
            <TopItemView>
              <Dot />
              <OnlineCountText type="body" weight="500">
                {onlineWorkerCount}
              </OnlineCountText>
            </TopItemView>
            <Divider vertical style={styles.verticalDivider} />
            <TopItemView>
              <Dot gray />
              <OfflineCountText type="body" weight="500">
                {offlineWorkerCount}
              </OfflineCountText>
            </TopItemView>
          </TopRowView>
          <Divider style={styles.horizontalDivider} />
          <BodyRowView>
            <FlashIcon />
            <PoolCountText type="body" weight="500">
              {poolWorkerCount} {strings['subscription.poolMiners']}
            </PoolCountText>
          </BodyRowView>
          <Divider style={styles.horizontalDivider} />
          <BottomRowView>
            <BottomRowItem>
              <SpeedometerIcon />
              <HashrateCommonText type="body" weight="500">
                {formattedHashrate}{' '}
                <HashrateMarkText type="body" weight="500">
                  H/s
                </HashrateMarkText>
              </HashrateCommonText>
            </BottomRowItem>
            <Divider vertical style={styles.verticalDivider} />
            <BottomRowItem>
              <BtcIcon />
              <BtcText type="body" weight="500">
                {formattedBtc}
              </BtcText>
            </BottomRowItem>
          </BottomRowView>
        </View>
      )}
    </RootView>
  );
});

const BtcIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(BtcSvg, 18)} {...props} />
));
const SpeedometerIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(SpeedometerSvg, 18)} {...props} />
));
const FlashIcon = observer((props: SvgProps) => {
  const theme = useTheme();
  const Icon = colored(sized(FlashSvg, 24), theme.colors.primaryBlack);
  return <Icon {...props} />;
});

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  horizontalDivider: {
    width: '100%',
  },
  verticalDivider: {
    height: '100%',
  },
  loader: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const RootView = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.colors.uiMain,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: theme.colors.uiAdditional1,
  },
}));

const OnlineCountText = variance(Typography)((theme) => ({
  root: {
    color: theme.colors.primarySuccess,
    fontSize: 13,
    lineHeight: 16,
    marginLeft: 6,
  },
}));

const OfflineCountText = variance(Typography)((theme) => ({
  root: {
    color: theme.colors.primaryUIDirtyBlue,
    fontSize: 13,
    lineHeight: 16,
    marginLeft: 6,
  },
}));

const PoolCountText = variance(Typography)((theme) => ({
  root: {
    color: theme.colors.uiSecondary,
    fontSize: 13,
    lineHeight: 16,
    marginLeft: 8,
  },
}));

const HashrateCommonText = variance(Typography)((theme) => ({
  root: {
    color: theme.colors.uiSecondary,
    fontSize: 13,
    lineHeight: 16,
    marginLeft: 8,
  },
}));

const HashrateMarkText = variance(Typography)((theme) => ({
  root: {
    color: theme.colors.primaryUIDirtyBlue,
    fontSize: 12,
    lineHeight: 15,
  },
}));

const BtcText = variance(Typography)((theme) => ({
  root: {
    color: theme.colors.uiSecondary,
    fontSize: 13,
    lineHeight: 16,
    marginLeft: 8,
  },
}));

const BodyRowView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 4,
    flex: 1,
  },
}));

const BottomRowView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 10,
    flex: 1,
  },
}));

const BottomRowItem = variance(View)(() => ({
  root: {
    flex: 1,
    paddingHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Dot = variance(View)((theme) => ({
  root: {
    width: 6,
    height: 6,
    backgroundColor: theme.colors.primarySuccess,
    borderRadius: 6,
  },
  gray: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: theme.colors.primaryUIDirtyBlue,
  },
}));

const TopRowView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    flex: 1,
  },
}));

const TopItemView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 15,
    flex: 1,
  },
}));
