import React, {useMemo} from 'react';
import {ScrollView, View, StyleSheet, Platform} from 'react-native';
import {Language} from './Language';
import BtcSection from './BtcSection';
import MenuList, {MenuListProps} from './MenuList';
import SubMenuList from './SubMenuList';
import {SecondaryMenuItem} from './SecondaryMenuItem';
import {useRoot, useStrings} from '../Root/hooks';
import {observer} from 'mobx-react-lite';
import {AccountType} from '../Auth';
import RefreshControl from '../components/RefreshControl';
import LogoutSvg from '../assets/svg/colorless/logout.svg';
import LoginSvg from '../assets/svg/colorless/signIn.svg';
import {expr} from 'mobx-utils';
import {useStyles} from '../styling';
import LgStoreBanner from './LgStoreBanner';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {
  MD_BREAKPOINT,
  useGetIsDimensions,
} from '../WindowDimensions/useDimensions';
import UnlockThePossibilitiesOfMiningBanner from '../components/organisms/banner/UnlockThePossibilitiesOfMiningBanner';
import {useVisibleTabletTemplate} from '../AppTemplateState';

export type MenuScreenProps = MenuListProps & {
  goToAuth: () => void;
  goToLanguage: () => void;
  openRate: () => void;
  goToSignOut: () => void;
  goToDeleteAccount: () => void;
};

export default observer(function MenuScreen(props: MenuScreenProps) {
  const {
    goToPlan,
    safelyGoToWithdraw,
    goToFarmSwitch,
    getAffiliateIsPending,
    safelyGoToAffiliate,
    goToWithdrawalHistory,
    goToTutorial,
    goToDescription,
    goToAuth,
    goToQRCodeScanner,
    goToLanguage,
    goToSignOut,
    goToDeleteAccount,
    openRate,
  } = props;
  const {auth, dashboardStore} = useRoot();
  const strings = useStrings();
  const visibleTemplate = useVisibleTabletTemplate();
  const getIsLgDimensions = useGetIsDimensions('lg');
  const isLarge = expr(() => getIsLgDimensions());
  const insets = useInsetStyles();
  const refreshControl = useMemo(
    () => (
      <RefreshControl
        refreshing={dashboardStore.isRefreshing}
        onRefresh={dashboardStore.refresh}
      />
    ),
    [dashboardStore.isRefreshing, dashboardStore.refresh],
  );
  const safeInsets = useSafeAreaInsets();
  return (
    <ScrollView
      refreshControl={refreshControl}
      contentContainerStyle={{paddingBottom: Math.max(safeInsets.bottom, 15)}}
    >
      {isLarge && Platform.OS === 'web' ? (
        <LgStoreBanner />
      ) : (
        <View>
          <BtcSection onWithdrawPress={safelyGoToWithdraw} />
          <UnlockThePossibilitiesOfMiningBanner
            testID="profile_increase_income_btn"
            onButtonPress={goToPlan}
            style={styles.banner}
          />
        </View>
      )}
      <View style={insets.menuList}>
        <MenuList
          goToPlan={goToPlan}
          safelyGoToWithdraw={safelyGoToWithdraw}
          goToFarmSwitch={goToFarmSwitch}
          getAffiliateIsPending={getAffiliateIsPending}
          safelyGoToAffiliate={safelyGoToAffiliate}
          goToWithdrawalHistory={goToWithdrawalHistory}
          goToTutorial={goToTutorial}
          goToDescription={goToDescription}
          goToQRCodeScanner={goToQRCodeScanner}
          openRate={openRate}
        />
      </View>
      <View style={insets.lgMenu}>
        <SubMenuList goToDeleteAccount={goToDeleteAccount} />
        <View style={insets.footer}>
          {!visibleTemplate && (
            <Language hideLabel goToLanguage={goToLanguage} />
          )}
          <View style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
            {auth.accountType === AccountType.Temporary ? (
              <SecondaryMenuItem
                Icon={LoginSvg}
                text={strings['menu.signUp']}
                onPress={goToAuth}
                right
              />
            ) : (
              <>
                <SecondaryMenuItem
                  testID="profile_resign_up_btn"
                  text={strings['menu.resignUp']}
                  onPress={goToAuth}
                  right
                />
                <SecondaryMenuItem
                  testID="profile_sign_out_btn"
                  Icon={LogoutSvg}
                  text={strings['menu.signOut']}
                  onPress={goToSignOut}
                  right
                />
              </>
            )}
          </View>
        </View>
      </View>
    </ScrollView>
  );
});

const styles = StyleSheet.create({
  banner: {
    marginVertical: 18,
    marginHorizontal: 16,
    height: 115,
  },
});

const useInsetStyles = () =>
  useStyles((theme) => ({
    menuList: {
      paddingBottom: 25,
      ...theme.mediaQuery({
        [MD_BREAKPOINT]: {
          paddingBottom: 0,
          marginTop: 8,
        },
      }),
    },
    lgMenu: {
      ...theme.mediaQuery({
        [MD_BREAKPOINT]: {
          paddingRight: 15,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        },
      }),
    },
    firstDivider: {marginBottom: 20},
    subMenuList: {marginBottom: 10},
    secondDivider: {marginBottom: 5},
    footer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      paddingHorizontal: 15,
      alignItems: 'center',
      marginTop: 15,
      justifyContent: 'space-between',
      ...theme.mediaQuery({
        [MD_BREAKPOINT]: {
          marginTop: 0,
          paddingHorizontal: 0,
          marginLeft: 20,
        },
      }),
    },
    largeBanner: {
      marginTop: 9,
      marginBottom: 9,
      marginHorizontal: 9,
      flex: 4,
    },
  }));
