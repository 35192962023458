import {observer} from 'mobx-react-lite';
import React from 'react';
import ColoredBanner, {
  ShadowColor,
  DefaultButton,
  BLUE_BACKGROUND,
  ColoredBannerProps,
  SERVER_ICON,
} from '../../molecules/ColoredBanner';
import {useStrings} from '../../../Root/hooks';

export type TryTheNewPoolMinersBannerProps = Partial<ColoredBannerProps> & {
  onButtonPress: () => void;
};

export default observer(function TryTheNewPoolMinersBanner({
  onButtonPress,
  ...rest
}: TryTheNewPoolMinersBannerProps) {
  const strings = useStrings();
  return (
    <ColoredBanner
      shadowColor={ShadowColor.Blue}
      title={strings['banner.tryWorkersBanner.title']}
      text={strings['banner.tryWorkersBanner.description']}
      descriptionVisible={(layout) => (layout ? layout.width > 305 : false)}
      icon={SERVER_ICON}
      iconProps={{
        style: {
          transform: [{translateX: -5}, {translateY: 7}, {scale: 1.15}],
        },
      }}
      background={BLUE_BACKGROUND}
      contrast
      actions={[
        <DefaultButton
          testID="statistics_try_worker_btn"
          contrast
          onPress={onButtonPress}
          title={strings['banner.tryWorkersBanner.button']}
        />,
      ]}
      {...rest}
    />
  );
});
