import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {
  ScrollViewProps,
  SectionList,
  SectionListProps,
  StyleSheet,
} from 'react-native';
import formSections, {GroupSection, WorkerItem} from './formSections';
import {DetailSlot} from './DetailSlot';
import SectionGroupHeader from './SectionGroupHeader';
import {useRoot} from '../../../Root/hooks';
import useSectionStableOrder from '../../hooks/useSectionStableOrder';
import groupsContentAreEqual from './groupsContentAreEqual';
import SectionListHeader from './SectionListHeader';
import {SectionWorkerListContext} from './context';
import {useForcedContext} from '../../../context';
import {DashboardContext} from '../../context';
import useGetIsLarge from '../../hooks/useGetIsLarge';
import {expr} from 'mobx-utils';
import SectionGroupFooter from './SectionGroupFooter';
import useGetItemLayout from './useGetItemLayout';
import SectionListFooter from './SectionListFooter';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

export type SectionWorkerListProps = ScrollViewProps & {};
export default observer(function SectionWorkerList(
  props: SectionWorkerListProps,
) {
  const {dashboardStore, workerStateRegistry, dashboardService} = useRoot();
  const {goToAddNewWorkerGroup, goToPickDashboardSort, goToPoolActivator} =
    useForcedContext(DashboardContext);
  const insets = useSafeAreaInsets();
  const {dashboardRequest, getWorkerBalanceById} = dashboardStore;
  const groups = dashboardRequest.value?.groups;
  const liveSections = formSections(
    groups,
    dashboardService.getOrder,
    dashboardService.getCollapsedGroups,
    getWorkerBalanceById,
    workerStateRegistry,
  );
  const stableSections = useSectionStableOrder(
    liveSections,
    groupsContentAreEqual,
    [groups],
  );
  const getItemLayout = useGetItemLayout();
  const getIsLarge = useGetIsLarge();
  const isLarge = expr(() => getIsLarge());
  const value = {
    groupCount: groups?.length ?? 0,
    goToAddGroup: goToAddNewWorkerGroup,
    goToPickSort: goToPickDashboardSort,
  };

  const renderItem = useCallback(
    ({
      item,
      index,
      section,
    }: {
      item: WorkerItem;
      index: number;
      section: {isLast: boolean};
    }) => (
      <DetailSlot
        worker={item.worker}
        index={index}
        isLast={item.isLast}
        isLastSection={section.isLast}
        goToPoolActivator={goToPoolActivator}
      />
    ),
    [goToPoolActivator],
  );

  return (
    <SectionWorkerListContext.Provider value={value}>
      <SectionList
        contentContainerStyle={[
          styles.container,
          isLarge && styles.largeContainer,
          {paddingBottom: insets.bottom},
        ]}
        ListFooterComponentStyle={styles.footer}
        ListHeaderComponent={SectionListHeader}
        ListFooterComponent={SectionListFooter}
        sections={stableSections}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        getItemLayout={getItemLayout}
        renderSectionHeader={renderSectionHeader}
        renderSectionFooter={renderSectionFooter}
        stickySectionHeadersEnabled={false}
        keyboardShouldPersistTaps="handled"
        removeClippedSubviews
        initialNumToRender={8}
        maxToRenderPerBatch={6}
        {...props}
      />
    </SectionWorkerListContext.Provider>
  );
});

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  footer: {
    marginTop: 'auto',
  },
  largeContainer: {
    paddingHorizontal: 18,
  },
});

export type ListProps = SectionListProps<WorkerItem, GroupSection>;

const keyExtractor: NonNullable<ListProps['keyExtractor']> = (item) => item.key;

const renderSectionHeader: ListProps['renderSectionHeader'] = ({section}) => (
  <SectionGroupHeader groupSection={section} />
);

const renderSectionFooter: ListProps['renderSectionFooter'] = ({section}) => (
  <SectionGroupFooter groupSection={section} />
);
