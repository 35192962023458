import React, {forwardRef, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {AdaptiveModalRef} from '../components/templates';
import DotsSvg from '../assets/svg/colorless/horizontalDots.svg';
import ScheduleSvg from '../assets/svg/colorless/schedule.svg';
import RenewSvg from '../assets/svg/colorless/renew.svg';
import EditSvg from '../assets/svg/colorless/edit.svg';
import MoveFolderSvg from '../assets/svg/colorless/moveFolder.svg';
import TrashSvg from '../assets/svg/colorless/trash.svg';
import {sized} from '../Svg';
import {AdaptiveActionListModal, ActionVariant} from '../components/organisms';
import {useStrings} from '../Root/hooks';

export enum WorkerAction {
  Detail,
  Schedule,
  Statistics,
  Renew,
  Rename,
  Move,
  Delete,
}

export type WorkerActionModalProps = {
  onModalClosed: () => void;
  onAction: (action: WorkerAction) => void;
  disabledList: WorkerAction[];
  hiddenList: WorkerAction[];
  visibleRenewButton: boolean;
  isVisibleMoveButton: boolean;
};

export default observer<WorkerActionModalProps, AdaptiveModalRef>(
  forwardRef(function WorkerActionModal(
    {
      visibleRenewButton,
      onAction,
      disabledList,
      hiddenList,
      isVisibleMoveButton,
      ...rest
    },
    ref,
  ) {
    const strings = useStrings();
    const actionList = useMemo(() => {
      let result = [
        {
          id: WorkerAction.Detail,
          title: strings['worker.details'],
          onPress: () => onAction(WorkerAction.Detail),
          Icon: DotsIcon,
          disabled: disabledList.includes(WorkerAction.Detail),
        },
        {
          id: WorkerAction.Schedule,
          title: strings['worker.schedule'],
          onPress: () => onAction(WorkerAction.Schedule),
          Icon: ScheduleIcon,
          disabled: disabledList.includes(WorkerAction.Schedule),
        },
        {
          id: WorkerAction.Renew,
          title: strings['workerDetails.renew'],
          onPress: () => onAction(WorkerAction.Renew),
          Icon: RenewIcon,
        },
        {
          id: WorkerAction.Rename,
          title: strings['action.rename'],
          onPress: () => onAction(WorkerAction.Rename),
          Icon: EditIcon,
          disabled: disabledList.includes(WorkerAction.Rename),
        },
        ...(isVisibleMoveButton
          ? [
              {
                id: WorkerAction.Move,
                title: strings['worker.move'],
                onPress: () => onAction(WorkerAction.Move),
                Icon: MoveFolderIcon,
                disabled: disabledList.includes(WorkerAction.Move),
              },
            ]
          : []),
        {
          id: WorkerAction.Delete,
          title: strings['action.remove'],
          onPress: () => onAction(WorkerAction.Delete),
          Icon: TrashIcon,
          variant: ActionVariant.Error,
          disabled: disabledList.includes(WorkerAction.Delete),
        },
      ].filter((_) => !hiddenList.includes(_.id));

      if (!visibleRenewButton) {
        result = result.filter((_) => _.id !== WorkerAction.Renew);
      }

      return result;
    }, [
      disabledList,
      hiddenList,
      onAction,
      strings,
      visibleRenewButton,
      isVisibleMoveButton,
    ]);

    return (
      <AdaptiveActionListModal actionList={actionList} {...rest} ref={ref} />
    );
  }),
);

const DotsIcon = sized(DotsSvg, 16, 6);
const ScheduleIcon = sized(ScheduleSvg, 15);
const RenewIcon = sized(RenewSvg, 16);
const EditIcon = sized(EditSvg, 16);
const MoveFolderIcon = sized(MoveFolderSvg, 16);
const TrashIcon = sized(TrashSvg, 16);
