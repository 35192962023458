import React from 'react';
import {observer} from 'mobx-react-lite';
import {View, StyleSheet} from 'react-native';
import Typography from '../../Typography';
import {useIntlFormatter, useRoot, useStrings} from '../../../Root/hooks';
import {variance} from '../../../styling';
import BtcSvg from '../../../assets/svg/colored/btc.svg';
import {sized} from '../../../Svg';
import {AppButton, ButtonVariant, ButtonSize} from '../../AppButton';

export type BtcBalanceProps = {
  goToWithdraw: () => void;
};

export default observer(function BtcBalance({goToWithdraw}: BtcBalanceProps) {
  const {dashboardStore} = useRoot();
  const strings = useStrings();
  const {intFormatter} = useIntlFormatter();
  return (
    <RootView>
      <View style={styles.btcView}>
        <BtcIcon />
      </View>
      <BalanceTypography type="mono" weight="700">
        {dashboardStore.balance.btc && intFormatter(dashboardStore.balance.btc)}
      </BalanceTypography>
      <AppButton
        size={ButtonSize.Small}
        variant={ButtonVariant.Primary}
        onPress={goToWithdraw}>
        {strings['action.withdraw']}
      </AppButton>
    </RootView>
  );
});

const styles = StyleSheet.create({
  btcView: {
    marginRight: 12,
  },
});

const BtcIcon = sized(BtcSvg, 32);

const RootView = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.colors.uiMain,
    borderRadius: 12,
    height: 46,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    marginLeft: 18,
  },
}));

const BalanceTypography = variance(Typography)((theme) => ({
  root: {
    color: theme.colors.uiSecondary,
    fontSize: 21,
    lineHeight: 24,
    letterSpacing: -0.5,
    marginRight: 32,
  },
}));
