import React, {forwardRef} from 'react';
import {observer} from 'mobx-react-lite';
import {
  AdaptiveModal,
  AdaptiveModalRef,
  ModalWidth,
} from '../components/templates';
import {View} from 'react-native';
import {useTheme, variance} from '../styling';
import LogoSvg from '../assets/svg/colored/logoWithLock.svg';
import QuadFlash from '../assets/svg/colorless/quadFlash.svg';
import PlusCircle from '../assets/svg/circled/circlePlus.svg';
import {sized} from '../Svg';
import {Body, Header} from '../components';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {useStrings} from '../Root/hooks';
import useSafePaddingBottom from '../components/templates/AdaptiveModal/useSafePaddingBottom';

export type SwitchDemoModeScreenProps = ContentProps & {
  onModalClosed: () => void;
};

export default observer<SwitchDemoModeScreenProps, AdaptiveModalRef>(
  forwardRef(function SwitchDemoModeScreen(props, ref) {
    const {onModalClosed, ...rest} = props;
    return (
      <AdaptiveModal
        ref={ref}
        modalWidth={ModalWidth.Small}
        onModalClosed={onModalClosed}
        enableContentAutoHeight
      >
        <Content {...rest} />
      </AdaptiveModal>
    );
  }),
);

type ContentProps = {
  goToPlan(): void;
  goToAddDemoWorker(): void;
};
const Content = observer(({goToAddDemoWorker, goToPlan}: ContentProps) => {
  const theme = useTheme();
  const strings = useStrings();
  const paddingBottom = useSafePaddingBottom(16, 0);
  return (
    <Container style={{paddingBottom}}>
      <LogoIcon color={theme.colors.uiSecondary} />
      <Title type="header" size="h2" weight="700">
        {strings['switchDemo.title']}
      </Title>
      <Description type="body" weight="500">
        {strings['switchDemo.description']}
      </Description>
      <BtnWithBottomOffset
        testID="modal_demo_start_btn"
        Icon={QuadFlash}
        variant={ButtonVariant.Primary}
        onPress={goToPlan}
      >
        {strings['switchDemo.btn.start']}
      </BtnWithBottomOffset>
      <AppButton
        Icon={PlusCircle}
        onPress={goToAddDemoWorker}
        testID="modal_demo_addworker_btn"
      >
        {strings['modal.addWorker.actions.addDemoWorker']}
      </AppButton>
    </Container>
  );
});

const _LogoIcon = sized(LogoSvg, 64, 64);

const BtnWithBottomOffset = variance(AppButton)(() => ({
  root: {
    marginBottom: 12,
  },
}));

const Title = variance(Header)((theme) => ({
  root: {
    textAlign: 'center',
    color: theme.colors.uiSecondary,
    marginBottom: 8,
  },
}));

const Description = variance(Body)((theme) => ({
  root: {
    textAlign: 'center',
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.primaryUIDirtyBlue,
    marginBottom: 24,
  },
}));

const LogoIcon = variance(_LogoIcon)(() => ({
  root: {
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: 8,
  },
}));

const Container = variance(View)(() => ({
  root: {
    marginVertical: 32,
    marginHorizontal: 16,
  },
}));
