import React from 'react';
import {observer} from 'mobx-react-lite';
import {useGetIsDimensions} from '../WindowDimensions/useDimensions';
import {Platform, StyleSheet} from 'react-native';
import {RefreshControlProps, ScrollView, View} from 'react-native';
import {expr} from 'mobx-utils';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

export type PurchaseScreenTemplateProps = {
  refreshControl?: React.ReactElement<RefreshControlProps>;
  renderHeader: () => React.ReactNode;
  renderFooter: () => React.ReactNode;
  renderAdditional: () => React.ReactNode;
  children: React.ReactNode;
};

export default observer(function PurchaseScreenTemplate(
  props: PurchaseScreenTemplateProps,
) {
  const getIsLarge = useGetIsDimensions('lg');
  const isLarge = expr(() => getIsLarge());
  if (isLarge) {
    return <LargeTemplate {...props} />;
  }
  return <MobileTemplate {...props} />;
});

const LargeTemplate = observer(
  ({
    refreshControl,
    renderFooter,
    renderHeader,
    renderAdditional,
    children,
  }: PurchaseScreenTemplateProps) => {
    const insets = useSafeAreaInsets();
    return (
      <ScrollView
        refreshControl={refreshControl}
        contentContainerStyle={[
          styles.largeContainer,
          {paddingBottom: insets.bottom},
        ]}>
        {renderHeader()}
        {children}
        {renderFooter()}
        {Platform.OS === 'web' && renderAdditional()}
      </ScrollView>
    );
  },
);

const MobileTemplate = observer(
  ({
    renderHeader,
    refreshControl,
    renderFooter,
    children,
  }: PurchaseScreenTemplateProps) => (
    <View style={styles.root}>
      <ScrollView refreshControl={refreshControl}>
        {renderHeader()}
        {children}
      </ScrollView>
      {renderFooter()}
    </View>
  ),
);

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  largeContainer: {
    paddingHorizontal: 20,
  },
});
