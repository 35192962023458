import React from 'react';
import {observer} from 'mobx-react-lite';
import {View, TouchableWithoutFeedback, StyleSheet} from 'react-native';
import {useForcedContext} from '../../context';
import {DashboardContext} from '../context';
import {BalanceInformation} from '../../components/organisms';
import {Divider, TwoToneIcon} from '../../components/atoms';
import {sized} from '../../Svg';
import SpeedometerSvg from '../../assets/svg/twoTone/speedometer.svg';
import FlashSvg from '../../assets/svg/colored/yellowFlash.svg';
import PlusCircleSvg from '../../assets/svg/colored/plusCircle.svg';
import {SvgProps} from 'react-native-svg';
import {variance} from '../../styling';
import Typography, {Body} from '../../components/Typography';
import {useIntlFormatter, useRoot, useStrings} from '../../Root/hooks';
import getTotalWorkersSpeed from '../getters/getTotalWorkersSpeed';
import {expr} from 'mobx-utils';
import getTotalOnlineWorkers from '../getters/getTotalOnlineWorkers';
import {PressableOpacity} from '../../components';
import HeaderPromoBannerSection, {
  useHeaderPromoBannerHeight,
} from './HeaderPromoBannerSection';

const CONTENT_HEIGHT = 108;

export default observer(function HeaderFarmInfoSection() {
  const {goToWithdraw, goToPlan} = useForcedContext(DashboardContext);
  const {
    debugEasterEgg,
    dashboardStore,
    workerStateRegistry,
    currentSubscriptionState,
  } = useRoot();
  const workers = [...dashboardStore.workers.values()];
  const strings = useStrings();
  const {intFormatter} = useIntlFormatter();
  const onlineWorkerCount = expr(() =>
    getTotalOnlineWorkers(workers, workerStateRegistry),
  );
  const totalHashrate = expr(() =>
    getTotalWorkersSpeed(workers, workerStateRegistry),
  );
  const poolWorkerCount = expr(
    () => currentSubscriptionState.subscription?.poolMiners ?? 0,
  );
  const formattedHashrate = intFormatter(totalHashrate.toFixed(0));
  const height = useHeaderBannerConditionSectionHeight();
  return (
    <RootView>
      <View style={{height}}>
        <View style={{height: CONTENT_HEIGHT}}>
          <BalanceInformation onWithdrawPress={goToWithdraw} />
          <Divider style={styles.horizontalDivider} />
          <BottomRowView>
            <TouchableWithoutFeedback onPress={debugEasterEgg.tapFirst}>
              <BottomRowItemView>
                <SpeedometerIcon />
                <HashrateCommonText type="body" weight="500">
                  {formattedHashrate}{' '}
                  <HashrateMarkText type="body" weight="500">
                    H/s
                  </HashrateMarkText>
                </HashrateCommonText>
              </BottomRowItemView>
            </TouchableWithoutFeedback>
            <Divider vertical />
            <TouchableWithoutFeedback onPress={debugEasterEgg.tapSecond}>
              <BottomRowItemView>
                <OnlineCountCommonText type="body" weight="500">
                  <OnlineCountText type="body" weight="500">
                    {strings['dashboard.panel.online']}: {onlineWorkerCount}
                  </OnlineCountText>{' '}
                  / {workers.length}
                </OnlineCountCommonText>
              </BottomRowItemView>
            </TouchableWithoutFeedback>
            <Divider vertical />
            <TouchableWithoutFeedback onPress={debugEasterEgg.tapThird}>
              <BottomRowItemView>
                <FlashIcon />
                <PoolCountText type="body" weight="500">
                  {poolWorkerCount}
                </PoolCountText>
                <PlusButton onPress={goToPlan}>
                  <PlusCircleIcon />
                </PlusButton>
              </BottomRowItemView>
            </TouchableWithoutFeedback>
          </BottomRowView>
        </View>
        <HeaderPromoBannerSection />
      </View>
    </RootView>
  );
});

export const useHeaderBannerConditionSectionHeight = () => {
  const promoSectionHeight = useHeaderPromoBannerHeight();
  return CONTENT_HEIGHT + promoSectionHeight;
};

const styles = StyleSheet.create({
  horizontalDivider: {
    width: '100%',
  },
});

const SpeedometerIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(SpeedometerSvg, 17, 12)} {...props} />
));
const FlashIcon = sized(FlashSvg, 18);
const PlusCircleIcon = sized(PlusCircleSvg, 15);

const RootView = variance(View)((theme) => ({
  root: {
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    overflow: 'hidden',
    backgroundColor: theme.colors.uiMain,
  },
}));

const BottomRowView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 42, // 44 - 2 (divider height)
  },
}));

const BottomRowItemView = variance(View)(() => ({
  root: {
    height: '100%',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const HashrateCommonText = variance(Typography)((theme) => ({
  root: {
    color: theme.colors.uiSecondary,
    fontSize: 13,
    lineHeight: 16,
    marginLeft: 5,
  },
}));

const HashrateMarkText = variance(Typography)((theme) => ({
  root: {
    color: theme.colors.primaryUIDirtyBlue,
    fontSize: 12,
    lineHeight: 15,
  },
}));

const OnlineCountCommonText = variance(Body)((theme) => ({
  root: {
    color: theme.colors.primaryUIDirtyBlue,
    fontSize: 13,
    lineHeight: 16,
  },
}));
const OnlineCountText = variance(OnlineCountCommonText)((theme) => ({
  root: {
    color: theme.colors.primarySuccess,
  },
}));

const PoolCountText = variance(Typography)((theme) => ({
  root: {
    color: theme.colors.uiSecondary,
    fontSize: 13,
    lineHeight: 16,
    marginHorizontal: 5,
  },
}));

const PlusButton = variance(PressableOpacity)(() => ({
  root: {
    borderRadius: 7.5,
    padding: 10,
    margin: -10,
  },
}));
