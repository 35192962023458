import React from 'react';
import {observer} from 'mobx-react-lite';
import {ReadonlyDeep} from 'type-fest';
import MiningInfo from './MiningInfo';
import {WorkerEntity} from '../../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import PowerSlider from './PowerSlider';
import {variance} from '../../../../styling';
import WorkerStatusBackground from '../../../../components/organisms/worker/WorkerStatusBackground';
import {StyleSheet, View} from 'react-native';
import useGetIsLarge from '../../../hooks/useGetIsLarge';
import {expr} from 'mobx-utils';
import Configuration from './Configuration';
import MenuHeader from './MenuHeader';
import {
  SECTION_LIST_ITEM_DESKTOP_HEIGHT,
  SECTION_LIST_ITEM_HEIGHT,
} from '../../../constants';
import {TouchableWithoutFeedback} from 'react-native-gesture-handler';
import isNeedToReactivate from '../../../../PoolMinerActivator/isNeedToReactivate';

export type DetailSlotProps = {
  worker: ReadonlyDeep<WorkerEntity>;
  index: number;
  isLast: boolean;
  isLastSection: boolean;
  goToPoolActivator: () => void;
};

export function useGetDetailedSlotHeight() {
  const getIsLarge = useGetIsLarge();
  return () =>
    getIsLarge() ? SECTION_LIST_ITEM_DESKTOP_HEIGHT : SECTION_LIST_ITEM_HEIGHT;
}

export default observer(function DetailSlot(props: DetailSlotProps) {
  const {worker, index, goToPoolActivator, isLast, isLastSection} = props;
  const getIsLarge = useGetIsLarge();
  const isLarge = expr(() => getIsLarge());
  const getDetailedSlotHeight = useGetDetailedSlotHeight();
  const expired = expr(() => isNeedToReactivate(worker));
  const height = expr(() => getDetailedSlotHeight());

  return (
    <RootView
      style={{height}}
      large={isLarge}
      bottomRounded={isLarge && isLast && isLastSection}>
      <Background worker={worker}>
        <MenuHeader
          worker={worker}
          onHeaderPress={expired ? goToPoolActivator : undefined}
          index={index}
        />
        <Content
          large={isLarge}
          onPress={expired ? goToPoolActivator : undefined}
          last={isLast && isLastSection}>
          <MiningInfo worker={worker} />
          <PowerSlider worker={worker} />
          <Configuration worker={worker} />
        </Content>
      </Background>
    </RootView>
  );
});

type ContentProps = {
  onPress?: () => void;
  last: boolean;
  large: boolean;
  children: React.ReactNode;
};
const Content = ({children, onPress, last, large}: ContentProps) => {
  if (onPress) {
    return (
      <TouchableWithoutFeedback
        style={styles.grow}
        containerStyle={styles.grow}
        onPress={onPress}>
        <ContentView last={last} large={large}>
          {children}
        </ContentView>
      </TouchableWithoutFeedback>
    );
  } else {
    return (
      <View style={styles.grow}>
        <ContentView last={last} large={large}>
          {children}
        </ContentView>
      </View>
    );
  }
};

const styles = StyleSheet.create({
  grow: {
    flex: 1,
  },
});

const RootView = variance(View)((theme) => ({
  root: {
    overflow: 'hidden',
  },
  large: {
    borderLeftWidth: 1,
    borderLeftColor: theme.colors.uiAdditional1,
    borderRightWidth: 1,
    borderRightColor: theme.colors.uiAdditional1,
  },
  bottomRounded: {
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.uiAdditional1,
  },
}));

const Background = variance(WorkerStatusBackground)(() => ({
  root: {
    flexGrow: 1,
  },
}));

const ContentView = variance(View)((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: 12,
    paddingBottom: 14,
    borderBottomWidth: 2,
    borderBottomColor: theme.colors.uiMain,
  },
  large: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 0,
    paddingBottom: 0,
  },
  last: {
    borderBottomWidth: 0,
  },
}));
