import {useCallback} from 'react';
import useRoot from './useRoot';
import {ISODateString} from '../../Time';

const useIntlFormatter = () => {
  const {translation} = useRoot();
  const currentLocale = translation.locale || 'en-US';

  const mergeOptions = (
    defaultOptions: Intl.NumberFormatOptions,
    userOptions?: Intl.NumberFormatOptions,
  ) => ({
    ...defaultOptions,
    ...userOptions,
  });

  const intFormatter = useCallback(
    (value: string | number, options?: Intl.NumberFormatOptions) => {
      try {
        const toNumber = typeof value === 'string' ? parseFloat(value) : value;

        if (isNaN(toNumber)) {
          return value;
        }

        const defaultOptions: Intl.NumberFormatOptions = {
          minimumFractionDigits: 0,
          maximumFractionDigits: 20,
        };

        const formatter = new Intl.NumberFormat(
          currentLocale,
          mergeOptions(defaultOptions, options),
        );

        return formatter.format(toNumber);
      } catch (error) {
        console.error('Error in intFormatter:', error);
        return value;
      }
    },
    [currentLocale],
  );

  const dateFormatter = useCallback(
    (date: ISODateString | number, options?: Intl.DateTimeFormatOptions) => {
      try {
        const parsedDate = new Date(date);

        if (isNaN(parsedDate.getTime())) {
          throw new Error('Invalid date format!');
        }

        const defaultOptions: Intl.DateTimeFormatOptions = {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        };

        const formatter = new Intl.DateTimeFormat(currentLocale, {
          ...defaultOptions,
          ...options,
        });

        return formatter.format(parsedDate);
      } catch (error) {
        console.error('Error in dateFormatter:', error);
        return date;
      }
    },
    [currentLocale],
  );

  return {intFormatter, dateFormatter};
};

export default useIntlFormatter;
