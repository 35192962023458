import React from 'react';
import {View} from 'react-native';
import {observer} from 'mobx-react-lite';

import {variance} from '../styling';
import {
  BalanceInformation,
  BalanceInformationProps,
} from '../components/organisms';
import {Divider} from '../components/atoms';

export default observer(function BtcSection(props: BalanceInformationProps) {
  return (
    <View>
      <RootView>
        <BalanceInformation {...props} btnDemoTestID="demo_btn" />
      </RootView>
      <Divider />
    </View>
  );
});

const RootView = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.colors.uiMain,
    borderTopRightRadius: 24,
    borderTopLeftRadius: 24,
  },
}));
