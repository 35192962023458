import {observer} from 'mobx-react-lite';
import React from 'react';
import {RootParamList} from './RootParamList';
import SwitchDemoModeBinding from './SwitchDemoModeBinding';
import LogoutConfirmBinding from './LogoutConfirmBinding';
import PickLanguageBinding from './PickLanguageBinding';
import WithdrawalHistoryBinding from './WithdrawalHistoryBinding';
import RateAppBinding from './RateAppBinding';
import DeleteWorkerGroupConfirmBinding from './DeleteWorkerGroupConfirmBinding';
import WorkerActionBinding from './WorkerActionBinding';
import DeleteWorkerConfirmBinding from './DeleteWorkerConfirmBinding';
import WorkerGroupActionBinding from './WorkerGroupActionBinding';
import AddNewWorkerGroupBinding from './AddNewWorkerGroupBinding';
import RenameWorkerGroupBinding from './RenameWorkerGroupBinding';
import RenameWorkerBinding from './RenameWorkerBinding';
import MoveWorkerToGroupBinding from './MoveWorkerToGroupBinding';
import PickDashboardGroupSortBinding from './PickDashboardGroupSortBinding';
import AddWorkerBinding from './AddWorkerBinding';
import LinkWorkerBinding from './LinkWorkerBinding';
import CopyInstallIdBinding from './CopyInstallIdBinding';
import LinkDemoWorkerBinding from './LinkDemoWorkerBinding';
import PoolPuzzleConfirmBinding from './PoolPuzzleConfirmBinding';
import PickScheduleSpeedBinding from './PickScheduleSpeedBinding';
import LeaveScheduleUnsavedBinding from './LeaveScheduleUnsavedBinding';
import PickScheduleTimezoneBinding from './PickScheduleTimezoneBinding';
import PickScheduleWorkersBinding from './PickScheduleWorkersBinding';
import LinkWorkerConfirmBinding from './LinkWorkerConfirmBinding';
import CalendarBinding from './CalendarBinding';
import PoolMinerSchedulerWarningBinding from './PoolMinerSchedulerWarningBinding';
import CopyDiscountLinkBinding from './CopyDiscountLinkBinding';
import CreateNewFarmWarningBinding from './CreateNewFarmWarningBinding';
import PickPurchaseConfigurationBinding from './PickPurchaseConfigurationBinding';
import PickSubManagementConfigurationBinding from './PickSubManagementConfigurationBinding';
import ThanksModalBinding from './ThanksModalBinding';
import DeleteAccountConfirmBinding from './DeleteAccountConfirmBinding';
import {BottomTab} from '../BottomTab';
import MenuBinding from './MenuBinding';
import WorkerDetailsBinding from './WorkerDetailsBinding';
import AccessRecoveryBinding from './AccessRecoveryBinding';
import AuthBinding from './AuthBinding';
import CheckAuthBinding from './CheckAuthBinding';
import OAuthSplashBinding from './OAuthSplashBinding';
import AccountSwitchBinding from './AccountSwitchBinding';
import PaymentSuccessBinding from './PaymentSuccessBinding';
import NotificationSettingsBinding from './NotificationSettingsBinding';
import PurchaseBinding from './PurchaseBinding';
import SubscriptionManagementBinding from './SubscriptionManagementBinding';
import ScheduleBinding from './ScheduleBinding';
import DashboardSettingsBinding from './DashboardSettingsBinding';
import QRCodeScannerBinding from './QRCodeScannerBinding';
import FarmActionBinding from './FarmActionBinding';
import RenewAccessBinding from './RenewAccessBinding';
import QuickStartBinding from './QuickStartBinding';
import QuickStartModalBinding from './QuickStartModalBinding';
import FinishQuickStartBinding from './FinishQuickStartBinding';
import {
  DatabaseImportScreen,
  EnvironmentFormScreen,
  EnvironmentListScreen,
} from '../../DebugScreen';
import LogBinding from './LogBinding';
import {
  useGetCommonScreenOptions,
  useGetTabBarScreenOptions,
} from '../hooks/useGetScreenOptions';
import {createHomeStackNavigator} from '../HomeStackNavigator';
import {StackNavigationOptions} from '@react-navigation/stack';
import AdvertSplashBinding from './AdvertSplashBinding';
import TutorialBinding from './TutorialBinding';
import PoolMinerActivatedBinding from './PoolMinerActivatedBinding';
import PaymentConfirmPendingBinding from './PaymentConfirmPendingBinding';
import DisconnectWalletBinding from './DisconnectWalletBinding';
import PurchasePaidBinding from './PurchasePaidBinding';
import ConnectWalletBinding from './ConnectWalletBinding';
import PaymentBinding from './PaymentBinding';
import PriceChangedBinding from './PriceChangedBinding';
import PaymentCompletedBinding from './PaymentCompletedBinding';
import PoolMinerDescription from './PoolMinerDescription';
import {ProductsBinding} from './ProductsBinding';
import IntroVideoBinding from './IntroVideoBinding';
import {BonusProgramBinding} from './BonusProgramBinding';

const {Navigator, Screen, Group} = createHomeStackNavigator<RootParamList>();

export default observer(() => {
  const getCommonScreenOptions = useGetCommonScreenOptions();
  const getTabBarScreenOptions = useGetTabBarScreenOptions();
  return (
    <Navigator initialRouteName="Root">
      <Group screenOptions={getCommonScreenOptions}>
        <Screen
          name="Root"
          component={BottomTab}
          options={getTabBarScreenOptions}
        />
        <Screen name="Menu" component={MenuBinding} />
        <Screen name="WorkerDetails" component={WorkerDetailsBinding} />
        <Screen name="AccessRecovery" component={AccessRecoveryBinding} />
        <Screen name="Auth" component={AuthBinding} />
        <Screen name="CheckAuth" component={CheckAuthBinding} />
        <Screen name="OAuthSplash" component={OAuthSplashBinding} />
        <Screen name="AccountSwitch" component={AccountSwitchBinding} />
        <Screen name="PaymentSuccess" component={PaymentSuccessBinding} />
        <Screen
          name="NotificationsSettings"
          component={NotificationSettingsBinding}
        />
        <Screen name="Plan" component={PurchaseBinding} />
        <Screen name="Products" component={ProductsBinding} />
        <Screen name="BonusProgram" component={BonusProgramBinding} />
        <Screen name="QuickStart" component={QuickStartBinding} />
        <Screen
          name="SubscriptionManagement"
          component={SubscriptionManagementBinding}
        />
        <Screen name="Schedule" component={ScheduleBinding} />
        <Screen name="DashboardSettings" component={DashboardSettingsBinding} />
        <Screen name="QRCodeScanner" component={QRCodeScannerBinding} />
        <Screen name="FarmAction" component={FarmActionBinding} />
        <Screen name="RenewAccess" component={RenewAccessBinding} />
        <Screen name="Payment" component={PaymentBinding} />
        {/* DEBUG */}
        <Screen name="EnvironmentForm" component={EnvironmentFormScreen} />
        <Screen name="EnvironmentList" component={EnvironmentListScreen} />
        <Screen name="DatabaseImport" component={DatabaseImportScreen} />
        <Screen name="Log" component={LogBinding} />
        {/* # DEBUG */}
      </Group>
      <Group screenOptions={modalScreenOptions}>
        <Screen name="Tutorial" component={TutorialBinding} />
        <Screen name="LogoutConfirm" component={LogoutConfirmBinding} />
        <Screen name="PickLanguage" component={PickLanguageBinding} />
        <Screen name="Calendar" component={CalendarBinding} />
        <Screen name="Rate" component={RateAppBinding} />
        <Screen name="SwitchDemoMode" component={SwitchDemoModeBinding} />
        <Screen name="AdvertSplash" component={AdvertSplashBinding} />
        <Screen name="IntroVideo" component={IntroVideoBinding} />
        <Screen
          name="PickPurchaseConfiguration"
          component={PickPurchaseConfigurationBinding}
        />
        <Screen
          name="PickSubManagementConfiguration"
          component={PickSubManagementConfigurationBinding}
        />
        <Screen name="WithdrawalHistory" component={WithdrawalHistoryBinding} />
        <Screen
          name="DeleteWorkerGroupConfirm"
          component={DeleteWorkerGroupConfirmBinding}
        />
        <Screen
          name="DeleteWorkerConfirm"
          component={DeleteWorkerConfirmBinding}
        />
        <Screen
          name="DeleteAccountConfirm"
          component={DeleteAccountConfirmBinding}
        />
        <Screen name="PaymentCompleted" component={PaymentCompletedBinding} />
        <Screen name="AddNewWorkerGroup" component={AddNewWorkerGroupBinding} />
        <Screen
          name="PickDashboardSort"
          component={PickDashboardGroupSortBinding}
        />
        <Screen name="ThanksForPurchase" component={ThanksModalBinding} />
        <Screen name="AddWorker" component={AddWorkerBinding} />
        <Screen name="QuickStartModal" component={QuickStartModalBinding} />
        <Screen name="FinishQuickStart" component={FinishQuickStartBinding} />
        <Screen name="LinkWorker" component={LinkWorkerBinding} />
        <Screen name="LinkDemoWorker" component={LinkDemoWorkerBinding} />
        <Screen name="CopyInstallId" component={CopyInstallIdBinding} />
        <Screen name="RenameWorkerGroup" component={RenameWorkerGroupBinding} />
        <Screen name="RenameWorker" component={RenameWorkerBinding} />
        <Screen name="MoveWorkerToGroup" component={MoveWorkerToGroupBinding} />
        <Screen name="WorkerAction" component={WorkerActionBinding} />
        <Screen name="WorkerGroupAction" component={WorkerGroupActionBinding} />
        <Screen name="PoolPuzzleConfirm" component={PoolPuzzleConfirmBinding} />
        <Screen name="LinkWorkerConfirm" component={LinkWorkerConfirmBinding} />
        <Screen name="PickScheduleSpeed" component={PickScheduleSpeedBinding} />
        <Screen
          name="PickScheduleTimezone"
          component={PickScheduleTimezoneBinding}
        />
        <Screen
          name="PickScheduleWorkers"
          component={PickScheduleWorkersBinding}
        />
        <Screen
          name="LeaveScheduleUnsaved"
          component={LeaveScheduleUnsavedBinding}
        />
        <Screen
          name="PoolMinerSchedulerWarning"
          component={PoolMinerSchedulerWarningBinding}
        />
        <Screen
          name="PoolMinerActivated"
          component={PoolMinerActivatedBinding}
        />
        <Screen name="CopyDiscountLink" component={CopyDiscountLinkBinding} />
        <Screen
          name="CreateNewFarmWarning"
          component={CreateNewFarmWarningBinding}
        />
        <Screen name="PurchasePaid" component={PurchasePaidBinding} />
        <Screen name="DisconnectWallet" component={DisconnectWalletBinding} />
        <Screen name="OAuthComplete" component={BottomTab} />
        <Screen name="NotFound" component={BottomTab} />
        <Screen
          name="PaymentConfirmPending"
          component={PaymentConfirmPendingBinding}
        />
        <Screen name="ConnectWallet" component={ConnectWalletBinding} />
        <Screen name="PriceChanged" component={PriceChangedBinding} />
        <Screen name="PoolMinerDescription" component={PoolMinerDescription} />
      </Group>
    </Navigator>
  );
});

const modalScreenOptions: StackNavigationOptions = {
  animationTypeForReplace: 'pop',
  headerShown: false,
  animationEnabled: false,
  cardStyle: {backgroundColor: 'transparent'},
  cardOverlayEnabled: false,
  gestureEnabled: false,
  presentation: 'transparentModal',
};
