import {observable, makeObservable, runInAction, computed, flow} from 'mobx';
import {ErrorRepository} from '../ErrorRepository';
import {ConnectedClient} from '../ContextClient';
import {error, success} from '../fp';
import {SERVER_ERROR} from '../Error';
import {Translation} from '../Localization';
import {HSHAffiliate} from './HSHAffiliate';
import {Service} from '../structure';
import {ApiStore, HSHBalanceResponse} from '../ApiStore';

export default class HSHAffiliateService implements HSHAffiliate, Service {
  @observable private _total: string = '0';
  @observable.ref private _text: HSHBalanceResponse['text'] | undefined;
  @observable private _isRefreshing: boolean = false;
  @observable private _isLoading: boolean = false;

  constructor(
    private readonly _root: {
      readonly translation: Translation;
      readonly connectedClient: ConnectedClient;
      readonly errorRepository: ErrorRepository;
      readonly apiStore: ApiStore;
    },
  ) {
    makeObservable(this);
  }

  get balance() {
    return this._total;
  }

  get isLoading() {
    return this._isLoading;
  }

  get isRefreshing() {
    return this._isRefreshing;
  }

  @computed
  get shareText() {
    if (!this._text) {
      return undefined;
    }
    const localeTag = this._root.translation.localeTag;
    return this._text[localeTag] || this._text.en;
  }

  async fetchBalance() {
    runInAction(() => (this._isLoading = true));
    const res = await this._root.connectedClient.apply('get_balances_info');
    if (!res.success) {
      return error(
        this._root.errorRepository.create({
          kind: SERVER_ERROR,
          raw: res.left,
        }),
      );
    }
    runInAction(() => {
      const data = res.right;
      this._total = data.total;
      this._text = data.text;
    });
    runInAction(() => (this._isLoading = false));
  }

  refresh = flow(function* (this: HSHAffiliateService) {
    runInAction(() => (this._isRefreshing = true));
    yield this.fetchBalance().catch(() => undefined);
    runInAction(() => (this._isRefreshing = false));
  }).bind(this);

  private _listenBalanceUpdates() {
    return this._root.apiStore.server.call(
      'balance_info',
      (params, response) => {
        runInAction(() => {
          this._total = params.total;
        });
        return response.respond(success(null));
      },
    );
  }

  subscribe() {
    return this._listenBalanceUpdates();
  }
}
