import {observer} from 'mobx-react-lite';
import CoinSvg from '../../assets/svg/colored/hashcoin.svg';
import QuestionSvg from '../../assets/svg/colorless/question.svg';
import {Pressable, View, Text, StyleProp, ViewStyle} from 'react-native';
import AffiliateCard from '../../components/organisms/banner/AffiliateBalanceBanner/AffiliateCard';
import {createStylesHook, useTheme, variance} from '../../styling';
import React from 'react';
import Typography, {getMonoFontStyles} from '../../components/Typography';
import {sized} from '../../Svg';
import {AppButton, ButtonVariant} from '../../components/AppButton';
import ShareSvg from '../../assets/svg/colorless/share.svg';
import InfoSvg from '../../assets/svg/colorless/infoBordered.svg';
import {useStrings} from '../../Root/hooks';
import ArrowSvg from '../../assets/svg/colorless/arrowInSquare.svg';

export const AFFILIATE_BALANCE_BANNER_HEIGHT = 128;

export type AffiliateBalanceBannerProps = {
  balance: string;
  shareLink: () => void;
  onLeaveToAffiliatePress: () => void;
  onDetailsPress: () => void;
  isTemporary: boolean;
  style?: StyleProp<ViewStyle>;
};
export default observer(function AffiliateBalanceBanner(
  props: AffiliateBalanceBannerProps,
) {
  const {
    balance,
    shareLink,
    onLeaveToAffiliatePress,
    onDetailsPress,
    isTemporary,
    style,
  } = props;
  const theme = useTheme();
  const styles = useStyles();
  const strings = useStrings();
  return (
    <View style={[{height: AFFILIATE_BALANCE_BANNER_HEIGHT}, style]}>
      <AffiliateCard contentStyle={styles.cardContent} style={styles.card}>
        <TopContainer>
          <TitleWrapper>
            <Title
              numberOfLines={2}
              adjustsFontSizeToFit
              minimumFontScale={0.8}
              type="body"
              weight="600">
              {strings['bonusBalance.banner.title']}
            </Title>
            <Tooltip onPress={onDetailsPress}>
              <QuestionIcon
                color={theme.chroma(theme.colors.primaryWhite).alpha(0.2).hex()}
              />
            </Tooltip>
          </TitleWrapper>
          <BalanceWrapper>
            <CoinIcon />
            <Balance>{balance || '0'} HSH</Balance>
          </BalanceWrapper>
        </TopContainer>
        <BottomWrapper>
          <AppButton
            Icon={ShareIcon}
            variant={ButtonVariant.Primary}
            onPress={shareLink}
            style={styles.share}>
            {strings['bonusBalance.banner.shareButton']}
          </AppButton>
          {isTemporary ? (
            <AppButton
              Icon={InfoIcon}
              variant={ButtonVariant.White}
              onPress={onDetailsPress}
            />
          ) : (
            <AppButton
              Icon={ArrowIcon}
              variant={ButtonVariant.White}
              onPress={onLeaveToAffiliatePress}
            />
          )}
        </BottomWrapper>
      </AffiliateCard>
    </View>
  );
});

const QuestionIcon = sized(QuestionSvg, 18, 18);
const ArrowIcon = sized(ArrowSvg, 20, 20);
const CoinIcon = sized(CoinSvg, 18, 18);
const ShareIcon = sized(ShareSvg, 20, 20);
const InfoIcon = sized(InfoSvg, 20, 20);
const useStyles = createStylesHook(() => ({
  share: {
    flexGrow: 1,
  },
  card: {
    flex: 1,
  },
  cardContent: {
    flex: 1,
  },
}));

const Title = variance(Typography)((theme) => ({
  root: {
    fontSize: 15,
    lineHeight: 20,
    color: theme.colors.primaryWhite,
  },
}));
const Tooltip = variance(Pressable)(() => ({
  root: {marginLeft: 5},
}));
const TitleWrapper = variance(View)(() => ({
  root: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
const BalanceWrapper = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 8,
    flexShrink: 1,
  },
}));
const Balance = variance(Text)((theme) => ({
  root: {
    ...getMonoFontStyles('700'),
    fontSize: 16,
    lineHeight: 20,
    color: theme.colors.primaryWhite,
    letterSpacing: -0.5,
  },
}));
const BottomWrapper = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    paddingTop: 16,
    marginTop: 'auto',
    gap: 10,
  },
}));
const TopContainer = variance(View)(() => ({
  root: {
    justifyContent: 'space-between',
    gap: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
