import React, {useCallback} from 'react';
import {useTheme} from '../../styling';
import HeaderActionButton from '../../components/HeaderActionButton';
import {sized} from '../../Svg';
import GearSvg from '../../assets/svg/colorless/gear.svg';
import {AppRouteName} from '../AppRouteName';
import ProfileSvg from '../../assets/svg/colorless/profile.svg';
import {useRoot, useStrings} from '../../Root/hooks';
import {observer} from 'mobx-react-lite';
import {ThemeKind} from '../../Appearance';
import MoonSvg from '../../assets/svg/colorless/moon.svg';
import WithdrawSvg from '../../assets/svg/colorless/withdraw.svg';
import SettingsSvg from '../../assets/svg/colorless/settings.svg';
import useNotificationsCount from '../BottomTab/useNotificationsCount';
import {AccountType} from '../../Auth';
import {Platform} from 'react-native';
import {useOpenStoreManageSubscription} from '../../CurrentSubscription';

export type GetScreenSegmentsHookParams = {
  routeName?: AppRouteName;
  navigation: any; // TODO Needs to be typed
};

export enum SpecificSegmentsKind {
  Unknown,
  Dashboard,
  Withdraw,
  PaymentSuccess,
  Menu,
  Notifications,
  Plans,
  Auth,
  Payment,
  Statistic,
  Products,
}

export type BaseSegments = {
  routeName: AppRouteName | undefined;
  title?: string;
  notificationsCount?: number;
};

export type UnknownSegments = BaseSegments & {
  kind: SpecificSegmentsKind.Unknown;
};

export type DashboardSegments = BaseSegments & {
  kind: SpecificSegmentsKind.Dashboard;
  settingsIcon: React.ReactNode;
  profileIcon: React.ReactNode;
};

export type WithdrawSegments = BaseSegments & {
  kind: SpecificSegmentsKind.Withdraw;
  withdrawHistoryIcon: React.ReactNode;
};

export type PaymentSuccessSegments = BaseSegments & {
  kind: SpecificSegmentsKind.PaymentSuccess;
  withdrawHistoryIcon: React.ReactNode;
};

export type MenuSegments = BaseSegments & {
  kind: SpecificSegmentsKind.Menu;
  themeSwitcherIcon: React.ReactNode;
};

export type NotificationsSegments = BaseSegments & {
  kind: SpecificSegmentsKind.Notifications;
  settingsIcon: React.ReactNode;
};

export type PlansSegments = BaseSegments & {
  kind: SpecificSegmentsKind.Plans;
  settingsIcon: React.ReactNode;
};
export type ProductsSegments = BaseSegments & {
  kind: SpecificSegmentsKind.Products;
  settingsIcon: React.ReactNode;
};

export type AuthSegments = BaseSegments & {
  kind: SpecificSegmentsKind.Auth;
};

export type PaymentSegments = BaseSegments & {
  kind: SpecificSegmentsKind.Payment;
};

export type StatisticsSegments = BaseSegments & any;

export type RouteSegments =
  | UnknownSegments
  | DashboardSegments
  | WithdrawSegments
  | PaymentSuccessSegments
  | MenuSegments
  | PlansSegments
  | NotificationsSegments
  | AuthSegments
  | StatisticsSegments
  | PaymentSegments
  | ProductsSegments;

export default function useGetScreenSegments() {
  const getHeaderTitle = useGetScreenTitle();
  const theme = useTheme();
  const notificationsCount = useNotificationsCount();
  const {auth, inAppOffers, currentSubscriptionOfferService} = useRoot();
  const openStoreManageSubscription = useOpenStoreManageSubscription();
  const visibleManage =
    inAppOffers.isLoadedIn &&
    currentSubscriptionOfferService.currentSubscriptionOffer !== undefined &&
    Platform.OS !== 'web';

  return useCallback(
    ({routeName, navigation}: GetScreenSegmentsHookParams): RouteSegments => {
      if (!routeName) {
        return {
          routeName,
          kind: SpecificSegmentsKind.Unknown,
        };
      }

      const title = getHeaderTitle(routeName);

      const baseSegments = {
        routeName,
        title,
      };

      switch (routeName) {
        case 'Dashboard':
          return {
            ...baseSegments,
            kind: SpecificSegmentsKind.Dashboard,
            settingsIcon: (
              <HeaderActionButton
                onPress={() => navigation.navigate('DashboardSettings')}
              >
                <GearIcon color={theme.colors.uiSecondary} />
              </HeaderActionButton>
            ),
            profileIcon: (
              <HeaderActionButton onPress={() => navigation.navigate('Menu')} testID="hd_profile">
                <ProfileIcon color={theme.colors.uiSecondary} />
              </HeaderActionButton>
            ),
          };
        case 'Withdraw':
          return {
            ...baseSegments,
            kind: SpecificSegmentsKind.Withdraw,
            withdrawHistoryIcon: (
              <HeaderActionButton
                testID="sendbtc_btc_history"
                onPress={() => navigation.navigate('WithdrawalHistory')}>
                {auth.accountType !== AccountType.Temporary && (
                  <WithdrawIcon color={theme.colors.uiSecondary} />
                )}
              </HeaderActionButton>
            ),
          };
        case 'PaymentSuccess':
          return {
            ...baseSegments,
            kind: SpecificSegmentsKind.Withdraw,
            withdrawHistoryIcon: (
              <HeaderActionButton
                testID="sendbtc_btc_history"
                onPress={() => navigation.navigate('WithdrawalHistory')}>
                <WithdrawIcon color={theme.colors.uiSecondary} />
              </HeaderActionButton>
            ),
          };
        case 'Menu':
          return {
            ...baseSegments,
            kind: SpecificSegmentsKind.Menu,
            themeSwitcherIcon: <HeaderMenuThemeToggle />,
          };
        case 'Notifications':
          return {
            ...baseSegments,
            kind: SpecificSegmentsKind.Notifications,
            settingsIcon: (
              <HeaderActionButton
                testID="logactivity_notif_btn"
                onPress={() => navigation.navigate('NotificationsSettings')}>
                <SettingsIcon color={theme.colors.uiSecondary} />
              </HeaderActionButton>
            ),
            notificationsCount,
          };
        case 'Statistics':
          return {
            ...baseSegments,
            kind: SpecificSegmentsKind.Statistic,
          };
        case 'Plan':
          return {
            ...baseSegments,
            kind: SpecificSegmentsKind.Plans,
            settingsIcon: visibleManage && (
              <HeaderActionButton onPress={() => openStoreManageSubscription()}>
                <SettingsIcon color={theme.colors.uiSecondary} />
              </HeaderActionButton>
            ),
          };
        case 'Products':
          return {
            ...baseSegments,
            kind: SpecificSegmentsKind.Products,
          };
        case 'Auth':
          return {
            ...baseSegments,
            kind: SpecificSegmentsKind.Auth,
          };
        case 'Payment':
          return {
            ...baseSegments,
            kind: SpecificSegmentsKind.Payment,
          };
      }

      return {
        ...baseSegments,
        kind: SpecificSegmentsKind.Unknown,
      };
    },
    [
      getHeaderTitle,
      theme,
      auth,
      notificationsCount,
      visibleManage,
      openStoreManageSubscription,
    ],
  );
}

const ProfileIcon = sized(ProfileSvg, 28);
const GearIcon = sized(GearSvg, 28);
const WithdrawIcon = sized(WithdrawSvg, 28);
const SettingsIcon = sized(SettingsSvg, 28);

export function useGetScreenTitle() {
  const strings = useStrings();
  return useCallback(
    (routeName: AppRouteName) => {
      switch (routeName) {
        case 'WorkerDetails':
          return strings['screenTitle.workerDetails'];
        case 'AccessRecovery':
          return strings['screenTitle.accessRecovery'];
        case 'Auth':
        case 'CheckAuth':
          return undefined;
        case 'QuickStart':
          return strings['quickStart.screenTitle'];
        case 'Menu':
          return strings['screenTitle.profileMenu'];
        case 'AccountSwitch':
          return strings['screenTitle.accountSwitch'];
        case 'WithdrawalHistory':
          return strings['screenTitle.withdrawHistory'];
        case 'DashboardSettings':
          return strings['screenTitle.dashboardSettings'];
        case 'NotificationsSettings':
          return strings['screenTitle.notificationsSettings'];
        case 'Plan':
          return strings['screenTitle.plans'];
        case 'SubscriptionManagement':
          return strings['menu.manageSubscription'];
        case 'Schedule':
          return strings['screenTitle.scheduler'];
        case 'QRCodeScanner':
          return strings['screenTitle.qrCodeScanner'];
        case 'RenewAccess':
          return strings['screenTitle.renewAccess'];
        case 'Dashboard':
          return strings['screenTitle.dashboard'];
        case 'Statistics':
          return strings['screenTitle.statistics'];
        case 'MockAddWorker':
        case 'AddWorker':
          return strings['screenTitle.addWorker'];
        case 'Withdraw':
        case 'PaymentSuccess':
          return strings['withdraw.title_2'];
        case 'Notifications':
          return strings['screenTitle.notifications'];
        case 'Payment':
          return strings['screenTitle.payment'];
        case 'Products':
          return strings['screenTitle.products'];
        case 'BonusProgram':
          return strings['screenTitle.bonusProgram'];
        //   DEBUG
        case 'Debug':
          return 'Debug';
        case 'EnvironmentForm':
          return 'Environment Form';
        case 'EnvironmentList':
          return 'Environment List';
        case 'DatabaseImport':
          return 'Database Import';
        // # DEBUG
      }
    },
    [strings],
  );
}

const MoonIcon = sized(MoonSvg, 28);
export const HeaderMenuThemeToggle = observer(() => {
  const {appearance} = useRoot();
  const theme = useTheme();

  const changeTheme = useCallback(() => {
    const {isDark, setThemeMode} = appearance;
    return setThemeMode(isDark ? ThemeKind.Light : ThemeKind.Dark);
  }, [appearance]);

  return (
    <HeaderActionButton onPress={changeTheme} testID="profile_theme_toggle">
      <MoonIcon color={theme.colors.uiSecondary} />
    </HeaderActionButton>
  );
});
