import {observer} from 'mobx-react-lite';
import React, {PropsWithChildren} from 'react';
import {
  View,
  StyleSheet,
  Platform,
  Image,
  ViewProps,
  StyleProp,
  ViewStyle,
} from 'react-native';
import {variance} from '../../../../styling';
import {GOLDEN_BACKGROUND} from '../../../molecules/ColoredBanner';

export type AffiliateCardProps = PropsWithChildren<ViewProps> & {
  contentStyle?: StyleProp<ViewStyle>;
};

export default observer(function AffiliateCard(props: AffiliateCardProps) {
  const {children, contentStyle} = props;
  return (
    <Wrapper {...props}>
      <BgContainer>
        <Bg source={GOLDEN_BACKGROUND} resizeMode="cover" />
      </BgContainer>
      <Content style={contentStyle}>{children}</Content>
    </Wrapper>
  );
});

const Wrapper = variance(View)(() => ({
  root: {
    ...Platform.select({
      ios: {
        shadowColor: '#ADADAD33',
        shadowOffset: {
          width: 0,
          height: 6,
        },
        shadowOpacity: 0.4,
        shadowRadius: 8,
      },
      web: {
        boxShadow: ' 0px 14px 24px -8px rgba(173, 173, 173, 0.20)',
      },
    }),
  },
}));
const Bg = variance(Image)(() => ({
  root: {
    height: '100%',
    width: '100%',
  },
}));
const Content = variance(View)(() => ({
  root: {
    paddingTop: 16,
    paddingHorizontal: 12,
    paddingBottom: 12,
  },
}));

const BgContainer = variance(View)(() => ({
  root: {
    ...StyleSheet.absoluteFillObject,
    top: 0,
    borderRadius: 12,
    overflow: 'hidden',
  },
}));
