import React from 'react';
import {observer} from 'mobx-react-lite';
import {Platform, StyleSheet, View} from 'react-native';
import {Header} from '../../Typography';
import {
  RouteSegments,
  SpecificSegmentsKind,
} from '../../../Navigation/hooks/useGetRouteSegments';
import {useTheme, variance} from '../../../styling';
import BadgeCounter from '../../atoms/BadgeCounter';
import ArrowLeftSvg from '../../../assets/svg/colorless/arrowLeft.svg';
import sized from '../../../Svg/sized';
import HeaderActionButton from '../../HeaderActionButton';

export type TitleProps = {
  segments: RouteSegments;
  canBack?: boolean;
  goBack?: () => void;
  testIdGoBack?: string;
};

export default observer(function Title({
  segments,
  canBack,
  goBack,
  testIdGoBack,
}: TitleProps) {
  const {notificationsCount, title} = segments;
  const renderTitleIcon = () => {
    switch (segments.kind) {
      case SpecificSegmentsKind.Dashboard:
        return segments.settingsIcon;
      case SpecificSegmentsKind.Withdraw:
        return segments.withdrawHistoryIcon;
      case SpecificSegmentsKind.Notifications:
        return segments.settingsIcon;
      default:
        return null;
    }
  };
  const icon = renderTitleIcon();
  const theme = useTheme();
  const hasCounter =
    notificationsCount !== undefined && notificationsCount !== 0;
  return (
    <View style={styles.root}>
      {canBack && (
        <StyledHeaderActionButton onPress={goBack} testID={testIdGoBack}>
          <ArrowIcon color={theme.colors.uiSecondary} />
        </StyledHeaderActionButton>
      )}
      {title ? (
        <View
          style={[styles.titleView, icon !== null && styles.titleViewOffset]}>
          <TitleText
            style={styles.title}
            hasCounter={hasCounter}
            type="header"
            size="h2"
            {...scaleProps}>
            {title}
          </TitleText>
          {hasCounter && <BadgeCounter label={notificationsCount} />}
        </View>
      ) : null}
      {icon}
    </View>
  );
});

const ArrowIcon = sized(ArrowLeftSvg, 18, 16);

const scaleProps = Platform.select({
  web: {
    numberOfLines: 1,
  },
  default: {
    adjustsFontSizeToFit: true,
    minimumFontScale: 0.7,
    numberOfLines: 2,
  },
});

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
  },
  titleView: {
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 1,
  },
  titleViewOffset: {
    marginRight: 15,
  },
  title: {
    fontSize: 23,
    ...Platform.select({
      web: {},
      default: {
        lineHeight: undefined, // for correct font scaling
      },
    }),
  },
});

const TitleText = variance(Header)(() => ({
  root: {},
  hasCounter: {
    marginRight: 10,
  },
}));

const StyledHeaderActionButton = variance(HeaderActionButton)(() => ({
  root: {
    marginRight: 10,
  },
}));
