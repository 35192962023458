import React, {useCallback, useMemo} from 'react';

import {useStyles, useTheme} from '../styling';
import {Pressable, StyleSheet, View} from 'react-native';
import {observer} from 'mobx-react-lite';
import Animated, {useAnimatedStyle, withSpring} from 'react-native-reanimated';

export type SwitchComponentProps = {
  value: boolean;
  onValueChange: (value: boolean) => void;
  backgroundActive?: string;
  backgroundInactive?: string;
  renderInsideCircle?: () => React.ReactNode;
  testID?: string;
};

export default observer(
  ({
    value,
    onValueChange,
    backgroundActive,
    backgroundInactive,
    renderInsideCircle,
    testID,
  }: SwitchComponentProps) => {
    const animatedPosition = useAnimatedStyle(() => ({
      transform: [
        {
          translateX: withSpring(value ? 24 : 0, {
            damping: 100,
            stiffness: 90,
          }),
        },
      ],
    }));

    const animatedBackground = useAnimatedStyle(() => ({
      opacity: withSpring(value ? 1 : 0),
    }));

    const themedStyles = useStyles((theme) => ({
      circle: {
        width: 24,
        height: 24,
        borderRadius: 24,
        backgroundColor: theme.colors.primaryWhite,
        alignItems: 'center',
        justifyContent: 'center',
      },
    }));

    const onPress = useCallback(
      () => onValueChange(!value),
      [onValueChange, value],
    );

    const theme = useTheme();
    const backgroundActiveColor = useMemo(
      () =>
        backgroundActive ||
        theme.select(theme.colors.primaryBlack, theme.colors.primaryAttractive),
      [backgroundActive, theme],
    );

    return (
      <Pressable style={styles.wrapper} onPress={onPress} testID={testID}>
        <View
          style={[
            styles.container,
            {backgroundColor: backgroundInactive || theme.colors.uiAdditional4},
          ]}>
          <Animated.View
            style={[
              styles.activeBackground,
              {backgroundColor: backgroundActiveColor},
              animatedBackground,
            ]}
          />
          <Animated.View style={[themedStyles.circle, animatedPosition]}>
            {renderInsideCircle?.()}
          </Animated.View>
        </View>
      </Pressable>
    );
  },
);

const styles = StyleSheet.create({
  container: {
    width: 52,
    height: 28,
    padding: 2,
    borderRadius: 14,
  },
  activeBackground: {
    ...StyleSheet.absoluteFillObject,
    width: 52,
    height: 28,
    borderRadius: 14,
  },
  wrapper: {
    width: 52,
    height: 28,
  },
});
