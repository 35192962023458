import {
  GestureResponderEvent,
  TouchableWithoutFeedback,
  ViewProps,
  View,
  TouchableWithoutFeedbackProps,
} from 'react-native';
import {Millisecond} from '../utils/time';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useIntlFormatter} from '../Root/hooks';
import {variance} from '../styling';
import {Paragraph} from '../components';

export type FilterViewProps = ViewProps & {
  onPress?: (event: GestureResponderEvent) => void;
  from: Millisecond;
  to: Millisecond;
  modalShown: boolean;
};

export default observer(
  ({onPress, modalShown, from, to, ...rest}: FilterViewProps) => {
    const {dateFormatter} = useIntlFormatter();
    const Wrapper = (
      modalShown ? View : TouchableWithoutFeedback
    ) as React.ComponentType<ViewProps | TouchableWithoutFeedbackProps>;
    return (
      <View {...rest}>
        <Wrapper onPress={onPress} testID="statistics_colon_sign">
          <Period>
            <Paragraph type="paragraph" size="large" weight="500">
              {dateFormatter(from, {month: '2-digit', day: '2-digit'})}
              {' – '}
              {dateFormatter(to, {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
              })}
            </Paragraph>
            <View>
              <Dot first />
              <Dot />
            </View>
          </Period>
        </Wrapper>
      </View>
    );
  },
);

const Period = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));

const Dot = variance(View)((theme) => ({
  root: {
    width: 3,
    height: 3,
    borderRadius: 3,
    backgroundColor: theme.colors.primaryUIDirtyBlue,
    marginLeft: 4,
  },
  first: {
    marginBottom: 2,
  },
}));
