import React from 'react';
import {observer} from 'mobx-react-lite';
import {WorkerList} from './WorkerList';
import {InteractiveTutorialView} from '../InteractiveTutorialView';
import Portal from '../Portal';
import {DashboardContext} from './context';
import {WorkerId} from '../ApiStore';
import {Mode} from '../DashboardMode';
import HeaderStatusBanner from './components/HeaderStatusBanner';
import {StyleSheet, View} from 'react-native';
import {AdInfoItem} from '../Advert';
import IntroVideoBanner from '../components/organisms/banner/IntroVideoBanner';

export type DashboardScreenProps = {
  dashboardMode: Mode;
  changeDashboardMode: (newMode: Mode) => void;
  goToAddWorker: () => void;
  goToWithdraw: () => void;
  goToScanQr: () => void;
  goToLinkWorker: () => void;
  goToReadMore: () => void;
  goToPickDemoMiner: () => void;
  safelyGoToPromoAffiliate: () => void;
  getAffiliateIsPending: () => boolean;
  goToWorkerAction: (workerId: WorkerId) => void;
  goToWorkerDetails: (workerId: WorkerId) => void;
  goToRenewAccess: (workerId: WorkerId) => void;
  goToWorkerGroupAction: (groupId: number) => void;
  goToAddNewWorkerGroup: () => void;
  goToRenameWorkerGroup: (groupId: number) => void;
  goToPickDashboardSort: () => void;
  goToPoolActivator: () => void;
  goToPlan: () => void;
  goToPoolMinerSchedulerWarning: (workerId: WorkerId) => void;
  goToCopyDiscountLink: () => void;
  onWorkerSpeedChanged: (speed: number, workerId: WorkerId) => Promise<void>;
  getAdvertMobileBanner: () => AdInfoItem | undefined;
  getAdvertDesktopBanner: () => AdInfoItem | undefined;
  goToLimitedOffer: () => void;
  closeAffiliateBanner: () => void;
  getAffiliateBannerVisible: () => boolean;
  goToSwitchDemo: () => void;
  goToIntroVideo: () => void;
  goToIntroVideoCloseConfirm: () => void;
  goToBonusProgram: () => void;
  goToAffiliate: () => void;
  videoBannerIsVisible: boolean;
  shareBalance: () => void;
};

export default observer(function DashboardScreen(props: DashboardScreenProps) {
  const {goToIntroVideo, goToIntroVideoCloseConfirm, videoBannerIsVisible} =
    props;

  return (
    <>
      <DashboardContext.Provider value={props}>
        <View style={styles.root}>
          <HeaderStatusBanner />
          <WorkerList />
        </View>
      </DashboardContext.Provider>
      <Portal>
        <InteractiveTutorialView />
      </Portal>
      {videoBannerIsVisible && (
        <IntroVideoBanner
          goToIntroVideo={goToIntroVideo}
          goToIntroVideoCloseConfirm={goToIntroVideoCloseConfirm}
        />
      )}
    </>
  );
});

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
});
