import React, {useCallback, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {StateVisibleContent} from './types';
import {RateAppModalizeProvider} from './context';
import {
  AdaptiveModal,
  AdaptiveModalRef,
  ModalWidth,
} from '../components/templates';
import {ScrollView} from 'react-native';
import MainContentView from './MainContentView';
import {useStyles} from '../styling';
import {ADAPTIVE_BREAKPOINT, BREAKPOINT_PX} from './constants';

export type RateAppModalProps = {
  goBack: () => void;
};

export default observer(function RateAppModal(props: RateAppModalProps) {
  const {goBack} = props;
  const [selectedRating, setSelectedRating] = useState(0);
  const [visibleContentState, setVisibleContentState] =
    useState<StateVisibleContent>(StateVisibleContent.Rate);
  const modalRef = useRef<AdaptiveModalRef>(null);
  const dismiss = useCallback(async () => {
    await modalRef.current?.close();
    goBack();
  }, [goBack]);
  const goToComment = (rating: number) => {
    setSelectedRating(rating);
    setVisibleContentState(StateVisibleContent.Comment);
  };
  const goToRateUs = (rating: number) => {
    setSelectedRating(rating);
    setVisibleContentState(StateVisibleContent.RateUs);
  };
  const goToThanks = () => setVisibleContentState(StateVisibleContent.Thanks);
  const styles = useStyles((theme) => ({
    container: {
      ...theme.mediaQuery({
        [BREAKPOINT_PX]: {
          width: 375,
        },
      }),
    },
  }));
  const value = {
    goToThanks: goToThanks,
    goToRateUs: goToRateUs,
    dismiss: dismiss,
    goToComment: goToComment,
    visibleContent: visibleContentState,
    selectedRating: selectedRating,
  };
  return (
    <AdaptiveModal
      onModalClosed={dismiss}
      modalWidth={ModalWidth.Small}
      ref={modalRef}
      enableContentAutoHeight
      adaptiveBreakpoint={ADAPTIVE_BREAKPOINT}
      testIdCloseIcon="modal_close_btn">
      <RateAppModalizeProvider value={value}>
        <ScrollView bounces={false} contentContainerStyle={styles.container}>
          <MainContentView />
        </ScrollView>
      </RateAppModalizeProvider>
    </AdaptiveModal>
  );
});
