import {observer} from 'mobx-react-lite';
import React from 'react';
import {View, StyleSheet, ScrollView} from 'react-native';
import {useWindowDimensions} from '../WindowDimensions';
import HeaderBannerConditionLargeSection from './components/HeaderBannerConditionLargeSection';
import useGetIsLarge from './hooks/useGetIsLarge';
import {expr} from 'mobx-utils';
import EmptyWorkerListDesktop, {
  EmptyWorkerListDesktopProps,
} from './EmptyWorkerListDesktop';
import EmptyWorkerListMobile, {
  EmptyWorkerListMobileProps,
} from './EmptyWorkerListMobile';
import {variance} from '../styling';
import {useForcedContext} from '../context';
import {DashboardContext} from './context';
import {INTRO_BANNER_HEIGHT} from '../components/organisms/banner/IntroVideoBanner';

export type EmptyWorkerListProps = EmptyWorkerListDesktopProps &
  EmptyWorkerListMobileProps & {
    renderRefreshControl: () => JSX.Element | undefined;
  };

export default observer(function EmptyWorkerList({
  renderRefreshControl,
  ...rest
}: EmptyWorkerListProps) {
  const {videoBannerIsVisible} = useForcedContext(DashboardContext);
  const getIsLarge = useGetIsLarge();
  const isLarge = expr(() => getIsLarge());
  const {height} = useWindowDimensions();
  return (
    <ScrollView
      style={[styles.root, {minHeight: height * 0.8}]}
      contentContainerStyle={[
        styles.container,
        videoBannerIsVisible && {paddingBottom: INTRO_BANNER_HEIGHT},
      ]}
      refreshControl={renderRefreshControl()}>
      {isLarge ? (
        <View style={[styles.largePadding, styles.largeContent]}>
          <View style={styles.largeHeader}>
            <HeaderBannerConditionLargeSection />
          </View>
          <LargeContentView large={isLarge}>
            <EmptyWorkerListDesktop {...rest} />
          </LargeContentView>
        </View>
      ) : (
        <EmptyWorkerListMobile style={styles.mobileContent} {...rest} />
      )}
    </ScrollView>
  );
});

const styles = StyleSheet.create({
  root: {
    paddingTop: 16,
  },
  container: {
    paddingBottom: 16,
    flexGrow: 1,
  },
  mobileContent: {
    flex: 1,
  },
  largePadding: {
    paddingHorizontal: 16,
  },
  largeContent: {
    flex: 1,
  },
  largeHeader: {
    marginBottom: 18,
  },
  farmInfoContainer: {
    paddingBottom: 18,
  },
});

const LargeContentView = variance(View)((theme) => ({
  root: {
    flex: 1,
    borderWidth: 1,
    borderColor: theme.colors.uiAdditional1,
    paddingHorizontal: 18,
  },
  large: {
    padding: 20,
    backgroundColor: theme.colors.uiMain,
    borderRadius: 12,
  },
}));
