import React from 'react';
import {observer} from 'mobx-react-lite';
import {Typography} from '../components';
import {useStrings} from '../Root/hooks';
import CheckSvg from '../assets/svg/circled/check.svg';
import {View} from 'react-native';
import {sized} from '../Svg';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {useStyles} from '../styling';
import useSafePaddingBottom from '../components/templates/AdaptiveModal/useSafePaddingBottom';

export type ThanksProps = {
  onSubmit: () => void;
};

export default observer(function Thanks({onSubmit}: ThanksProps) {
  const strings = useStrings();
  const styles = useStyles((theme) => ({
    root: {
      padding: 16,
    },
    iconView: {
      alignItems: 'center',
      marginBottom: 8,
    },
    titleText: {
      textAlign: 'center',
      marginBottom: 8,
    },
    descText: {
      textAlign: 'center',
      color: theme.colors.primaryUIDirtyBlue,
    },
    button: {
      marginTop: 24,
    },
  }));
  const paddingBottom = useSafePaddingBottom(16);
  return (
    <View style={[styles.root, {paddingBottom}]}>
      <View style={styles.iconView}>
        <CheckIcon />
      </View>
      <Typography type="header" size="h2" style={styles.titleText}>
        {strings['modal.rateUs.thanks']}
      </Typography>
      <Typography type="paragraph" weight="500" style={styles.descText}>
        {strings['modal.rateThanks.description']}
      </Typography>
      <AppButton
        testID="modal_feedback_ok_btn"
        variant={ButtonVariant.Primary}
        style={styles.button}
        onPress={onSubmit}>
        {strings['action.ok']}
      </AppButton>
    </View>
  );
});

const CheckIcon = sized(CheckSvg, 64);
