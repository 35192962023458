import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
} from 'react';
import {observer} from 'mobx-react-lite';
import {useTheme, variance} from '../../../styling';
import {sized} from '../../../Svg';
import CloseSvg from '../../../assets/svg/colorless/close.svg';
import PressableOpacity from '../../PressableOpacity';
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native';
import {useWindowDimensions} from '../../../WindowDimensions';
import {TouchableWithoutFeedback} from 'react-native-gesture-handler';
import Animated, {
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
  runOnJS,
} from 'react-native-reanimated';
import {ModalRef, ModalWidth} from './types';

export type PublicModalProps = {
  /**
   * Only works with modal representations
   * (default = .Auto)
   */
  modalWidth?: ModalWidth;
  modalContentStyle?: StyleProp<ViewStyle>;

  closeIconHidden?: boolean;
};

export type ProtectedModalProps = {
  close: () => void;
  onClosed: () => void;
  onShown: () => void;
};

export type ModalProps = PublicModalProps &
  ProtectedModalProps & {
    children: React.ReactNode;
    testIdCloseIcon?: string;
  };

export default observer(
  forwardRef(function Modal(props: ModalProps, ref: ForwardedRef<ModalRef>) {
    const {
      children,
      modalContentStyle,
      onShown,
      onClosed,
      closeIconHidden,
      modalWidth,
      testIdCloseIcon,
    } = props;
    const transitionValue = useSharedValue(0);
    const windowDimensions = useWindowDimensions();

    const open = useCallback(
      () =>
        (transitionValue.value = withTiming(
          1,
          {
            duration: 250,
          },
          (finished) => {
            if (finished) {
              runOnJS(onShown)();
            }
          },
        )),
      [onShown, transitionValue],
    );

    const close = useCallback(
      () =>
        (transitionValue.value = withTiming(
          0,
          {
            duration: 250,
          },
          (finished) => {
            if (finished) {
              runOnJS(onClosed)();
            }
          },
        )),
      [onClosed, transitionValue],
    );

    useEffect(() => {
      open();
    }, [open, transitionValue]);

    useImperativeHandle(ref, () => ({
      open,
      close,
    }));

    const theme = useTheme();
    const maxWidth = windowDimensions.width - 18.95 * 2;
    const maxHeight = windowDimensions.height - 18.95 * 2;

    const animatedBackgroundStyles = useAnimatedStyle(() => ({
      opacity: interpolate(transitionValue.value, [0, 1], [0, 1]),
    }));

    const animatedTransformStyles = useAnimatedStyle(() => ({
      transform: [
        {translateY: interpolate(transitionValue.value, [0, 1], [150, 0])},
      ],
    }));

    return (
      <RootView>
        <AnimatedOverlayView style={animatedBackgroundStyles}>
          <TouchableWithoutFeedback
            containerStyle={styles.touchableOverlay}
            onPress={close}
          />
        </AnimatedOverlayView>
        <Animated.View
          style={[animatedBackgroundStyles, animatedTransformStyles]}>
          <ContentView
            style={[
              {maxWidth, maxHeight},
              modalWidth === ModalWidth.Small && styles.modalSmallContent,
              modalWidth === ModalWidth.Medium && styles.modalMediumContent,
              modalWidth === ModalWidth.Large && styles.modalLargeContent,
              modalContentStyle,
            ]}>
            {children}
            {!closeIconHidden && (
              <View style={styles.close}>
                <PressableOpacity onPress={close} testID={testIdCloseIcon} style={{padding: 15}}>
                  <CloseIcon color={theme.colors.primaryUIDirtyBlue} />
                </PressableOpacity>
              </View>
            )}
          </ContentView>
        </Animated.View>
      </RootView>
    );
  }),
);

const CloseIcon = sized(CloseSvg, 10);

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
  },
  close: {
    position: 'absolute',
    right: 5,
    top: 5,
  },
  modalSmallContent: {
    width: 372,
  },
  modalMediumContent: {
    width: 700,
  },
  modalLargeContent: {
    width: 1152,
  },
  touchableOverlay: {
    flex: 1,
  },
});

const RootView = variance(View)(() => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
}));

const ContentView = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.colors.uiMain,
    borderRadius: 12,
  },
}));

const AnimatedOverlayView = variance(Animated.View)((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.colors.backgroundBlackout,
  },
}));
