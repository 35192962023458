import {StyleSheet, Text, View, ViewProps} from 'react-native';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useIntlFormatter} from '../Root/hooks';
import {variance} from '../styling';
import {getCommissionerFontStyles, getMonoFontStyles} from '../components';

export enum TooltipScope {
  Date,
  Time,
}

export type TooltipProps = ViewProps & {
  timestamp: number;
  scope: TooltipScope;
  title: string;
  value: number;
  fractionDigits: number;
  secondaryTitle?: string;
  secondaryValue?: number;
  secondaryFractionDigits?: number;
};

export default observer(
  ({
    timestamp,
    scope,
    title,
    value,
    fractionDigits,
    secondaryTitle,
    secondaryValue,
    secondaryFractionDigits,
    style,
    ...rest
  }: TooltipProps) => {
    const {dateFormatter, intFormatter} = useIntlFormatter();

    return (
      <RootView style={style} {...rest}>
        <DateText>
          {dateFormatter(timestamp, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            ...(scope !== TooltipScope.Date && {
              hour: '2-digit',
              minute: '2-digit',
            }),
          })}
        </DateText>
        <LabelText>{title}:</LabelText>
        <ValueText>{intFormatter(value.toFixed(fractionDigits))}</ValueText>
        {secondaryTitle !== undefined &&
          secondaryValue !== undefined &&
          secondaryFractionDigits !== undefined && (
            <View style={styles.root}>
              <LabelText>{secondaryTitle}:</LabelText>
              <ValueText>
                {secondaryValue.toFixed(secondaryFractionDigits)}
              </ValueText>
            </View>
          )}
      </RootView>
    );
  },
);

const styles = StyleSheet.create({
  root: {
    marginTop: 5,
  },
});

const RootView = variance(View)((theme) => ({
  root: {
    minWidth: 93,
    backgroundColor: theme.select(
      theme.chroma(theme.colors.primaryBlack).alpha(0.8).hex(),
      theme.chroma('#1E222F').alpha(0.8).hex(),
    ),
    borderWidth: 1,
    borderColor: theme.select(
      theme.chroma(theme.colors.primaryBlack).alpha(0.5).hex(),
      theme.chroma('#DDDDDD').alpha(0.2).hex(),
    ),
    borderRadius: 4,
    padding: 8,
  },
}));

const DateText = variance(Text)((theme) => ({
  root: {
    ...getCommissionerFontStyles('500'),
    fontSize: 10,
    lineHeight: 12,
    marginBottom: 6,
    color: theme.colors.primaryAttractive,
  },
}));

const LabelText = variance(Text)((theme) => ({
  root: {
    ...getCommissionerFontStyles('500'),
    fontSize: 10,
    lineHeight: 12,
    marginBottom: 4,
    color: theme.colors.primaryWhite,
  },
}));

const ValueText = variance(Text)((theme) => ({
  root: {
    ...getMonoFontStyles('500'),
    fontSize: 12,
    lineHeight: 16,
    color: theme.colors.primaryWhite,
  },
}));
