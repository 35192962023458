import {observer} from 'mobx-react-lite';
import React, {ForwardedRef, forwardRef} from 'react';
import {AdaptiveModalRef} from '../components/templates';
import {AdaptiveActionModal} from '../components/organisms';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {useStrings} from '../Root/hooks';
import DoorSvg from '../assets/svg/colorless/door.svg';
import {SvgProps} from 'react-native-svg';
import {TwoToneIcon} from '../components/atoms';
import {sized} from '../Svg';

export type LogoutConfirmModalProps = {
  goToAccountSwitch: () => void;
  signOut: () => void;
  onModalClosed: () => void;
};

export default observer(
  forwardRef(function LogoutConfirmModal(
    props: LogoutConfirmModalProps,
    ref: ForwardedRef<AdaptiveModalRef>,
  ) {
    const strings = useStrings();
    const {goToAccountSwitch, signOut, onModalClosed} = props;
    return (
      <AdaptiveActionModal
        ref={ref}
        onModalClosed={onModalClosed}
        IconSvg={DoorIcon}
        IconSvgModalRep={DoorIconLarge}
        title={strings['signOutModal.title']}
        description={strings['signOutModal.description']}
        actions={[
          <AppButton
            testID="modal_switch_acc_btn"
            onPress={goToAccountSwitch}
            variant={ButtonVariant.Primary}>
            {strings['signOutModal.switchAccount']}
          </AppButton>,
          <AppButton testID="modal_sign_out_btn" onPress={signOut}>{strings['menu.signOut']}</AppButton>,
        ]}
      />
    );
  }),
);

const DoorIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(DoorSvg, 22, 28)} {...props} />
));

const DoorIconLarge = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(DoorSvg, 30, 36)} {...props} />
));
