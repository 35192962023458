import {FlashMessageService, Message, RequestedMessage} from './Message';
import {observable, runInAction, makeObservable, action} from 'mobx';
import {nanoid} from 'nanoid';

const _DEFAULT_TIMEOUT = 3000;

export default class FlashMessageServiceImpl implements FlashMessageService {
  @observable private _messages: Message[] = [];

  constructor() {
    makeObservable(this);
  }

  get messages() {
    return this._messages;
  }

  private _getMessages() {
    return this.messages;
  }

  getMessages = () => this._getMessages();

  private _showMessage(message: RequestedMessage) {
    const newMessage = {
      timeout: _DEFAULT_TIMEOUT,
      ...message,
      id: nanoid(),
    };
    runInAction(() =>
      message.single
        ? (this._messages = [newMessage])
        : this._messages.unshift(newMessage),
    );
  }

  showMessage = (message: RequestedMessage) => this._showMessage(message);

  private _removeMessage = action((id: string) => {
    this._messages = this._messages.filter((_) => _.id !== id);
  });

  removeMessage = (id: string) => this._removeMessage(id);
}
