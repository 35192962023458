import React from 'react';
import {observer} from 'mobx-react-lite';
import {View, StyleSheet, ViewStyle, StyleProp, TextStyle} from 'react-native';
import Typography from '../../Typography';
import {useTheme, variance} from '../../../styling';
import {colored, sized} from '../../../Svg';
import MenuDotsSvg from '../../../assets/svg/colorless/menuDots.svg';
import ChevronRightSvg from '../../../assets/svg/colorless/chevronRight.svg';
import PressableOpacity from '../../PressableOpacity';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {getWorkerName} from '../../../DashboardScreen';
import useWorkerStatuses from '../../../DashboardScreen/hooks/useWorkerStatuses';
import TwoToneFlashSvg from '../../../assets/svg/twoTone/flash.svg';
import DarkFlashSvg from '../../../assets/svg/colored/darkFlash.svg';
import GreenFlashGiftSvg from '../../../assets/svg/colored/greenLightGift.svg';
import {SvgProps} from 'react-native-svg';
import {TwoToneIcon} from '../../atoms';
import ComputerSvg from '../../../assets/svg/twoTone/computer.svg';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {LinearGradient} from 'expo-linear-gradient';
import {useIntlFormatter} from '../../../Root/hooks';

export type WorkerMenuHeaderProps = {
  mode: 'detail' | 'simple';
  worker: ReadonlyDeep<WorkerEntity>;
  onMenuPress?: () => void;
  index?: string | number;
  onHeaderPress?: () => void;
};

export default observer(function WorkerMenuHeader({
  mode,
  index,
  worker,
  onHeaderPress,
  onMenuPress,
}: WorkerMenuHeaderProps) {
  const {disabled, needActivate, expired, poolMiner} =
    useWorkerStatuses(worker);
  const theme = useTheme();
  const indexText = index !== undefined ? index + '. ' : undefined;
  let textColor = theme.colors.uiSecondary;
  let dotsColor = theme.colors.primaryUIDirtyBlue;
  let backgroundColor = [
    theme.colors.uiAdditional1,
    theme.colors.uiAdditional1,
  ];
  let badgeBackStyle: StyleProp<ViewStyle> = {};
  let badgeTextStyle: StyleProp<TextStyle> = {};
  if (expired) {
    textColor = theme.colors.primaryWhite;
    dotsColor = theme.colors.primaryWhite;
    backgroundColor = theme.colors.gradientWorkerHeaderExpired;
  } else if (needActivate) {
    backgroundColor = theme.colors.gradientWorkerHeaderInactive;
    dotsColor = theme.colors.primaryBlack;
    textColor = theme.colors.primaryBlack;
    badgeBackStyle = {
      backgroundColor: theme.colors.primaryBlack,
    };
    badgeTextStyle = {
      color: theme.colors.primaryWhite,
    };
  } else if (disabled) {
    backgroundColor = [theme.colors.uiAdditional2, theme.colors.uiAdditional2];
  }

  const Icon =
    poolMiner && worker.order_id?.includes('gift:')
      ? GreenFlashGiftIcon
      : poolMiner
      ? TwoToneFlashIcon
      : poolMiner && needActivate
      ? DarkFlashIcon
      : ComputerIcon;

  return (
    <LinearGradient
      colors={backgroundColor}
      start={{x: 0, y: 0}}
      end={{x: 1, y: 0}}>
      <View style={styles.root}>
        <TouchableOpacity
          activeOpacity={0.8}
          disabled={onHeaderPress === undefined}
          onPress={onHeaderPress}
          containerStyle={styles.container}
          style={styles.content}>
          <IndexText type="body" weight="600" style={{color: textColor}}>
            {indexText}
          </IndexText>
          {Icon && (
            <View style={styles.icon}>
              <Icon />
            </View>
          )}
          <WorkerNameText
            numberOfLines={1}
            type="body"
            weight="600"
            style={{color: textColor}}>
            {getWorkerName(worker)}
          </WorkerNameText>
        </TouchableOpacity>
        <View style={styles.right}>
          {poolMiner && (
            <SpeedBadge
              badgeTextStyle={badgeTextStyle}
              badgeBackStyle={badgeBackStyle}
              speed={worker.pool_miner_config?.hash_rate ?? 0}
            />
          )}
          <DotsButton onPress={onMenuPress}>
            {mode === 'detail' ? (
              <MenuDotsIcon color={dotsColor} />
            ) : (
              <ChevronRightIcon color={dotsColor} />
            )}
          </DotsButton>
        </View>
      </View>
    </LinearGradient>
  );
});

type SpeedBadgeProps = {
  speed: number;
  badgeBackStyle?: StyleProp<ViewStyle>;
  badgeTextStyle?: StyleProp<TextStyle>;
};

const SpeedBadge = observer(
  ({speed, badgeBackStyle, badgeTextStyle}: SpeedBadgeProps) => {
    const {intFormatter} = useIntlFormatter();
    return (
      <BadgeRootView style={badgeBackStyle}>
        <BadgeValueText type="body" weight="500" style={badgeTextStyle}>
          {intFormatter(speed.toFixed(0))} H/s
        </BadgeValueText>
      </BadgeRootView>
    );
  },
);

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 31,
  },
  container: {
    flex: 1,
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 5,
    paddingHorizontal: 16,
  },
  right: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 8,
  },
  icon: {
    width: 20,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 4,
  },
});
export const ComputerIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(ComputerSvg, 20, 14)} {...props} />
));
const TwoToneFlashIcon = colored(sized(TwoToneFlashSvg, 20, 20), '#09183F');
const DarkFlashIcon = sized(DarkFlashSvg, 18);
const MenuDotsIcon = sized(MenuDotsSvg, 4, 10);
const ChevronRightIcon = sized(ChevronRightSvg, 6, 12);
const GreenFlashGiftIcon = sized(GreenFlashGiftSvg, 20, 20);

const BadgeRootView = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.colors.primaryAttractive,
    borderRadius: 10,
    paddingVertical: 2,
    paddingHorizontal: 10,
  },
}));
const BadgeValueText = variance(Typography)((theme) => ({
  root: {
    fontSize: 13,
    lineHeight: 16,
    letterSpacing: -0.2,
    color: theme.colors.primaryBlack,
  },
}));

const TitleText = variance(Typography)((theme) => ({
  root: {
    color: theme.colors.uiSecondary,
    fontSize: 12,
    lineHeight: 15,
    letterSpacing: 2,
    textTransform: 'uppercase',
  },
}));

const IndexText = variance(TitleText)(() => ({
  root: {
    marginRight: 1,
  },
}));

const WorkerNameText = variance(TitleText)(() => ({
  root: {
    flex: 1,
  },
}));

const DotsButton = variance(PressableOpacity)(() => ({
  root: {
    padding: 10,
    paddingHorizontal: 15,
    borderRadius: 8,
  },
}));
