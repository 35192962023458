import React, {useCallback, useEffect} from 'react';
import {View} from 'react-native';
import {observer} from 'mobx-react-lite';
import {Typography} from '../components';
import {useRoot, useStrings, useTemplates} from '../Root/hooks';
import AppStoreSvg from '../assets/svg/colored/appStore.svg';
import GooglePlaySvg from '../assets/svg/colored/googlePlay.svg';
import {colored, sized} from '../Svg';
import {createStylesHook, useTheme} from '../styling';
import StarSvg from '../assets/svg/colorless/roundedStar.svg';
import {AppButton, ButtonVariant} from '../components/AppButton';
import useSafePaddingBottom from '../components/templates/AdaptiveModal/useSafePaddingBottom';
import * as StoreReview from 'expo-store-review';
import LikeSvg from '../assets/svg/colorless/like.svg';
import {useGoToAppStore, useGoToPlayStore} from '../Navigation/hooks';
import {DetectPlatform} from '../DetectPlatform';

export type RateUsRedirectProps = {
  rating: number;
  onClose: () => void;
};

export default observer(function RateUsRedirect({
  rating,
  onClose,
}: RateUsRedirectProps) {
  const {rateApp} = useRoot();
  const strings = useStrings();
  const styles = useStyles();
  const theme = useTheme();
  const templates = useTemplates();
  const goToAppStore = useGoToAppStore();
  const goToPlayStore = useGoToPlayStore();
  const handleOpenAppStore = useCallback(async () => {
    await goToAppStore();
    await rateApp.sendRating({rating});
  }, [goToAppStore, rateApp, rating]);

  const handleOpenGooglePlay = useCallback(async () => {
    await goToPlayStore();
    await rateApp.sendRating({rating});
  }, [goToPlayStore, rateApp, rating]);

  useEffect(() => {
    async function run() {
      if (await StoreReview.isAvailableAsync()) {
        await StoreReview.requestReview();
        await rateApp.sendRating({rating});
      }
    }
    // noinspection JSIgnoredPromiseFromCall
    run();
  }, [rateApp, rating]);
  const marketAndroidText = templates['modal.rateUs.rateInMarket']({
    marketName: strings['modal.rateUs.androidMarketName'],
  });
  const marketAppleText = templates['modal.rateUs.rateInMarket']({
    marketName: strings['modal.rateUs.iosMarketName'],
  });
  const modalRateUsDescription = () => {
    if (!DetectPlatform.isAppleSystem && !DetectPlatform.isAndroidSystem) {
      return strings['modal.rateUs.description.default'];
    } else
      {return templates['modal.rateUs.description']({
        marketName: DetectPlatform.isAppleSystem
          ? strings['modal.rateUs.iosMarketName']
          : strings['modal.rateUs.androidMarketName'],
      });}
  };

  const Like = colored(LikeIcon, theme.colors.uiSecondary);

  const renderButtons = () => {
    if (DetectPlatform.isAppleSystem) {
      return (
        <AppButton onPress={handleOpenAppStore} variant={ButtonVariant.Primary}>
          {strings['modal.rateUs.rateInStore']}
        </AppButton>
      );
    }

    if (DetectPlatform.isAndroidSystem) {
      return (
        <AppButton
          onPress={handleOpenGooglePlay}
          variant={ButtonVariant.Primary}>
          {strings['modal.rateUs.rateInStore']}
        </AppButton>
      );
    }

    return (
      <>
        <AppButton onPress={handleOpenGooglePlay} Icon={GooglePlayIcon} testID="modal_rate_google">
          {marketAndroidText}
        </AppButton>
        <AppButton onPress={handleOpenAppStore} Icon={AppStoreIcon} testID="modal_rate_app_store">
          {marketAppleText}
        </AppButton>
      </>
    );
  };

  const renderIcon = () => {
    if (DetectPlatform.isAppleSystem) {
      return (
        <View style={styles.iconWrapper}>
          <AppStoreIcon />
        </View>
      );
    }
    if (DetectPlatform.isAndroidSystem) {
      return (
        <View style={styles.iconWrapper}>
          <GooglePlayIcon />
        </View>
      );
    }
    return <Like />;
  };
  const paddingBottom = useSafePaddingBottom(16);

  return (
    <View style={[styles.root, {paddingBottom}]}>
      <View style={styles.headerView}>
        {renderIcon()}
        <View style={styles.starList}>
          {new Array(5).fill('').map((_, i) => (
            <View key={i} style={styles.starView}>
              <StarIcon
                color={
                  i < rating
                    ? theme.colors.primaryAttractive
                    : theme.colors.uiAdditional1
                }
              />
            </View>
          ))}
        </View>
      </View>
      <Typography type="header" size="h2" style={styles.titleText}>
        {strings['modal.rateUs.title']}
      </Typography>

      <Typography type="paragraph" weight="500" style={styles.descText}>
        {modalRateUsDescription()}
      </Typography>
      <View style={styles.footerView}>
        {renderButtons()}
        {(DetectPlatform.isAppleSystem || DetectPlatform.isAndroidSystem) && (
          <AppButton onPress={onClose}>{strings['action.close']}</AppButton>
        )}
      </View>
    </View>
  );
});

const StarIcon = sized(StarSvg, 24);
const AppStoreIcon = sized(AppStoreSvg, 30);
const GooglePlayIcon = sized(GooglePlaySvg, 40, 30);
const LikeIcon = sized(LikeSvg, 27);

const useStyles = createStylesHook((theme) => ({
  root: {
    padding: 16,
  },
  modalView: {
    maxWidth: 450,
  },
  headerView: {
    alignItems: 'center',
    marginBottom: 16,
    marginTop: 10,
  },
  starList: {
    flexDirection: 'row',
    marginTop: 6,
  },
  starView: {
    padding: 4,
  },
  titleText: {
    marginBottom: 10,
    textAlign: 'center',
  },
  descText: {
    color: theme.colors.primaryUIDirtyBlue,
    marginBottom: 35,
    textAlign: 'center',
  },
  redirectTimerView: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 15,
    height: 30,
  },
  redirectText: {
    fontSize: 11,
    lineHeight: 13,
    color: theme.colors.primaryUIDirtyBlue,
  },
  iconWrapper: {
    width: 60,
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
  },

  footerView: {gap: 15},
}));
