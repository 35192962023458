import {observer} from 'mobx-react-lite';
import React from 'react';
import {sized} from '../Svg';
import {useTheme, variance} from '../styling';
import {Header} from '../components';
import {View, StyleSheet, StyleProp, ViewStyle} from 'react-native';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {useRoot, useStrings} from '../Root/hooks';
import {ApiMode} from '../farmApi';
import MinerSvg from '../assets/svg/grayish/miner.svg';
import MinerDarkSvg from '../assets/svg/grayish/minerDark.svg';
import QuadFlash from '../assets/svg/colorless/quadFlash.svg';
import PlusCircle from '../assets/svg/circled/circlePlus.svg';
import HeaderBannerConditionSection from './components/HeaderBannerConditionSection';

export type EmptyWorkerListMobileProps = {
  goToAddDemoMiner: () => void;
  goToAddMiner: () => void;
  goToPlan: () => void;
  style?: StyleProp<ViewStyle>;
};

export default observer(function EmptyWorkerListMobile({
  goToAddDemoMiner,
  goToAddMiner,
  goToPlan,
  style,
}: EmptyWorkerListMobileProps) {
  const {apiStore} = useRoot();
  const theme = useTheme();
  const strings = useStrings();
  const isReal = apiStore.mode === ApiMode.Real;
  const Icon = theme.select(MinerIcon, MinerDarkIcon);
  return (
    <View style={style}>
      <HeaderBannerConditionSection />
      <ContentView>
        <Icon />
        <TitleText type="header" size="h3" weight="600">
          {strings['dashboard.emptyList.title']}
        </TitleText>
        <View style={styles.content}>
          <AppButton
            style={[styles.offset, styles.button]}
            Icon={QuadFlash}
            onPress={goToPlan}
            variant={ButtonVariant.Primary}>
            {strings['dashboard.emptyList.goToTariffsButton']}
          </AppButton>
          {isReal ? (
            <AppButton
              Icon={PlusCircle}
              style={styles.button}
              onPress={goToAddMiner}>
              {strings['dashboard.emptyList.addMinerButton']}
            </AppButton>
          ) : (
            <AppButton
              Icon={PlusCircle}
              style={styles.button}
              onPress={goToAddDemoMiner}>
              {strings['dashboard.emptyList.addDemoMinerButton']}
            </AppButton>
          )}
        </View>
      </ContentView>
    </View>
  );
});

const MinerIcon = sized(MinerSvg, 102);
const MinerDarkIcon = sized(MinerDarkSvg, 102);

const styles = StyleSheet.create({
  container: {
    paddingBottom: 15,
    flexGrow: 1,
  },
  offset: {
    marginBottom: 12,
  },
  button: {
    maxWidth: 330,
    width: '100%',
  },
  content: {
    alignItems: 'center',
    width: '100%',
    paddingTop: 32,
  },
});

const ContentView = variance(View)((theme) => ({
  root: {
    paddingHorizontal: 16,
    flex: 1,
    backgroundColor: theme.colors.backgroundGround,
    paddingTop: 30,
    paddingBottom: 45,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const TitleText = variance(Header)(() => ({
  root: {
    marginTop: 12,
    textAlign: 'center',
    fontSize: 18,
    lineHeight: 22,
  },
}));
