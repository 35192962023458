import React, {useCallback, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import HistoryItemList from './HistoryItemList';
import {
  AdaptiveModal,
  AdaptiveModalRef,
  ModalWidth,
} from '../components/templates';
import {ADAPTIVE_BREAKPOINT} from './constants';
import {PageRequestImpl} from '../PageRequest';
import {WithdrawalEntity} from '../ApiStore';

export type WithdrawHistoryModalProps = {
  onModalClosed: () => void;
  goToAffiliate: () => void;
  goBack: () => void;
  request: PageRequestImpl<WithdrawalEntity>;
};

export default observer(function WithdrawHistoryModal(
  props: WithdrawHistoryModalProps,
) {
  const {goToAffiliate, goBack, onModalClosed, request} = props;
  const modalRef = useRef<AdaptiveModalRef>(null);
  const handleGoToAffiliate = useCallback(async () => {
    await modalRef.current?.close();
    goBack();
    goToAffiliate();
  }, [goBack, goToAffiliate]);
  return (
    <AdaptiveModal
      testIdCloseIcon="modal_close_btn"
      ref={modalRef}
      adaptiveBreakpoint={ADAPTIVE_BREAKPOINT}
      modalWidth={ModalWidth.Large}
      onModalClosed={onModalClosed}
      enableContentAutoHeight>
      <HistoryItemList goToAffiliate={handleGoToAffiliate} request={request} />
    </AdaptiveModal>
  );
});
