import {ReadonlyDeep} from 'type-fest';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {useRoot} from '../../../Root/hooks';
import {useForcedContext} from '../../../context';
import {DashboardContext} from '../../context';
import GroupHeader from '../../../components/organisms/group/GroupHeader';
import useGetIsLarge from '../../hooks/useGetIsLarge';
import {expr} from 'mobx-utils';
import getTotalWorkersSpeed from '../../getters/getTotalWorkersSpeed';
import getTotalWorkersMined from '../../getters/getTotalWorkersMined';
import getTotalOnlineWorkers from '../../getters/getTotalOnlineWorkers';
import {View, StyleSheet} from 'react-native';
import {variance} from '../../../styling';
import {GroupSection} from './formSections';
import {SECTION_LIST_ITEM_HEADER_HEIGHT} from '../../constants';

export type SectionGroupHeaderProps = {
  groupSection: ReadonlyDeep<GroupSection>;
};

export default observer(function SectionGroupHeader(
  props: SectionGroupHeaderProps,
) {
  const {groupSection} = props;
  const {group, collapsed, isLast, isEmpty} = groupSection;
  const {workers} = group;
  const {workerStateRegistry, dashboardService} = useRoot();
  const {goToWorkerGroupAction} = useForcedContext(DashboardContext);
  const getIsLarge = useGetIsLarge();
  const isLarge = expr(() => getIsLarge());

  const totalSpeed = expr(() =>
    getTotalWorkersSpeed(workers, workerStateRegistry),
  );
  const {
    dashboardStore: {getWorkerBalanceById},
  } = useRoot();
  const totalMined = expr(() =>
    getTotalWorkersMined(workers, getWorkerBalanceById),
  );
  const onlineWorkerCount = expr(() =>
    getTotalOnlineWorkers(workers, workerStateRegistry),
  );
  const onMenuPress = useCallback(() => {
    goToWorkerGroupAction(group.id);
  }, [goToWorkerGroupAction, group]);
  const onCollapsePress = useCallback(() => {
    dashboardService.toggleCollapseGroup(group.id);
  }, [dashboardService, group]);
  const getSectionGroupHeight = useGetSectionGroupHeaderHeight();
  const height = expr(() => getSectionGroupHeight());
  return (
    <RootView
      style={{height}}
      large={isLarge}
      bottomRounded={(collapsed || isEmpty) && isLast && isLarge}>
      <GroupHeader
        style={styles.content}
        speedValue={totalSpeed}
        btcValue={totalMined}
        onlineWorkerCount={onlineWorkerCount}
        totalWorkerCount={group.workers.length}
        opened={!collapsed || isEmpty}
        onMenuPress={onMenuPress}
        onCollapsePress={onCollapsePress}
        mode={isLarge ? 'large' : 'simple'}
        groupName={group.name}
        visibleOpenButton={!isEmpty}
      />
    </RootView>
  );
});

export function useGetSectionGroupHeaderHeight() {
  return () => SECTION_LIST_ITEM_HEADER_HEIGHT;
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
});

const RootView = variance(View)((theme) => ({
  root: {
    overflow: 'hidden',
  },
  large: {
    borderLeftWidth: 1,
    borderLeftColor: theme.colors.uiAdditional1,
    borderRightWidth: 1,
    borderRightColor: theme.colors.uiAdditional1,
  },
  bottomRounded: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.uiAdditional1,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
  },
}));
