import {FarmLogEntity} from '../Notifications';
import {observer} from 'mobx-react-lite';
import {useIntlFormatter, useRoot} from '../Root/hooks';
import React, {useMemo} from 'react';
import {variance} from '../styling';
import {Typography} from '../components';
import {StyleSheet, View} from 'react-native';
import NotificationLevelCircle from './NotificationLevelCircle';

export type NotificationViewProps = {
  item: FarmLogEntity;
};

export default observer(function NotificationListItem({
  item,
}: NotificationViewProps) {
  const {
    appTemplateState: {isTablet},
  } = useRoot();

  const {dateFormatter} = useIntlFormatter();
  const dateString = useMemo(
    () =>
      dateFormatter(item.timestamp, {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }),
    [item.timestamp, dateFormatter],
  );
  return (
    <View style={styles.root}>
      <View style={styles.iconView}>
        <NotificationLevelCircle message={item.message_id} level={item.level} />
      </View>
      <ContentView large={isTablet}>
        <MainView large={isTablet}>
          <NotificationText type="body" weight="500">
            <Typography type="header" size="h6">
              {item.title}.
            </Typography>{' '}
            <Typography type="body">{item.body}</Typography>
          </NotificationText>
        </MainView>
        <DateTimeView large={isTablet}>
          <DateTimeText type="body" weight="500" large={isTablet}>
            {dateString}
          </DateTimeText>
        </DateTimeView>
      </ContentView>
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    paddingTop: 15,
    paddingBottom: 10,
  },
  iconView: {
    width: 65,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const ContentView = variance(View)(() => ({
  root: {
    flex: 1,
  },
  large: {
    paddingLeft: 14,
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const DateTimeText = variance(Typography)((theme) => ({
  root: {
    color: theme.colors.primaryUIDirtyBlue,
    lineHeight: 12,
    fontSize: 10,
  },
  large: {
    flex: 2,
    paddingHorizontal: 15,
    textAlign: 'center',
  },
}));

const NotificationText = variance(Typography)((theme) => ({
  root: {
    fontSize: 12,
    lineHeight: 17,
    color: theme.colors.uiSecondary,
    marginBottom: 3,
  },
}));

const MainView = variance(View)(() => ({
  root: {
    paddingRight: 15,
  },
  large: {
    paddingRight: 0,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const DateTimeView = variance(View)(() => ({
  root: {},
  large: {
    width: 180,
  },
}));
