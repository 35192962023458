import React from 'react';
import {observer} from 'mobx-react-lite';
import {View, Text} from 'react-native';
import {ReadonlyDeep} from 'type-fest';
import {expr} from 'mobx-utils';
import {SvgProps} from 'react-native-svg';
import SpeedometerSvg from '../../../assets/svg/twoTone/speedometer.svg';
import {WorkerEntity} from '../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import getMiningSpeed from '../../../DashboardScreen/getters/getMiningSpeed';
import {variance} from '../../../styling';
import {useWorkerState} from '../../../WorkerStateRegistry';
import {TwoToneIcon} from '../../atoms';
import {sized} from '../../../Svg';
import {Typography} from '../../index';
import getAverageTemperature from '../../../DashboardScreen/getters/getAverageTemperature';
import { useIntlFormatter } from '../../../Root/hooks';

export type WorkerSpeedTemperatureSimpleProps = {
  worker: ReadonlyDeep<WorkerEntity>;
};

export default observer(function WorkerSpeedTemperatureSimple({
  worker,
}: WorkerSpeedTemperatureSimpleProps) {
  const state = useWorkerState(worker.id);
  const hashrate = expr(() => getMiningSpeed(worker, state).toFixed(0));
  const temperature = expr(() =>
    getAverageTemperature(state?.cpu_states).toFixed(0),
  );
  const hot = expr(() => state?.cpu_states.some((_) => _.hot));
  const {intFormatter } = useIntlFormatter();
  return (
    <RootView>
      <LeftView>
        <IconView>
          <SpeedometerIcon />
        </IconView>
        <ContentView>
          <Text>
            <MainText
              numberOfLines={1}
              adjustsFontSizeToFit
              type="body"
              weight="500">
              {intFormatter(hashrate)}
            </MainText>
            <MainHelperText type="body" weight="500">
              {' H/s'}
            </MainHelperText>
          </Text>
        </ContentView>
      </LeftView>
      <RightView>
        <MainText
          hot={hot}
          numberOfLines={1}
          adjustsFontSizeToFit
          type="body"
          weight="500">
          {temperature}°
        </MainText>
      </RightView>
    </RootView>
  );
});

const SpeedometerIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(SpeedometerSvg, 17, 12)} {...props} />
));

const RootView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    maxWidth: '100%',
  },
}));

const LeftView = variance(View)(() => ({
  root: {
    flex: 1,
    paddingVertical: 3,
    flexDirection: 'row',
  },
}));

const RightView = variance(View)(() => ({
  root: {},
}));

const IconView = variance(View)(() => ({
  root: {
    paddingHorizontal: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ContentView = variance(View)(() => ({
  root: {},
}));

const MainText = variance(Typography)((theme) => ({
  root: {
    fontSize: 18,
    lineHeight: 22,
    letterSpacing: -0.2,
    color: theme.colors.uiSecondary,
    marginRight: 3,
  },
  disabled: {
    color: theme.colors.uiAdditional3,
  },
  hot: {
    color: theme.colors.primaryError,
  },
}));

const MainHelperText = variance(Typography)((theme) => ({
  root: {
    fontSize: 12,
    lineHeight: 15,
    letterSpacing: -0.2,
    color: theme.colors.primaryUIDirtyBlue,
  },
  disabled: {
    color: theme.colors.uiAdditional3,
  },
}));
