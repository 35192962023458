import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {View} from 'react-native';
import CalendarModalDays from './CalendarModalDays';
import {useCalendarInfo} from './CalendarModalSettings';
import CalendarModalMonthSwitcher from './CalendarModalMonthSwitcher';
import CalendarModalMonthView from './CalendarModalMonthView';
import {useCalendarState} from './CalendarModalReducer';
import {
  NEXT_MONTH,
  PREV_MONTH,
  SET_NEW_DAY,
} from './CalendarModalActionsConstant';
import {variance} from '../styling';
import {Millisecond} from '../Time';
import {Header} from '../components';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {LG_BREAKPOINT} from '../WindowDimensions/useDimensions';
import useSafePaddingBottom from '../components/templates/AdaptiveModal/useSafePaddingBottom';
import {useStrings} from '../Root/hooks';

export type CalendarModalViewProps = {
  onSubmit: (from: Millisecond) => void;
  close: () => void;
  value?: Date;
};

export default observer(function CalendarModalView({
  onSubmit,
  close,
  value,
}: CalendarModalViewProps) {
  const [state, dispatch] = useCalendarState(value);
  const calendarBaseInfo = useCalendarInfo();
  const strings = useStrings();
  const onNextMonth = () => dispatch({type: NEXT_MONTH});
  const onPrevMonth = () => dispatch({type: PREV_MONTH});
  const setNewDay = (day: number, month: number) =>
    dispatch({type: SET_NEW_DAY, payload: {day: day, month: month}});

  const handlePress = useCallback(() => {
    onSubmit(
      new Date(
        state.year,
        state.month,
        state.date.getDate(),
      ).getTime() as Millisecond,
    );
    close();
  }, [onSubmit, close, state]);
  const paddingBottom = useSafePaddingBottom(16);
  return (
    <Root style={{paddingBottom}}>
      <Title type="header" size="h4">
        {strings['modal.calendar.title']}
      </Title>
      <Calendar>
        <CalendarModalMonthSwitcher
          date={`${calendarBaseInfo.month[state.month]} ${state.year}`}
          onNextMonth={onNextMonth}
          onPrevMonth={onPrevMonth}
        />
        <CalendarModalDays />
        <CalendarModalMonthView
          setNewDay={setNewDay}
          state={state}
          onBack={handlePress}
        />
      </Calendar>
      <SubmitButton variant={ButtonVariant.Primary} onPress={handlePress} testID="modal_stat_apply_btn">
        {strings['action.apply']}
      </SubmitButton>
    </Root>
  );
});

const Calendar = variance(View)((theme) => ({
  root: {
    borderRadius: 8,
    width: '100%',
    backgroundColor: theme.colors.uiMain,
    borderWidth: 1,
    borderColor: theme.colors.uiAdditional1,
    paddingHorizontal: 10,
    paddingBottom: 10,
    paddingTop: 15,
  },
}));

const Root = variance(View)((theme) => ({
  root: {
    paddingTop: 30,
    paddingHorizontal: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 20,
        paddingTop: 15,
        maxWidth: 372,
      },
    }),
  },
}));

const Title = variance(Header)(() => ({
  root: {
    marginBottom: 12,
    alignSelf: 'flex-start',
  },
}));

const SubmitButton = variance(AppButton)(() => ({
  root: {
    marginTop: 28,
  },
}));
