import React, {useCallback, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useRoot, useStrings} from '../Root/hooks';
import {DimensionValue, Image, ImageStyle, Platform, View} from 'react-native';
import {ResponseType, useAuthRequest} from 'expo-auth-session';
import {maybeCompleteAuthSession} from 'expo-web-browser';
import {when} from 'mobx';
import emptyFarmPresent from '../Auth/emptyFarmPresent';
import {AuthenticationErrorReason} from '../Auth';
import {useStyles} from '../styling';
import {sized} from '../Svg';
import CoinSvg from '../assets/svg/colored/coin.svg';
import {FarmId} from '../ApiStore';
import {DeviceIdentificationStateKind} from '../DeviceIdentification';
import {ScrollView} from 'react-native-gesture-handler';
import {Typography} from '../components';
import {AppButton} from '../components/AppButton';
import {
  MD_BREAKPOINT,
  useGetDimensions,
} from '../WindowDimensions/useDimensions';
import {FanPropellerIcon} from '../components/AnimatableFan';
import AppFooter from '../components/AppFooter';
import {expr} from 'mobx-utils';
import {SafeAreaView} from 'react-native-safe-area-context';
import Markdown from '../components/molecules/Markdown';

maybeCompleteAuthSession();

export type SignUpScreenProps = {
  goToOAuthSplash: () => void;
  onSuccess: () => void;
  goToThisScreen: () => void;
  getFarmId: () => FarmId | undefined;
  onRegisterNewFarmPress: () => void;
  variant?:
    | 'default'
    /**
     * @deprecated
     */
    | 'affiliate'
    | 'new-affiliate'
    | 'promo';
};
export default observer((props: SignUpScreenProps) => {
  const {
    goToOAuthSplash,
    onSuccess,
    goToThisScreen,
    getFarmId,
    onRegisterNewFarmPress,
    variant = 'default',
  } = props;

  const getDimension = useGetDimensions();
  const isMd = expr(() => getDimension('md'));
  const styles = useStyles((theme) => ({
    panel: {
      marginTop: 'auto',
      marginHorizontal: 16,
      borderRadius: 4,
      paddingBottom: 24,
      marginBottom: 'auto',
      ...theme.mediaQuery({
        [MD_BREAKPOINT]: {
          marginBottom: undefined,
        },
      }),
    },
    container: {
      justifyContent: 'center',
      flexGrow: 1,
    },
    card: {
      minHeight: (Platform.OS === 'web' ? '80vh' : '100%') as DimensionValue,
      ...theme.mediaQuery({
        [MD_BREAKPOINT]: {
          flexGrow: undefined,
          width: 600,
          maxWidth: '100%',
          margin: 'auto',
          padding: 6,
          borderRadius: 24,
          borderWidth: 1,
          borderColor: theme.colors.uiAdditional1,
          backgroundColor: theme.colors.uiMain,
          minHeight: 'auto',
          marginBottom: 24,
        },
      }),
    },
    newUser: {
      alignSelf: 'center',
      marginTop: 40,
    },
    title: {
      marginTop: 20,
      color: theme.colors.uiSecondary,
      textAlign: 'center',
      fontSize: 23,
      lineHeight: 28,
    },
    description: {
      marginTop: 20,
      marginBottom: 0,
      color: theme.colors.uiSecondary,
      textAlign: 'center',
    },
    buttonView: {
      marginTop: 35,
      width: 328,
      maxWidth: '100%',
      marginHorizontal: 'auto',
      borderWidth: 1,
      borderColor: theme.colors.uiAdditional2,
      backgroundColor: 'transparent',
    },
    error: {
      color: theme.colors.primaryError,
    },
    footer: {
      paddingVertical: 24,
      paddingHorizontal: 16,
      ...theme.mediaQuery({
        [MD_BREAKPOINT]: {
          marginHorizontal: 'auto',
        },
      }),
    },
    footerText: {
      fontSize: 12,
      lineHeight: 16,
      color: theme.colors.uiSecondary,
      marginTop: 0,
      marginBottom: 0,
    },
    link: {
      color: theme.colors.primaryUIDirtyBlue,
      textDecorationLine: 'none',
    },
    logo: {
      marginTop: 25,
      alignItems: 'center',
    },
    logoCircle: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      width: 24.4,
      height: 24.4,
      borderRadius: 100,
      backgroundColor: theme.colors.uiSecondary,
    },
    createNewFarm: {
      marginTop: 20,
      color: theme.colors.primaryNew,
      textAlign: 'center',
    },
    backgroundImage: {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    signUpBanner: {
      width: '100%',
      aspectRatio: 2,
      marginTop: 'auto',
      ...theme.mediaQuery({
        [MD_BREAKPOINT]: {
          aspectRatio: 3,
          width: '100%',
          marginLeft: '0%',
        },
      }),
    } as ImageStyle,
  }));
  const strings = useStrings();
  const {auth, log} = useRoot();
  const [_error, setError] = useState<unknown>();
  const error = _error || auth.error;
  const accountSwitchRequired =
    auth.state?.kind === 'AuthenticationFailed' &&
    auth.state.reason === AuthenticationErrorReason.AccountSwitchRequired;
  const showError = !accountSwitchRequired;
  const {deviceIdentification, configuration, specialLocation} = useRoot();
  const {
    oauthClientId,
    oauthAuthorizationEndpoint,
    oauthTokenEndpoint,
    oauthRevocationEndpoint,
  } = configuration.values;
  const redirectUri = specialLocation.getOauthRedirect();
  const params: Record<string, string> = {};
  if (Platform.OS === 'ios') {
    params.mode = 's';
  }
  if (
    deviceIdentification.state.kind ===
      DeviceIdentificationStateKind.Registered &&
    deviceIdentification.state.result.advertId !== undefined
  ) {
    params.ref = deviceIdentification.state.result.advertId;
  }
  const [, , promptAsync] = useAuthRequest(
    {
      responseType: ResponseType.Token,
      clientId: oauthClientId,
      redirectUri,
      scopes: ['read'],
      extraParams: params,
    },
    {
      authorizationEndpoint: oauthAuthorizationEndpoint,
      tokenEndpoint: oauthTokenEndpoint,
      revocationEndpoint: oauthRevocationEndpoint,
    },
  );
  const _startOAuth = useCallback(async () => {
    setError(undefined);
    try {
      // // Event Loop bug in Firefox
      setTimeout(async () => {
        log.write({body: '#SIGN_UP\nGO TO OAUTH SPLASH'});
        goToOAuthSplash();
        log.write({body: '#SIGN_UP\nPROMPT IS CALLED'});
        const response = await promptAsync();
        log.write({body: '#SIGN_UP\nPROMPT HAS BEEN CALLED'});
        log.write({
          body: `#SIGN_UP\nPROMPT RESPONSE\n${JSON.stringify(
            response,
            null,
            1,
          )}`,
        });
        if (response?.type === 'success') {
          goToThisScreen();
          const {access_token} = response.params;
          await auth.completeOAuth(access_token, getFarmId());
          await when(() => auth.isConnected);
          onSuccess();
        } else if (response.type === 'dismiss') {
          goToThisScreen();
        }
      }, 0);
    } catch (raw) {
      setError(raw);
    }
  }, [
    log,
    auth,
    getFarmId,
    goToOAuthSplash,
    goToThisScreen,
    onSuccess,
    promptAsync,
  ]);
  const hasEmptyFarm = auth.subscriptionMap
    ? emptyFarmPresent(auth.subscriptionMap)
    : true;
  let description = strings['signUp.description'];
  switch (variant) {
    case 'promo':
      description = strings['signUp.promoDescription'];
      break;
    case 'affiliate':
      description = strings['signUp.affiliateDescription'];
      break;
    case 'new-affiliate':
      description = strings['signUp.newAffiliateDescription'];
      break;
    case 'default':
      description = strings['signUp.description'];
      break;
  }
  let title = strings['signUp.title'];
  switch (variant) {
    case 'new-affiliate':
      title = strings['signUp.newAffiliateTitle'];
      break;
  }
  return (
    <>
      {isMd && (
        <Image
          source={require('../assets/fan_bg.png')}
          resizeMode={'cover'}
          style={styles.backgroundImage}
        />
      )}
      <ScrollView contentContainerStyle={styles.container}>
        <SafeAreaView edges={['bottom']} style={styles.card}>
          <View style={styles.panel}>
            <>
              {isMd && (
                <View style={styles.logo}>
                  <FanPropellerIcon />
                </View>
              )}
              <Typography type={'header'} size={'h2'} style={styles.title}>
                {title}
              </Typography>
              <Markdown styles={{paragraph: styles.description}}>
                {description}
              </Markdown>
              <AppButton
                rootStyle={styles.buttonView}
                onPress={_startOAuth}
                Icon={CoinIcon}
              >
                {strings['signUp.button']}
              </AppButton>
              {!hasEmptyFarm && (
                <Typography
                  type={'paragraph'}
                  onPress={onRegisterNewFarmPress}
                  style={styles.createNewFarm}
                >
                  {strings['signUp.newRegister']}
                </Typography>
              )}
              {showError && error && (
                <Typography
                  type={'paragraph'}
                  weight={'500'}
                  style={[styles.description, styles.error]}
                >
                  {error instanceof Error
                    ? error.message
                    : JSON.stringify(error)}
                </Typography>
              )}
            </>
          </View>
          <Image
            resizeMode="contain"
            style={styles.signUpBanner}
            source={require('../assets/signUpBanner.png')}
          />
          <View style={styles.footer}>
            <Markdown
              styles={{paragraph: styles.footerText, link: styles.link}}
            >
              {strings['signUp.disclaimer']}
            </Markdown>
          </View>
        </SafeAreaView>
        {Platform.OS === 'web' && <AppFooter />}
      </ScrollView>
    </>
  );
});

const CoinIcon = sized(CoinSvg, 18);
