import React from 'react';
import {observer} from 'mobx-react-lite';
import HeaderMenuUserInfo from '../../HeaderMenuUserInfo';
import {useTheme, variance} from '../../../styling';
import {TouchableOpacity, View} from 'react-native';
import {AppRouteName} from '../../../Navigation/AppRouteName';
import ProfileSvg from '../../../assets/svg/colorless/profile.svg';
import {sized} from '../../../Svg';

export type UserProfileProps = {
  activeRouteName: AppRouteName | undefined;
  goToLogin: () => void;
  goToProfile: () => void;
  goToVerify: () => void;
};

export default observer(function UserProfile({
  activeRouteName,
  goToLogin,
  goToProfile,
  goToVerify,
}: UserProfileProps) {
  const theme = useTheme();

  if (activeRouteName === 'Menu') {
    return (
      <RootView>
        <HeaderMenuUserInfo
          onVerifyPress={goToVerify}
          onLoginPress={goToLogin}
        />
      </RootView>
    );
  }
  return (
    <ProfileButton onPress={goToProfile} activeOpacity={0.9} testID="hd_profile">
      <ProfileIcon color={theme.colors.uiSecondary} />
    </ProfileButton>
  );
});

const ProfileIcon = sized(ProfileSvg, 18, 24);

const RootView = variance(View)((theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.colors.uiMain,
    borderRadius: 12,
    height: 46,
    paddingVertical: 4,
    paddingHorizontal: 12,
    marginLeft: 18,
  },
}));

const ProfileButton = variance(TouchableOpacity)((theme) => ({
  root: {
    width: 46,
    height: 46,
    backgroundColor: theme.colors.uiMain,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 18,
  },
}));
