import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {RootStackBindingProps} from './RootStackBindingProps';
import {useRoot} from '../../Root/hooks';
import {BonusProgramScreen} from '../../BonusProgramScreen';
import {useHSHBalance} from '../../HSHAffiliate';

export type BonusProgramBindingProps = RootStackBindingProps<'BonusProgram'>;

export const BonusProgramBinding = observer(({}: BonusProgramBindingProps) => {
  const {
    hshAffiliate,
    location,
    configuration: {values},
  } = useRoot();

  const goToAffiliate = useCallback(() => {
    location.open(values.affiliateWithdrawalUrl);
  }, [values.affiliateWithdrawalUrl, location]);
  const {shareLink} = useHSHBalance();

  const onShare = useCallback(async () => {
    shareLink();
  }, [shareLink]);
  return (
    <BonusProgramScreen
      balance={hshAffiliate.balance}
      shareLink={onShare}
      goToAffiliate={goToAffiliate}
      isRefreshing={hshAffiliate.isRefreshing}
      refresh={hshAffiliate.refresh}
    />
  );
});
