import React, {
  useImperativeHandle,
  useRef,
  useCallback,
  useState,
  forwardRef,
  useEffect,
} from 'react';
import {observer} from 'mobx-react-lite';
import {
  LiteralBreakpoint,
  translateBreakpointToPixels,
  useGetIsDimensions,
} from '../../../WindowDimensions/useDimensions';
import {expr} from 'mobx-utils';
import Modal, {PublicModalProps} from './Modal';
import {createNullableContext} from '../../../context';
import {observable, runInAction, when} from 'mobx';
import BottomSheet, {PublicBottomSheetProps} from './BottomSheet';
import {ModalRef, Representation} from './types';
import delayResolve from '../../../utils/delayResolve';
import {BackHandler, Platform} from 'react-native';

export type AdaptiveModalRef = {
  close: () => Promise<void>;
};

export const ADAPTIVE_BREAKPOINT: LiteralBreakpoint = 'lg';
export const ADAPTIVE_BREAKPOINT_PX =
  translateBreakpointToPixels(ADAPTIVE_BREAKPOINT);

type BaseAdaptiveModalProps = {
  children: React.ReactNode;
  representation?: Representation;
  /**
   * Toggles representation state
   * BottomSheet to this value (default = lg) and after modal representation
   * Only works with representation = .Adaptive (default)
   */
  adaptiveBreakpoint?: LiteralBreakpoint;
  /**
   * Called when the modal window is closed in any of the ways - via ref or via interaction with the background / gestures
   */
  onModalClose?: () => void;
  /**
   * !!! Will not be called if the modal is closed with ref
   */
  onModalClosed?: () => void;
  testIdCloseIcon?: string;
};

export type AdaptiveModalProps = BaseAdaptiveModalProps &
  PublicModalProps &
  PublicBottomSheetProps;

export default observer<AdaptiveModalProps, AdaptiveModalRef>(
  forwardRef(function AdaptiveModal(props, ref) {
    const {
      children,
      representation = Representation.Auto,
      adaptiveBreakpoint = ADAPTIVE_BREAKPOINT,
      onModalClosed,
      closeIconHidden,
      testIdCloseIcon,
    } = props;

    // Helper to avoid calling onModalClosed when closing a modal with a ref
    const visibleGhostRef = useRef(false);

    const [visibleBox] = useState(() => observable.box(true));
    const onClosed = useCallback(() => {
      runInAction(() => visibleBox.set(false));
      if (!visibleGhostRef.current) {
        onModalClosed?.();
      }
    }, [onModalClosed, visibleBox]);
    const onShown = useCallback(() => {
      runInAction(() => visibleBox.set(true));
      visibleGhostRef.current = false;
    }, [visibleBox]);

    const modalRef = useRef<ModalRef>(null);
    const bottomSheetRef = useRef<ModalRef>(null);

    const close = useCallback(async () => {
      if (!visibleBox.get()) {
        return;
      }
      modalRef.current?.close();
      bottomSheetRef.current?.close();
      // If something went wrong
      await Promise.race([
        when(() => !visibleBox.get()),
        delayResolve(1000, () => null),
      ]);
    }, [visibleBox]);

    useEffect(() => {
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        () => {
          close();
          return true;
        },
      );

      return () => backHandler.remove();
    }, [close]);

    const externalRefClosed = useCallback(async () => {
      visibleGhostRef.current = true;
      await close();
    }, [close]);

    useImperativeHandle(ref, () => ({
      close: externalRefClosed,
    }));

    const renderModal = useCallback(
      () => (
        <Modal
          {...props}
          close={close}
          onClosed={onClosed}
          testIdCloseIcon={testIdCloseIcon}
          onShown={onShown}
          ref={modalRef}
          closeIconHidden={closeIconHidden}>
          {children}
        </Modal>
      ),
      [props, close, onClosed, onShown, closeIconHidden, children, testIdCloseIcon],
    );
    const renderBottomSheet = useCallback(
      () => (
        <BottomSheet
          {...props}
          enablePanDownToClose={Platform.OS !== 'web'}
          ref={bottomSheetRef}
          close={close}
          onClosed={onClosed}
          onShown={onShown}
        />
      ),
      [close, onClosed, onShown, props],
    );
    const getIsLarge = useGetIsDimensions(adaptiveBreakpoint);
    const computedRepresentation = expr(() => {
      if (representation === Representation.Auto) {
        return getIsLarge() ? Representation.Modal : Representation.BottomSheet;
      }
      return representation;
    });
    const render = useCallback(() => {
      if (computedRepresentation === Representation.Modal) {
        return renderModal();
      }
      return renderBottomSheet();
    }, [computedRepresentation, renderBottomSheet, renderModal]);

    return visibleBox.get() ? (
      <AdaptiveModalContext.Provider
        value={{representation: computedRepresentation}}>
        {render()}
      </AdaptiveModalContext.Provider>
    ) : null;
  }),
);

type ModalContextProps = {
  representation: Representation.Modal | Representation.BottomSheet;
};
export const AdaptiveModalContext = createNullableContext<ModalContextProps>();
