import React from 'react';
import {observer} from 'mobx-react-lite';
import {useTheme, variance} from '../../../styling';
import {Typography} from '../../index';
import {View} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {LinearGradient} from 'expo-linear-gradient';
import {BottomTabBarProps} from '@react-navigation/bottom-tabs/src/types';
import {BottomTabRouteName} from '../../../Navigation/AppRouteName';
import getTabIcon from './getTabIcon';
import MobileTabButton from './MobileTabButton';
import {useVisibleTabletTemplate} from '../../../AppTemplateState';

export type MobileTabBar = BottomTabBarProps & {
  goToAddWorker: () => void;
};

export default observer(function MobileTabBar({
  state,
  descriptors,
  navigation,
  goToAddWorker,
}: MobileTabBar) {
  const theme = useTheme();
  const isLarge = useVisibleTabletTemplate();
  const insets = useSafeAreaInsets();
  if (isLarge) {
    return null;
  }
  return (
    <RootView style={{paddingBottom: insets.bottom}}>
      <ContentView>
        <GradientView>
          <LinearGradient
            colors={[
              theme.chroma(theme.colors.uiMain).alpha(0).hex(),
              theme.colors.uiMain,
            ]}
            locations={[0, 0.5]}
            style={{flex: 1}}
          />
        </GradientView>
        {state.routes.map((route, index) => {
          const routeName = route.name as BottomTabRouteName;
          const {options} = descriptors[route.key];
          const isFocused = state.index === index;
          const testId = routeName.toLowerCase().replaceAll('/', '_').replaceAll(' ', '_');
          options.tabBarTestID = `menu_${testId}`;

          const onPress = () => {
            if (routeName === 'MockAddWorker') {
              goToAddWorker();
            } else {
              const event = navigation.emit({
                type: 'tabPress',
                target: route.key,
                canPreventDefault: true,
              });
              if (!isFocused && !event.defaultPrevented) {
                navigation.navigate(routeName, {merge: true});
              }
            }
          };

          const {tabBarBadge} = options;
          const Icon = getTabIcon(routeName);
          const size = routeName === 'MockAddWorker' ? 45 : 25;
          return (
            <MobileTabButton
              key={index}
              onPress={onPress}
              options={options}
              isFocused={isFocused}
              routeName={routeName}>
              {Icon && <Icon active={isFocused} width={size} height={size} />}
              {tabBarBadge ? (
                <NotificationBadgeView>
                  <NotificationBadgeText type="body" weight="500">
                    {tabBarBadge}
                  </NotificationBadgeText>
                </NotificationBadgeView>
              ) : null}
            </MobileTabButton>
          );
        })}
      </ContentView>
    </RootView>
  );
});

const RootView = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.colors.uiMain,
  },
}));

const ContentView = variance(View)(() => ({
  root: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingHorizontal: 15,
    height: 55,
  },
}));

const GradientView = variance(View)(() => ({
  root: {
    position: 'absolute',
    top: -10,
    left: 0,
    right: 0,
    height: 15,
    overflow: 'hidden',
    pointerEvents: 'none',
  },
}));

const NotificationBadgeView = variance(View)((theme) => ({
  root: {
    position: 'absolute',
    top: 5,
    right: 'auto',
    left: 'auto',
    transform: [{translateX: 10}],
    minWidth: 18,
    paddingHorizontal: 5,
    height: 18,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 9,
    backgroundColor: theme.colors.primaryAttractive,
  },
}));

const NotificationBadgeText = variance(Typography)((theme) => ({
  root: {
    textAlign: 'center',
    color: theme.colors.primaryBlack,
    fontSize: 12,
    lineHeight: 14,
  },
}));
