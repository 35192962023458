import React, {useRef} from 'react';
import {FlatList, StyleSheet, View} from 'react-native';
import {useRoot} from '../Root/hooks';
import {observer} from 'mobx-react-lite';
import {FarmLogEntity} from '../Notifications';
import NotificationEmptyList from './NotificationEmptyList';
import {MoreDataActivityIndicator} from '../components/loaders';
import useScrollHandlers from './useScrollHandlers';
import {ScreenFluidLoader} from '../components/atoms/CryptoLoader';
import {variance} from '../styling';
import ToTopButton from './ToTopButton';
import {keyExtractor, ListProps} from './types';
import LgNotificationListHeader from './LgNotificationListHeader';
import NotificationListItem from './NotificationListItem';
import {Divider} from '../components/atoms';
import RefreshControl from '../components/RefreshControl';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

export type NotificationListScreenProps = {
  goToAddDemoMiner: () => void;
  goToPlan: () => void;
  goToScanQr: () => void;
  goToDownloadMiner: () => void;
};

export default observer(function NotificationListScreen({
  goToAddDemoMiner,
  goToPlan,
  goToScanQr,
  goToDownloadMiner,
}: NotificationListScreenProps) {
  const {
    notificationService: {list},
    appTemplateState: {isTablet},
  } = useRoot();
  const scrollRef = useRef<FlatList<FarmLogEntity>>(null);
  const {onScroll, scrollY} = useScrollHandlers(scrollRef);
  const insets = useSafeAreaInsets();
  const toTop = () => {
    scrollRef.current?.scrollToOffset({offset: 0, animated: true});
  };
  if (list.isLoading) {
    return <ScreenFluidLoader />;
  }
  if (list.items?.length === 0) {
    return (
      <BannerRootView
        large={isTablet}
        style={{paddingBottom: insets.bottom + (isTablet ? 26 : 0)}}>
        <NotificationEmptyList
          goToAddDemoMiner={goToAddDemoMiner}
          goToPlan={goToPlan}
          goToScanQr={goToScanQr}
          goToDownloadMiner={goToDownloadMiner}
        />
      </BannerRootView>
    );
  }

  return (
    <RootView
      large={isTablet}
      style={{paddingBottom: insets.bottom + (isTablet ? 26 : 0)}}>
      <ContentView large={isTablet}>
        <FlatList
          ref={scrollRef}
          refreshControl={
            <RefreshControl
              refreshing={list.isRefreshing}
              onRefresh={list.refresh}
            />
          }
          ListHeaderComponent={LgNotificationListHeader}
          stickyHeaderIndices={[0]}
          ListFooterComponent={
            list.isMoreLoading ? MoreDataActivityIndicator : null
          }
          ItemSeparatorComponent={isTablet ? Divider : null}
          data={list.items}
          contentContainerStyle={[
            styles.container,
            isTablet && styles.largeContainer,
          ]}
          onEndReached={list.fetchNext}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          onScroll={onScroll}
          initialNumToRender={15}
        />
      </ContentView>
      {scrollY > 300 && <ToTopButton onPress={toTop} />}
    </RootView>
  );
});

const renderItem: ListProps['renderItem'] = ({item}) => (
  <NotificationListItem item={item} />
);

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  largeContainer: {
    paddingHorizontal: 0,
  },
});

const RootView = variance(View)(() => ({
  root: {
    flex: 1,
  },
  large: {
    paddingHorizontal: 18,
  },
}));

const BannerRootView = variance(View)(() => ({
  root: {
    flex: 1,
  },
  large: {
    paddingHorizontal: 20,
    paddingTop: 10,
  },
}));

const ContentView = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.colors.uiMain,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    overflow: 'hidden',
    flex: 1,
  },
  large: {
    paddingTop: 12,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: theme.colors.uiAdditional1,
  },
  empty: {
    backgroundColor: theme.colors.backgroundGround,
  },
}));
