import React from 'react';
import {observer} from 'mobx-react-lite';
import {useTheme, variance} from '../../styling';
import {StyleProp, View, ViewStyle} from 'react-native';
import CircleFlashSvg from '../../assets/svg/colorless/circledFlash.svg';
import FlashSvg from '../../assets/svg/twoTone/flash.svg';
import {Divider, Header} from '../../components';
import {AppButton, ButtonVariant} from '../../components/AppButton';
import {sized} from '../../Svg';
import {useRoot, useStrings} from '../../Root/hooks';
import {expr} from 'mobx-utils';
import isNeedToReactivate from '../../PoolMinerActivator/isNeedToReactivate';

export type ReactivateCardProps = {
  goToPoolActivator: () => void;
  style?: StyleProp<ViewStyle>;
};

export default observer(function ReactivateCard(props: ReactivateCardProps) {
  const {goToPoolActivator, style} = props;
  const strings = useStrings();
  const {
    dashboardStore: {workers},
  } = useRoot();
  const amount = expr(() =>
    [...workers.values()].reduce(
      (prev, curr) => prev + (isNeedToReactivate(curr) ? 1 : 0),
      0,
    ),
  );
  const theme = useTheme();
  return (
    <RootView style={style}>
      <Block flexible>
        <FlashIcon color={theme.colors.uiSecondary} />
        <Title type="header" size="h4" weight="400">
          {strings['reactivateCard.title']}
        </Title>
        <Title type="header" size="h4" weight="500" second>
          ({amount})
        </Title>
      </Block>
      <Divider />
      <Block second>
        <AppButton
          onPress={goToPoolActivator}
          Icon={CircleFlashSvg}
          variant={ButtonVariant.Primary}>
          {strings['reactivateCard.button']}
        </AppButton>
      </Block>
    </RootView>
  );
});

const FlashIcon = sized(FlashSvg, 24, 24);

const RootView = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.colors.backgroundGround,
    borderRadius: 12,
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.2,
    shadowRadius: 8,
    elevation: 12,
    shadowColor: '#ADADAD',
  },
}));

const Block = variance(View)(() => ({
  root: {
    paddingHorizontal: 12,
    paddingBottom: 15,
    paddingTop: 17,
  },
  second: {
    paddingTop: 15,
    paddingBottom: 19,
  },
  flexible: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const Title = variance(Header)((theme) => ({
  root: {
    marginLeft: 8,
  },
  second: {
    marginLeft: 5,
    color: theme.colors.uiAdditional4,
    marginRight: 'auto',
  },
}));
