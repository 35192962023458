import {observer} from 'mobx-react-lite';
import React from 'react';
import {RatesPanel, RatesPanelState} from '../RatesPanel';
import RefreshControl from '../components/RefreshControl';
import {ScrollView} from 'react-native';
import {useStyles} from '../styling';
import {MD_BREAKPOINT} from '../WindowDimensions/useDimensions';
import BottomStatisticBannerContainer from './BottomStatisticBannerContainer';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

export type StatisticsScreenProps = {
  onRefresh: () => void;
  btcInUsd: number | undefined;
  ratesState: RatesPanelState;
  goToPlan: () => void;
  goToAddDemoMiner: () => void;
};

const LARGE_BREAKPOINT = 'lg';

export default observer(
  ({
    onRefresh,
    btcInUsd,
    ratesState,
    goToPlan,
    goToAddDemoMiner,
  }: StatisticsScreenProps) => {
    const insets = useSafeAreaInsets();
    const styles = useStyles((theme) => ({
      root: {
        paddingBottom: 20 + insets.bottom,
        ...theme.mediaQuery({
          [MD_BREAKPOINT]: {
            margin: 20,
            paddingBottom: 40 + insets.bottom,
          },
        }),
      },
    }));

    // Use ScrollView from RN, see https://github.com/software-mansion/react-native-gesture-handler/issues/1067
    return (
      <ScrollView
        contentContainerStyle={[styles.root]}
        refreshControl={
          <RefreshControl
            refreshing={ratesState.isRefreshing}
            onRefresh={onRefresh}
          />
        }>
        <RatesPanel ratesState={ratesState} btcInUsd={btcInUsd} />
        <BottomStatisticBannerContainer
          goToPlan={goToPlan}
          goToAddDemoMiner={goToAddDemoMiner}
          largeBreakpoint={LARGE_BREAKPOINT}
        />
      </ScrollView>
    );
  },
);
