import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, View} from 'react-native';
import FarmInfoCard from '../../components/FarmInfoCard';
import {useForcedContext} from '../../context';
import {DashboardContext} from '../context';
import BannerList from '../../components/templates/BannerList';
import {PromoBanner} from '../../components/organisms/banner/PromoBanner';
import {AdvertBanner} from './AdvertBanner';
import {XL_BREAKPOINT} from '../../WindowDimensions/useDimensions';
import {expr} from 'mobx-utils';
import {useRoot} from '../../Root/hooks';
import {BannerType} from '../../Dashboard';
import ReactivateCard from './ReactivateCard';
import AffiliateBalanceBanner from './AffiliateBalanceBanner';
import {AccountType} from '../../Auth';

const CONTENT_HEIGHT = 140;

export default observer(function HeaderFarmInfoLargeSection() {
  const {
    safelyGoToPromoAffiliate,
    getAffiliateIsPending,
    getAdvertDesktopBanner,
    goToPoolActivator,
    goToBonusProgram,
    goToAffiliate,
    shareBalance,
  } = useForcedContext(DashboardContext);
  const {
    windowDimensionsState: {window},
    dashboardService: {bannersState},
    hshAffiliate,
    auth,
  } = useRoot();
  const isTemporary = expr(() => auth.accountType === AccountType.Temporary);
  const visibleThirdBanner = expr(() => window.width > XL_BREAKPOINT);
  const height = useHeaderBannerConditionLargeSectionHeight();
  return (
    <View style={{height}}>
      <BannerList style={styles.list}>
        <FarmInfoCard style={styles.banner} />
        <AffiliateBalanceBanner
          balance={hshAffiliate.balance}
          shareLink={shareBalance}
          onLeaveToAffiliatePress={goToAffiliate}
          onDetailsPress={goToBonusProgram}
          isTemporary={isTemporary}
          style={[styles.affiliateBanner, styles.banner]}
        />
        {visibleThirdBanner &&
          (bannersState.targetBanner === BannerType.PoolMiner ? (
            <ReactivateCard
              goToPoolActivator={goToPoolActivator}
              style={styles.banner}
            />
          ) : getAdvertDesktopBanner() !== undefined ? (
            <AdvertBanner
              ad={getAdvertDesktopBanner()!}
              style={styles.banner}
            />
          ) : (
            <PromoBanner
              style={styles.banner}
              getAffiliateIsPending={getAffiliateIsPending}
              safelyGoToPromoAffiliate={safelyGoToPromoAffiliate}
            />
          ))}
      </BannerList>
    </View>
  );
});

export const useHeaderBannerConditionLargeSectionHeight = () => {
  return CONTENT_HEIGHT;
};

const styles = StyleSheet.create({
  list: {
    flex: 1,
  },
  banner: {
    flex: 1,
  },
  affiliateBanner: {
    height: undefined,
  },
});
