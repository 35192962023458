import React, {useCallback} from 'react';
import {Platform, View} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useIntlFormatter, useStrings} from '../../../Root/hooks';
import {createStylesHook, useTheme, variance} from '../../../styling';
import {Slider} from '@miblanchard/react-native-slider';
import {Body, PressableOpacity} from '../../index';
import {sized} from '../../../Svg';
import ChevronDownSvg from '../../../assets/svg/colorless/chevronDown.svg';
import {Divider} from '../../atoms';
import {expr} from 'mobx-utils';
import {
  LG_BREAKPOINT,
  useGetDimensions,
} from '../../../WindowDimensions/useDimensions';
import PurchaseGiftSection from '../../../PurchaseScreen/components/PurchaseGiftSection';
import {MinerConfig} from '../../../ProposalsState/MinerProposalsState';
import {Hashrate} from '../../../ApiStore';
import {Gift} from '../../../InAppOffersService';
import GiftDoubleSvg from '../../../assets/svg/colorless/gift-double.svg';

export type PickHashrateSectionProps = {
  goToConfiguration: () => void;
  theme?: 'light' | 'dark';
  gift?: Gift;
  isGiftLoading?: boolean;
  onChangeSlider: (hashrate: Hashrate) => void;
  minerConfigs: MinerConfig[];
  selectedConfig: MinerConfig | undefined;
};

export default observer(function PickHashrateSection({
  goToConfiguration,
  theme: componentTheme,
  gift,
  isGiftLoading,
  onChangeSlider,
  minerConfigs,
  selectedConfig,
}: PickHashrateSectionProps) {
  const selectedHashrate = selectedConfig?.hashrate;
  const isDarkTheme = componentTheme === 'dark';
  const getDimension = useGetDimensions();
  const isLg = expr(() => getDimension('lg'));
  const strings = useStrings();
  const styles = useStyles();
  const theme = useTheme();
  const {intFormatter} = useIntlFormatter();
  const handleChangeSlider = useCallback(
    (sliderValue: number[] | number) => {
      const index = Array.isArray(sliderValue) ? sliderValue[0] : sliderValue;
      const candidate = minerConfigs[index];
      if (candidate) {
        onChangeSlider(candidate.hashrate);
      }
    },
    [onChangeSlider, minerConfigs],
  );
  const index = expr(() =>
    minerConfigs.findIndex((_) => _.hashrate === selectedHashrate),
  );
  const maximumValue = expr(() =>
    minerConfigs && minerConfigs.length - 1 > 0
      ? minerConfigs.length - 1
      : undefined,
  );

  const giftText =
    Platform.OS === 'web'
      ? strings['purchaseRow.doubleGift.web']
      : strings['purchaseRow.doubleGift'];

  return (
    <>
      <Container>
        <HashrateView withDarkTheme={isDarkTheme}>
          <HashrateSpeed>
            <HashrateSpeedTitle
              withDarkTheme={isDarkTheme}
              weight="500"
              type="body">
              {strings['purchase.poolMiners.hashrate']}:
            </HashrateSpeedTitle>
            {selectedHashrate && (
              <HashrateSpeedValue
                withDarkTheme={isDarkTheme}
                weight="500"
                type="body">
                {intFormatter(selectedHashrate)}
                H/s
              </HashrateSpeedValue>
            )}
          </HashrateSpeed>
          <Divider
            vertical={isLg}
            style={isLg ? styles.largeDivider : undefined}
          />
          {maximumValue ? (
            <Slider
              maximumValue={maximumValue}
              minimumValue={0}
              step={1}
              value={index}
              containerStyle={styles.slider}
              onValueChange={(value) => handleChangeSlider(value)}
              maximumTrackTintColor={'#C7CDD9'}
              thumbTintColor={theme.colors.primaryWhite}
              minimumTrackTintColor={theme.colors.primaryAttractive}
              trackStyle={styles.track}
              thumbStyle={styles.thumb}
              thumbTouchSize={{
                width: 45,
                height: 40,
              }}
            />
          ) : null}
        </HashrateView>
        <ConfigurationView
          withDarkTheme={isDarkTheme}
          onPress={goToConfiguration}>
          {selectedConfig ? (
            <ConfigurationValueView>
              <SelectedPoolLabel type="body" weight="500">
                {strings['worker.selectConfiguration']}
              </SelectedPoolLabel>
              <SelectedPoolName
                withDarkTheme={isDarkTheme}
                type="body"
                weight="500"
                ellipsizeMode="tail"
                numberOfLines={1}>
                {selectedConfig.name} @{selectedConfig.frequency}
              </SelectedPoolName>
            </ConfigurationValueView>
          ) : null}
          <ChevronDownIcon color={theme.colors.primaryUIDirtyBlue} />
        </ConfigurationView>
      </Container>
      <GiftSectionView>
        <PurchaseGiftWrapperView>
          <PurchaseGiftSection isLoading={isGiftLoading ?? false} gift={gift} />
        </PurchaseGiftWrapperView>
        {isLg && (
          <DoubleContainer>
            <View>
              <GiftIcon color="white" />
            </View>
            <DoubleText type="body" weight="600">
              {giftText}
            </DoubleText>
          </DoubleContainer>
        )}
      </GiftSectionView>
    </>
  );
});

const GiftIcon = sized(GiftDoubleSvg, 24, 24);
const ChevronDownIcon = sized(ChevronDownSvg, 10, 6);

const useStyles = createStylesHook((theme) => ({
  track: {height: 6, borderRadius: 4},
  thumb: {
    height: 18,
    width: 18,
    borderRadius: 18,
    borderWidth: 2,
    borderColor: theme.colors.primaryAttractive,
  },
  largeDivider: {
    height: 50,
  },
  slider: {
    marginHorizontal: 16,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: 1,
      },
    }),
  },
}));

const Container = variance(View)((theme) => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        backgroundColor: theme
          .chroma(theme.colors.primaryWhite)
          .alpha(0.2)
          .hex(),
        flex: 1,
        marginHorizontal: -16,
        padding: 16,
        flexDirection: 'row-reverse',
        alignItems: 'stretch',
      },
    }),
  },
}));

const HashrateView = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.colors.primaryWhite,
    borderWidth: 1,
    borderColor: theme.colors.primaryWhite,
    borderRadius: 8,
    marginBottom: 12,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: 10,
        marginLeft: 18,
        marginBottom: 0,
        flexDirection: 'row',
        alignItems: 'center',
      },
    }),
  },
  withDarkTheme: {
    backgroundColor: theme.colors.uiMain,
    borderWidth: 1,
    borderColor: theme.colors.uiAdditional1,
  },
}));

const HashrateSpeed = variance(View)((theme) => ({
  root: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    }),
  },
}));

const HashrateSpeedTitle = variance(Body)((theme) => ({
  root: {
    color: theme.colors.primaryBlack,
    fontSize: 16,
    lineHeight: 20,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        fontSize: 12,
        lineHeight: 15,
        color: theme.colors.primaryUIDirtyBlue,
        textAlign: 'left',
      },
    }),
  },
  withDarkTheme: {
    color: theme.colors.uiSecondary,
  },
}));

const HashrateSpeedValue = variance(Body)((theme) => ({
  root: {
    color: theme.colors.primaryBlack,
    fontSize: 18,
    lineHeight: 22,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        fontSize: 16,
        lineHeight: 20,
      },
    }),
  },
  withDarkTheme: {
    color: theme.colors.uiSecondary,
  },
}));

const ConfigurationView = variance(PressableOpacity)((theme) => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 8,
    paddingHorizontal: 16,
    backgroundColor: theme.colors.primaryWhite,
    borderRadius: 8,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: 4,
        overflow: 'hidden',
        paddingVertical: 0,
      },
    }),
  },
  withDarkTheme: {
    backgroundColor: theme.colors.uiMain,
    borderWidth: 1,
    borderColor: theme.colors.uiAdditional1,
  },
}));

const ConfigurationValueView = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));

const SelectedPoolLabel = variance(Body)((theme) => ({
  root: {
    color: theme.colors.primaryUIDirtyBlue,
    fontSize: 12,
    lineHeight: 15,
  },
}));

const SelectedPoolName = variance(Body)((theme) => ({
  root: {
    fontSize: 16,
    lineHeight: 20,
    color: theme.colors.primaryBlack,
  },
  withDarkTheme: {
    color: theme.colors.uiSecondary,
  },
}));

const GiftSectionView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    marginTop: 12,
  },
}));

const PurchaseGiftWrapperView = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));

const DoubleContainer = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.colors.primaryError,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    marginLeft: 16,
    flex: 1,
    height: 50,
  },
}));

const DoubleText = variance(Body)((theme) => ({
  root: {
    fontSize: 14,
    lineHeight: 18,
    color: theme.colors.primaryWhite,
    marginLeft: 10,
  },
}));
