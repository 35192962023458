import React from 'react';
import {observer} from 'mobx-react-lite';
import {Pressable, StyleSheet, View, Image, Platform} from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';
import {Header} from '../../Typography';
import {useTheme, variance} from '../../../styling';
import CloseSvg from '../../../assets/svg/colorless/close.svg';
import PressableOpacity from '../../PressableOpacity';
import {sized} from '../../../Svg';
import {useStrings} from '../../../Root/hooks';

export const INTRO_BANNER_HEIGHT = 66;
const IconBanner = require('../../../assets/introIcon.png');

export type IntroVideoBannerProps = {
  goToIntroVideo: () => void;
  goToIntroVideoCloseConfirm: () => void;
};

export default observer(function IntroVideoBanner(
  props: IntroVideoBannerProps,
) {
  const {goToIntroVideo, goToIntroVideoCloseConfirm} = props;
  const theme = useTheme();
  const strings = useStrings();
  return (
    <Banner onPress={goToIntroVideo}>
      <LinearGradient
        colors={['#AC74D2', '#6A09E9']}
        start={{x: 0, y: 0.5}}
        style={styles.gradient}
        end={{x: 1, y: 0.5}}>
        <ContentWrapper>
          <Image
            style={styles.backgroundImage}
            source={IconBanner}
            resizeMode="contain"
          />

          <Content>
            <TitleText type="header" size="h5">
              {strings['introVideo.promo']}
            </TitleText>

            <CloseButton hitSlop={10} onPress={goToIntroVideoCloseConfirm}>
              <ActionCloseSvg color={theme.colors.uiMain} />
            </CloseButton>
          </Content>
        </ContentWrapper>
      </LinearGradient>
    </Banner>
  );
});

const styles = StyleSheet.create({
  backgroundImage: {
    position: 'absolute',
    bottom: 0,
    width: 98,
    height: INTRO_BANNER_HEIGHT,
  },
  gradient: {borderRadius: 8},
});
const Banner = variance(Pressable)(() => ({
  root: {
    marginHorizontal: 16,
    marginVertical: 20,
    backgroundColor: 'transparent',
    ...Platform.select({
      web: {
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
      },
      default: {
        shadowOffset: {
          width: 0,
          height: 4,
        },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 4,
        shadowColor: 'rgba(0, 0, 0, 0.10)',
      },
    }),
  },
}));

const ContentWrapper = variance(View)(() => ({
  root: {
    paddingHorizontal: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
const Content = variance(View)(() => ({
  root: {
    paddingVertical: 12,
    flexDirection: 'row',
    gap: 10,
    justifyContent: 'space-between',
    flex: 1,
    marginLeft: 108,
    minHeight: 53,
    alignItems: 'center',
  },
}));
const TitleText = variance(Header)((theme) => ({
  root: {
    fontSize: 14,
    lineHeight: 20,
    fontWeight: '700',
    color: theme.colors.uiMain,
    maxWidth: '100%',
  },
}));
const CloseButton = variance(PressableOpacity)(() => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    padding: 5,
    margin: -5,
  },
}));

const ActionCloseSvg = sized(CloseSvg, 10);
