import React from 'react';
import {observer} from 'mobx-react-lite';
import {View, Text, StyleSheet} from 'react-native';
import Typography from '../../Typography';
import {useIntlFormatter, useRoot, useStrings} from '../../../Root/hooks';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {useWorkerState} from '../../../WorkerStateRegistry';
import {expr} from 'mobx-utils';
import getMiningSpeed from '../../../DashboardScreen/getters/getMiningSpeed';
import {useTheme, variance} from '../../../styling';
import {Divider, TwoToneIcon} from '../../atoms';
import toFixedTrunc from '../../../utils/toFixedTrunc';
import {sized} from '../../../Svg';
import {SvgProps} from 'react-native-svg';
import SpeedometerSvg from '../../../assets/svg/twoTone/speedometer.svg';
import SpeedometerColorlessSvg from '../../../assets/svg/colorless/speedometer.svg';
import BtcSvg from '../../../assets/svg/twoTone/btc.svg';
import BtcColorlessSvg from '../../../assets/svg/colorless/btcRing.svg';
import {SM_BREAKPOINT} from '../../../WindowDimensions/useDimensions';
import useWorkerStatuses from '../../../DashboardScreen/hooks/useWorkerStatuses';

export type WorkerMiningInfoProps = {
  worker: ReadonlyDeep<WorkerEntity>;
  mode?: 'horizontal' | 'vertical';
};

export default observer(function WorkerMiningInfo({
  worker,
  mode = 'horizontal',
}: WorkerMiningInfoProps) {
  const strings = useStrings();
  const {dashboardStore} = useRoot();
  const {intFormatter} = useIntlFormatter();
  const balance = expr(() =>
    intFormatter(
      toFixedTrunc(dashboardStore.getWorkerBalanceById(worker.id) / 1e12, 8),
    ),
  );
  const state = useWorkerState(worker.id);
  const hashrate = expr(() =>
    intFormatter(getMiningSpeed(worker, state).toFixed(0)),
  );
  const isVerticalMode = expr(() => mode === 'vertical');
  const {needActivate, expired, inactive} = useWorkerStatuses(worker);
  const theme = useTheme();
  const isGrey = expr(() => needActivate || expired || inactive);
  return (
    <RootView vertical={isVerticalMode}>
      <ColumnView firstHorizontal={!isVerticalMode}>
        <IconView vertical={isVerticalMode}>
          {isGrey ? (
            <SpeedometerColorlessSvgIcon color={theme.colors.uiAdditional4} />
          ) : (
            <SpeedometerIcon />
          )}
        </IconView>
        {isVerticalMode && <Divider vertical style={styles.verticalDivider} />}
        <ContentView vertical={isVerticalMode}>
          <Text>
            <MainText
              numberOfLines={1}
              adjustsFontSizeToFit
              disabled={isGrey}
              type="body"
              weight="500">
              {hashrate}
            </MainText>{' '}
            <MainHelperText disabled={isGrey} type="body" weight="500">
              H/s
            </MainHelperText>
          </Text>
          <HelperText type="body" disabled={isGrey} weight="500">
            {strings['worker.minedSpeed']}
          </HelperText>
        </ContentView>
      </ColumnView>
      <Divider
        style={
          isVerticalMode
            ? styles.middleHorizontalDivider
            : styles.middleVerticalDivider
        }
        vertical={!isVerticalMode}
      />
      <ColumnView secondHorizontal={!isVerticalMode}>
        <IconView vertical={isVerticalMode}>
          {isGrey ? (
            <BtcColorlessIcon color={theme.colors.uiAdditional4} />
          ) : (
            <BtcIcon />
          )}
        </IconView>
        {isVerticalMode && <Divider vertical style={styles.verticalDivider} />}
        <ContentView vertical={isVerticalMode}>
          <Text>
            <MainText disabled={isGrey} type="body" weight="500">
              {balance}
            </MainText>{' '}
            <MainHelperText disabled={isGrey} type="body" weight="500">
              BTC
            </MainHelperText>
          </Text>
          <HelperText type="body" weight="500" disabled={isGrey}>
            {strings['worker.mined']}
          </HelperText>
        </ContentView>
      </ColumnView>
    </RootView>
  );
});

const SpeedometerIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(SpeedometerSvg, 22, 17)} {...props} />
));
const SpeedometerColorlessSvgIcon = sized(SpeedometerColorlessSvg, 22, 17);
const BtcColorlessIcon = sized(BtcColorlessSvg, 23);

const BtcIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(BtcSvg, 23)} {...props} />
));

const styles = StyleSheet.create({
  verticalDivider: {
    height: 36,
  },
  middleHorizontalDivider: {
    paddingVertical: 5,
  },
  middleVerticalDivider: {
    height: 36,
  },
});

const RootView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    paddingRight: 16,
    paddingLeft: 10,
    maxWidth: '100%',
  },
  vertical: {
    flexDirection: 'column',
  },
}));

const ColumnView = variance(View)((theme) => ({
  root: {
    paddingVertical: 3,
    paddingRight: 10,
    flexDirection: 'row',
  },
  firstHorizontal: {
    flex: 4,
    ...theme.mediaQuery({
      [SM_BREAKPOINT]: {
        flex: 1,
      },
    }),
  },
  secondHorizontal: {
    flex: 5,
    ...theme.mediaQuery({
      [SM_BREAKPOINT]: {
        flex: 1,
      },
    }),
  },
}));

const IconView = variance(View)(() => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
  },
  vertical: {
    paddingHorizontal: 15,
  },
}));

const ContentView = variance(View)(() => ({
  root: {},
  vertical: {
    paddingLeft: 16,
  },
}));

const MainText = variance(Typography)((theme) => ({
  root: {
    fontSize: 18,
    lineHeight: 22,
    letterSpacing: -0.2,
    color: theme.colors.uiSecondary,
  },
  disabled: {
    color: theme.colors.uiAdditional4,
  },
}));

const MainHelperText = variance(Typography)((theme) => ({
  root: {
    fontSize: 12,
    lineHeight: 15,
    letterSpacing: -0.2,
    color: theme.colors.uiSecondary,
  },
  disabled: {
    color: theme.colors.uiAdditional4,
  },
}));

const HelperText = variance(Typography)((theme) => ({
  root: {
    textTransform: 'lowercase',
    color: theme.colors.primaryUIDirtyBlue,
    fontSize: 12,
    lineHeight: 15,
    letterSpacing: -0.2,
  },
  disabled: {
    color: theme.colors.uiAdditional4,
  },
}));
