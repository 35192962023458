import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {AppRouteName} from '../../../Navigation/AppRouteName';
import {StyleSheet, View} from 'react-native';
import useGetScreenSegments from '../../../Navigation/hooks/useGetRouteSegments';
import Title from './Title';
import BtcQuoteChart from './BtcQuote';
import BtcBalance from './BtcBalance';
import {Language} from '../../../MenuScreen/Language';
import UserProfile from './UserProfile';
import {translateBreakpointToPixels} from '../../../WindowDimensions/useDimensions';
import {expr} from 'mobx-utils';
import {useScreenContentWidth} from '../../../AppTemplateState';
import {AppForceParamList} from '../../../Navigation/AppForceParamList';
import {StackNavigationProp} from '@react-navigation/stack';
import {useRoot} from '../../../Root/hooks';

export type AppHeaderProps = {
  activeRouteName: AppRouteName | undefined;
  navigation: StackNavigationProp<AppForceParamList>;
  goBackPossible: boolean;
  onGoBackPress: () => void;
};

export default observer(function AppHeader({
  activeRouteName,
  navigation,
  goBackPossible,
  onGoBackPress,
}: AppHeaderProps) {
  const getSegments = useGetScreenSegments();
  const {location} = useRoot();
  const {
    configuration: {values},
  } = useRoot();
  const segments = getSegments({
    routeName: activeRouteName,
    navigation: navigation,
  });
  const goToWithdraw = useCallback(
    () =>
      navigation.navigate('Root', {
        screen: 'Withdraw',
      }),
    [navigation],
  );
  const goToLanguage = useCallback(
    () => navigation.navigate('PickLanguage'),
    [navigation],
  );
  const goToLogin = useCallback(
    () => navigation.navigate('Auth'),
    [navigation],
  );
  const goToProfile = useCallback(
    () => navigation.navigate('Menu'),
    [navigation],
  );
  const {top} = useSafeAreaInsets();
  const width = useScreenContentWidth();
  const isXl = expr(() => width >= translateBreakpointToPixels('xl'));
  const visibleBtcRates = activeRouteName !== 'Payment' && isXl;
  const hiddenBalance =
    activeRouteName === 'Withdraw' || activeRouteName === 'Payment';
  const goToVerify = useCallback(() => {
    location.open(values.affiliateVerifyUrl);
  }, [values.affiliateVerifyUrl, location]);
  const testIdGoBack = activeRouteName?.toLowerCase() + '_back_arrow_btn';

  return (
    <View style={{paddingTop: top}}>
      <View style={styles.root}>
        <View style={styles.titleView}>
          <Title
            testIdGoBack={testIdGoBack}
            canBack={goBackPossible}
            goBack={onGoBackPress}
            segments={segments}
          />
        </View>
        {visibleBtcRates && <BtcQuoteChart />}
        {!hiddenBalance && <BtcBalance goToWithdraw={goToWithdraw} />}
        <UserProfile
          goToProfile={goToProfile}
          goToLogin={goToLogin}
          activeRouteName={activeRouteName}
          goToVerify={goToVerify}
        />
        <Language
          goToLanguage={goToLanguage}
          hideLabel
          pickerStyle={styles.language}
        />
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    minHeight: 78,
    paddingVertical: 16,
    paddingLeft: 38,
    paddingRight: 18,
    flexDirection: 'row',
  },
  titleView: {
    flex: 1,
    marginRight: 'auto',
    alignSelf: 'center',
  },
  language: {
    height: 46,
    marginLeft: 18,
  },
});
