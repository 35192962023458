import {
  NavigationState,
  PartialState,
} from '@react-navigation/routers/src/types';
import {first} from 'lodash';
import {action, autorun, observable} from 'mobx';
import {observer} from 'mobx-react-lite';
import {expr} from 'mobx-utils';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {AdSpot, WorkerId} from '../../ApiStore';
import {FULFILLED} from '../../AsyncAtom';
import {Mode} from '../../DashboardMode';
import {DashboardScreen} from '../../DashboardScreen';
import {useErrorHandler} from '../../ExceptionHandler';
import {IntroModalStatus} from '../../IntroVideoManager/IntroVideoManagerService';
import {useOpenLink} from '../../Links';
import {ActivatorStatus} from '../../PoolMinerActivator';
import {useRoot} from '../../Root/hooks';
import {TutorialType} from '../../TutorialManager/TutorialManager';
import {RootParamList} from '../RootStack';
import {useNavigationGetIsFocused, useSafelyGoToAffiliate} from '../hooks';
import {AffiliateVariant} from '../hooks/useSafelyGoToAffiliate';
import {BottomTabBindingProps} from './BottomTabBindingProps';
import {useHSHBalance} from '../../HSHAffiliate';
import {AccountType} from '../../Auth';

export type DashboardBindingProps = BottomTabBindingProps<'Dashboard'>;

export const DashboardBinding = observer((props: DashboardBindingProps) => {
  const {navigation} = props;
  const {
    auth,
    connectedClient,
    dashboardModeService,
    dashboardStore,
    configuration: {values},
    advertHelper,
    introVideoManager,
    windowDimensionsState,
  } = useRoot();
  const [affiliateBannerVisibleBox] = useState(() => observable.box(true));
  const isDesktop = expr(() => windowDimensionsState.window.width >= 1024);
  const {shareLink} = useHSHBalance();
  const handleShareLink = useCallback(() => {
    if (auth.accountType === AccountType.Temporary) {
      navigation.navigate('Auth', {variant: 'new-affiliate'});
    } else {
      shareLink();
    }
  }, [auth, navigation, shareLink]);
  const closeAffiliateBanner = useMemo(
    () => action(() => affiliateBannerVisibleBox.set(false)),
    [affiliateBannerVisibleBox],
  );
  const getAffiliateBannerVisible = useCallback(
    () => affiliateBannerVisibleBox.get(),
    [affiliateBannerVisibleBox],
  );
  const openReadMoreLink = useOpenLink(values.pcUrl);
  const goToAuthAndThenToDashboard = useCallback(
    () =>
      navigation.navigate('Auth', {
        onSuccess: createDashboardState(),
        variant: 'promo',
      }),
    [navigation],
  );
  const {safelyGoToAffiliate, getAffiliateIsPending} = useSafelyGoToAffiliate(
    goToAuthAndThenToDashboard,
    AffiliateVariant.Promo,
  );
  const changeDashboardMode = useCallback(
    (mode: Mode) => {
      dashboardModeService.changeMode(mode);
    },
    [dashboardModeService],
  );
  const goToSwitchDemo = useCallback(
    () => navigation.navigate('SwitchDemoMode'),
    [navigation],
  );
  const goToLinkWorker = useCallback(
    () => navigation.navigate('LinkWorker'),
    [navigation],
  );
  const goToReadMore = useCallback(
    () => openReadMoreLink(),
    [openReadMoreLink],
  );
  const goToPickDemoMiner = useCallback(
    () => navigation.navigate('LinkDemoWorker'),
    [navigation],
  );
  const goToWithdraw = useCallback(
    () => navigation.navigate('Withdraw'),
    [navigation],
  );
  const goToScanQr = useCallback(
    () => navigation.navigate('QRCodeScanner'),
    [navigation],
  );
  const goToWorkerGroupAction = useCallback(
    (groupId: number) => navigation.navigate('WorkerGroupAction', {groupId}),
    [navigation],
  );
  const goToWorkerAction = useCallback(
    (workerId: WorkerId) =>
      navigation.navigate('WorkerAction', {workerId: workerId}),
    [navigation],
  );
  const goToAddWorker = useCallback(
    () => navigation.navigate('AddWorker'),
    [navigation],
  );
  const goToWorkerDetails = useCallback(
    (workerId: WorkerId) =>
      navigation.navigate('WorkerDetails', {workerId: workerId}),
    [navigation],
  );
  const goToRenewAccess = useCallback(
    (workerId: WorkerId) =>
      navigation.navigate('RenewAccess', {workerId: workerId}),
    [navigation],
  );
  const goToAddNewWorkerGroup = useCallback(
    () => navigation.navigate('AddNewWorkerGroup'),
    [navigation],
  );
  const goToRenameWorkerGroup = useCallback(
    (groupId: number) => navigation.navigate('RenameWorkerGroup', {groupId}),
    [navigation],
  );
  const goToPickDashboardSort = useCallback(() => {
    navigation.navigate('PickDashboardSort');
  }, [navigation]);
  const goToPoolActivator = useCallback(() => {
    navigation.navigate('PoolPuzzleConfirm');
  }, [navigation]);
  const goToPlan = useCallback(() => {
    navigation.navigate('Plan');
  }, [navigation]);
  const goToBonusProgram = useCallback(() => {
    if (auth.accountType === AccountType.Temporary) {
      navigation.navigate('Auth', {variant: 'new-affiliate'});
    } else {
      navigation.navigate('BonusProgram');
    }
  }, [auth, navigation]);
  const goToPoolMinerSchedulerWarning = useCallback(
    (workerId: WorkerId) => {
      navigation.navigate('PoolMinerSchedulerWarning', {workerId});
    },
    [navigation],
  );
  const goToCopyDiscountLink = useCallback(() => {
    navigation.navigate('CopyDiscountLink');
  }, [navigation]);
  const goToLimitedOffer = useCallback(
    () => navigation.navigate('QuickStart'),
    [navigation],
  );
  const goToIntroVideoCloseConfirm = useCallback(() => {
    introVideoManager.closeBannerOnTime();
  }, [introVideoManager]);
  const goToIntroVideo = useCallback(() => {
    navigation.navigate('IntroVideo');
    introVideoManager.setModalStatus(IntroModalStatus.Video);
  }, [navigation, introVideoManager]);
  const errorHandler = useErrorHandler();
  const onWorkerSpeedChanged = useCallback(
    async (speed: number, workerId: WorkerId) => {
      const result = await connectedClient.call('change_settings', {
        worker_id: workerId,
        settings: {speed},
      });
      if (!result.success) {
        errorHandler.handle(result.left);
      }
    },
    [connectedClient, errorHandler],
  );
  const getIsFocused = useNavigationGetIsFocused();

  useRedirectEffect(props);
  useEffect(
    () =>
      autorun(() => {
        if (getIsFocused() && auth.isConnected) {
          // noinspection JSIgnoredPromiseFromCall
          dashboardStore.updateBalance();
        }
      }),
    [auth, dashboardStore, getIsFocused],
  );
  const getAdvertMobileBanner = useCallback(
    () =>
      expr(() =>
        first(advertHelper.spotByBannerList?.get(AdSpot.DashboardMobileTop)),
      ),
    [advertHelper],
  );
  const getAdvertDesktopBanner = useCallback(
    () =>
      expr(() =>
        first(advertHelper.spotByBannerList?.get(AdSpot.DashboardDesktopTop)),
      ),
    [advertHelper],
  );
  const videoBannerIsVisible = expr(
    () => !isDesktop && introVideoManager.shouldOpenBanner,
  );
  const goToAffiliate = useOpenLink(values.affiliateWithdrawalUrl);
  return (
    <DashboardScreen
      dashboardMode={dashboardModeService.mode}
      changeDashboardMode={changeDashboardMode}
      goToAddWorker={goToAddWorker}
      goToWithdraw={goToWithdraw}
      goToPickDemoMiner={goToPickDemoMiner}
      goToScanQr={goToScanQr}
      goToLinkWorker={goToLinkWorker}
      goToReadMore={goToReadMore}
      safelyGoToPromoAffiliate={safelyGoToAffiliate}
      getAffiliateIsPending={getAffiliateIsPending}
      goToWorkerAction={goToWorkerAction}
      goToWorkerDetails={goToWorkerDetails}
      goToWorkerGroupAction={goToWorkerGroupAction}
      goToAddNewWorkerGroup={goToAddNewWorkerGroup}
      goToRenameWorkerGroup={goToRenameWorkerGroup}
      goToRenewAccess={goToRenewAccess}
      goToPickDashboardSort={goToPickDashboardSort}
      goToPoolActivator={goToPoolActivator}
      goToPlan={goToPlan}
      goToPoolMinerSchedulerWarning={goToPoolMinerSchedulerWarning}
      goToCopyDiscountLink={goToCopyDiscountLink}
      onWorkerSpeedChanged={onWorkerSpeedChanged}
      getAdvertMobileBanner={getAdvertMobileBanner}
      getAdvertDesktopBanner={getAdvertDesktopBanner}
      goToLimitedOffer={goToLimitedOffer}
      closeAffiliateBanner={closeAffiliateBanner}
      getAffiliateBannerVisible={getAffiliateBannerVisible}
      goToSwitchDemo={goToSwitchDemo}
      videoBannerIsVisible={videoBannerIsVisible}
      goToIntroVideo={goToIntroVideo}
      goToIntroVideoCloseConfirm={goToIntroVideoCloseConfirm}
      goToBonusProgram={goToBonusProgram}
      goToAffiliate={goToAffiliate}
      shareBalance={handleShareLink}
    />
  );
});

function useRedirectEffect({navigation, route}: DashboardBindingProps) {
  const {
    poolMinerActivator,
    navigationContainer,
    advertHelper,
    tutorialManager,
    interactiveTutorial,
    quickStartOffer,
    pushAdvert,
    introVideoManager,
    appLifecycle,
  } = useRoot();
  const getIsFocused_ = useNavigationGetIsFocused();
  const isCurrentScreen = route.name === 'Dashboard';
  const getIsFocused = useCallback(
    () => getIsFocused_() && isCurrentScreen,
    [getIsFocused_, isCurrentScreen],
  );
  const goToPoolActivator = useCallback(
    () => navigation.navigate('PoolPuzzleConfirm'),
    [navigation],
  );
  const goToAdSplash = useCallback(
    () => navigation.navigate('AdvertSplash'),
    [navigation],
  );
  const goToSplashTutorial = useCallback(
    () => navigation.navigate('Tutorial'),
    [navigation],
  );
  const goToInteractiveTutorial = useCallback(() => {
    interactiveTutorial.start();
  }, [interactiveTutorial]);
  const goToQuickStart = useCallback(
    () => navigation.navigate('QuickStartModal'),
    [navigation],
  );
  const getShouldRedirectToSplashTutorial = useCallback(
    () => tutorialManager.shouldOpenTutorialModal === TutorialType.Splash,
    [tutorialManager],
  );
  const getShouldRedirectToInteractiveTutorial = useCallback(
    () => tutorialManager.shouldOpenTutorialModal === TutorialType.Interactive,
    [tutorialManager],
  );
  const getShouldRedirectToPoolActivator = useCallback(
    () =>
      poolMinerActivator.activatorStatus === ActivatorStatus.NeedActivate &&
      navigationContainer.isConfigured,
    [navigationContainer, poolMinerActivator],
  );
  const goToIntroVideoPreview = useCallback(
    () => navigation.navigate('IntroVideo'),
    [navigation],
  );
  const getShouldRedirectToAdSplash = useCallback(() => {
    const {
      state,
      isDimensionsForShowMobileSplash,
      isDimensionsForShowDesktopSplash,
    } = advertHelper;
    if (state?.status !== FULFILLED) {
      return false;
    }
    if (quickStartOffer.shouldShownModal) {
      return false;
    }
    if (pushAdvert.adToShow !== undefined) {
      return false;
    }
    const {spotByBannerList} = state.result;
    return (
      ((spotByBannerList.get(AdSpot.SplashMobile) !== undefined &&
        isDimensionsForShowMobileSplash) ||
        (spotByBannerList.get(AdSpot.SplashDesktop) !== undefined &&
          isDimensionsForShowDesktopSplash)) &&
      !appLifecycle.hasJustBeenInstalled
    );
  }, [advertHelper, pushAdvert, quickStartOffer, appLifecycle]);

  useEffect(
    () =>
      autorun(async () => {
        if (!getIsFocused()) {
          return;
        }
        if (
          [tutorialManager.ready, quickStartOffer.ready].some((_) => _ !== true)
        ) {
          return;
        }
        if (getShouldRedirectToSplashTutorial()) {
          tutorialManager.unflag();
          goToSplashTutorial();
        } else if (getShouldRedirectToInteractiveTutorial()) {
          tutorialManager.unflag();
          goToInteractiveTutorial();
        } else if (getShouldRedirectToPoolActivator()) {
          goToPoolActivator();
        } else if (quickStartOffer.shouldShownModal) {
          goToQuickStart();
        } else if (getShouldRedirectToAdSplash()) {
          goToAdSplash();
        } else if (introVideoManager.isModalPreviewCouldBeShown) {
          introVideoManager.setModalStatus(IntroModalStatus.Preview);
          goToIntroVideoPreview();
        }
      }),
    [
      getIsFocused,
      goToAdSplash,
      goToInteractiveTutorial,
      goToPoolActivator,
      goToQuickStart,
      goToSplashTutorial,
      getShouldRedirectToAdSplash,
      getShouldRedirectToInteractiveTutorial,
      getShouldRedirectToPoolActivator,
      quickStartOffer,
      getShouldRedirectToSplashTutorial,
      tutorialManager,
      introVideoManager,
      goToIntroVideoPreview,
    ],
  );
}

export const createDashboardState = (
  goToAffiliateToken?: string,
): PartialState<NavigationState<RootParamList>> => {
  return {
    index: 0,
    routes: [
      {
        name: 'Root',
        state: {
          index: 0,
          routes: [
            {
              name: 'Dashboard',
              params: {goToAffiliateToken},
            },
          ],
        },
      },
    ],
  };
};
