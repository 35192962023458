import React from 'react';
import {observer} from 'mobx-react-lite';

import ListItem, {ListItemProps} from './ListItem';

import {useStyles} from '../../styling';
import {LG_BREAKPOINT} from '../../WindowDimensions/useDimensions';

export default observer(function MainListItem({style, ...rest}: ListItemProps) {
  const styles = useStyles((theme) => ({
    root: {
      backgroundColor: theme.colors.uiMain,
      flex: 1,
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
        },
      }),
    },
  }));
  return <ListItem style={[styles.root, style]} {...rest}/>;
});
