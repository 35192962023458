import React, {forwardRef, PropsWithChildren, ReactNode} from 'react';
import {StyleProp, TextProps, TextStyle, View, ViewStyle} from 'react-native';
import {observer} from 'mobx-react-lite';
import {SvgProps} from 'react-native-svg';
import Typography from '../Typography';
import {
  ButtonSize,
  ButtonVariant,
  useGetGradientStyles,
  useButtonStyles,
} from './useButtonStyles';
import PressableOpacity, {PressableOpacityProps} from '../PressableOpacity';
import {LinearGradient} from 'expo-linear-gradient';
import {Hoverable} from 'react-native-web-hooks';

export type ButtonProps = Omit<PressableOpacityProps, 'children'> & {
  variant?: ButtonVariant;
  Icon?: React.ComponentType<SvgProps>;
  rootStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  textProps?: TextProps;
  size?: ButtonSize;
  children?:
    | string
    | ReactNode
    | ((
        style: StyleProp<TextStyle>,
        hoverStyle: StyleProp<TextStyle>,
      ) => ReactNode);
};

export default observer<ButtonProps, View>(
  forwardRef(function Button(props, ref) {
    const {
      variant = ButtonVariant.Default,
      Icon,
      children,
      textStyle,
      rootStyle,
      textProps,
      disabled = false,
      ...rest
    } = props;
    const styles = useButtonStyles(props);
    const gradient = useGetGradientStyles(variant);
    const content = (
      hoverColor?: StyleProp<TextStyle>,
      isHovered?: boolean,
    ) => (
      <>
        {Icon && <Icon style={[styles.icon, isHovered && hoverColor]} />}
        {children !== undefined &&
          (typeof children === 'string' ? (
            <AppButtonText
              {...textProps}
              style={[styles.text, textStyle, isHovered && hoverColor]}>
              {children}
            </AppButtonText>
          ) : typeof children === 'function' ? (
            children(styles.text, isHovered ? hoverColor : {})
          ) : (
            children
          ))}
      </>
    );

    if (gradient) {
      return (
        <Hoverable>
          {(isHovered) => (
            <PressableOpacity disabled={disabled} {...rest} ref={ref}>
              <LinearGradient
                colors={gradient.getColor(isHovered)}
                start={gradient.start}
                end={gradient.end}
                style={[styles.root, rootStyle, textStyle]}>
                {content({color: gradient.hoveredTextColor}, isHovered)}
              </LinearGradient>
            </PressableOpacity>
          )}
        </Hoverable>
      );
    }

    return (
      <PressableOpacity disabled={disabled} {...rest} ref={ref}>
        <View style={[styles.root, rootStyle]}>{content()}</View>
      </PressableOpacity>
    );
  }),
);

export type AppButtonTextProps = PropsWithChildren<TextProps>;

export const AppButtonText = observer(
  ({children, ...rest}: AppButtonTextProps) => {
    return (
      <Typography selectable={false} weight="600" type="body" {...rest}>
        {children}
      </Typography>
    );
  },
);
