import React from 'react';
import {observer} from 'mobx-react-lite';
import Content from './Content';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {WorkerId} from '../ApiStore';
import {WorkerDetailsContext} from './context';

export type WorkerDetailsScreenProps = {
  onWorkerSpeedChanged: (speed: number) => void;
  goToScheduler: () => void;
  goToRenew: () => void;
  goToPoolActivator: () => void;
  worker: ReadonlyDeep<WorkerEntity>;
  visibleRenewButton: boolean;
  visibleManageButton: boolean;
  isSubscriptionWorker: boolean;
  goToWorkerAction: () => void;
  goToManageSubscription: () => void;
  goToPoolMinerSchedulerWarning: (workerId: WorkerId) => void;
  gotToDelete: () => void;
};

export default observer(function WorkerDetailsScreen(
  props: WorkerDetailsScreenProps,
) {
  return (
    <WorkerDetailsContext.Provider value={props}>
      <Content />
    </WorkerDetailsContext.Provider>
  );
});
