import React, {useCallback, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {
  useIntlFormatter,
  useRoot,
  useStrings,
  useTemplates,
} from '../Root/hooks';
import {useTheme, variance} from '../styling';
import {Platform, Pressable, View} from 'react-native';
import {SubscriptionCandidateKind} from '../ProposalsState';
import {sized} from '../Svg';
import {Header, Paragraph} from '../components';
import ChevronDownSvg from '../assets/svg/colorless/chevronDown.svg';
import Animated, {useAnimatedStyle, withSpring} from 'react-native-reanimated';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {Divider} from '../components/atoms';
import {
  LG_BREAKPOINT,
  useGetDimensions,
} from '../WindowDimensions/useDimensions';
import SavedMessage from '../components/organisms/purchase/TotalPrice/SavedMessage';
import {OfferDiscount, Trial} from '../InAppOffersService';
import {expr} from 'mobx-utils';
import TotalPriceSubscription from './TotalPriceVariants/TotalPriceSubscription';
import TotalPriceBtnAndGift from './TotalPriceVariants/TotalPriceBtnAndGift';
import {VerticalDivider} from './TotalPriceVariants/shared';
import {PurchaseId} from '../units';

export type BaseOffer = {
  uiPrice: string;
  questionMarkVisible: boolean;
  helperPrice: string | undefined;
  currency: string;
  discounts: OfferDiscount[];
  interval: number;
  discountsDifferencePrice: number | undefined;
  uiDiscountsDifferencePrice: string | undefined;
  uiPricePerMonth?: string;
};

export type ProductOffer = BaseOffer & {};

export type SubscriptionOffer =
  | {
      kind: SubscriptionCandidateKind.CanBeImproved;
      purchaseId: PurchaseId;
    }
  | {
      kind: SubscriptionCandidateKind.Unavailable;
    }
  | {
      kind: SubscriptionCandidateKind.CanBeManaged;
      purchaseId: PurchaseId;
    }
  | (BaseOffer & {
      kind: SubscriptionCandidateKind.Available;
      trial: Trial | null;
    });

export type TotalPriceViewProps = {
  product: ProductOffer | undefined;
  subscription: SubscriptionOffer | undefined;
  onMainOfferPurchasePress: () => void;
  onAdditionalOfferPurchasePress?: () => void;
  onImprovePress?: () => void;
  onManagePress?: () => void;
  subscriptionOptionAvailable?: boolean;
  submitProductText?: string;
  submitSubscriptionText?: string;
  hideGift?: boolean;
};

export default observer(function TotalPriceView(props: TotalPriceViewProps) {
  const strings = useStrings();
  const {
    product,
    subscription,
    onMainOfferPurchasePress,
    hideGift,
    onAdditionalOfferPurchasePress,
    onImprovePress,
    onManagePress,
    subscriptionOptionAvailable = true,
    submitProductText = strings['action.buy'],
    submitSubscriptionText = strings['action.subscribe'],
  } = props;
  const {
    translation: {localeTag},
  } = useRoot();
  const theme = useTheme();
  const {intFormatter} = useIntlFormatter();
  const getDimension = useGetDimensions();
  const isLg = expr(() => getDimension('lg'));
  const [visibleDiscount, setVisibleDiscount] = useState(true);
  const toggleVisibleDiscount = useCallback(() => {
    setVisibleDiscount(!visibleDiscount);
  }, [visibleDiscount]);
  const animatedPosition = useRotatedChevron(visibleDiscount);
  const safeAreaInsets = useSafeAreaInsets();
  const paddingBottom = isLg ? 0 : Math.max(safeAreaInsets.bottom, 12);
  const templates = useTemplates();
  const renderDiscounts = useCallback(() => {
    const purchase =
      product ||
      (subscription?.kind === SubscriptionCandidateKind.Available
        ? subscription
        : undefined);
    if (!purchase) {
      return null;
    }
    return (
      <>
        {purchase.uiDiscountsDifferencePrice !== undefined &&
          purchase.currency &&
          !isLg && (
            <DiscountHeaderView>
              <SafeMessagePressable onPress={toggleVisibleDiscount}>
                <SavedMessage
                  formattedSaveSum={purchase.uiDiscountsDifferencePrice}
                  currency={purchase.currency}
                />
                {purchase.discounts.map((d, index) => (
                  <DiscountPercentView
                    offset
                    key={index + 'top'}
                    secondary={index % 2 === 0}>
                    <DiscountPercentText
                      type="paragraph"
                      size="tiny"
                      weight="500">
                      -{d.percent}%
                    </DiscountPercentText>
                  </DiscountPercentView>
                ))}
                <Animated.View style={animatedPosition}>
                  <ChevronDownIcon color={theme.colors.primaryUIDirtyBlue} />
                </Animated.View>
              </SafeMessagePressable>
              <Divider />
            </DiscountHeaderView>
          )}
        {(visibleDiscount || (isLg && purchase.discounts.length > 0)) && (
          <>
            {isLg && (
              <>
                {purchase.uiDiscountsDifferencePrice && (
                  <SavedMessage
                    formattedSaveSum={purchase.uiDiscountsDifferencePrice}
                    currency={purchase.currency}
                  />
                )}
                <VerticalDivider />
              </>
            )}
            <PromoListView
              withThird={
                subscriptionOptionAvailable &&
                subscription?.kind === SubscriptionCandidateKind.Available
              }>
              {purchase.discounts.map((discount, index) => {
                const secondary = index % 2 === 0;
                const title = discount.title[localeTag];
                const isLast = purchase.discounts.length === index + 1;
                return (
                  <DiscountRowView key={index} offsetTop={index !== 0}>
                    <DiscountTitleContainer
                      withThird={subscriptionOptionAvailable}>
                      {isLg ? (
                        <Header type="header" size="h5" weight="600">
                          {title}
                        </Header>
                      ) : (
                        <Paragraph type="paragraph" weight="500">
                          {title}
                        </Paragraph>
                      )}
                    </DiscountTitleContainer>
                    <DiscountAdaptiveContainer>
                      <DiscountPercentView secondary={secondary}>
                        <DiscountPercentText type="paragraph" weight="600">
                          -{intFormatter(discount.percent)}%
                        </DiscountPercentText>
                      </DiscountPercentView>
                      <DiscountPriceContainer>
                        <DiscountText type="paragraph" weight="500" cross>
                          {intFormatter(discount.uiToPrice)} {purchase.currency}
                        </DiscountText>
                        {isLast && (
                          <DiscountText last type="paragraph" weight="500">
                            {intFormatter(purchase.uiPrice)} {purchase.currency}
                          </DiscountText>
                        )}
                      </DiscountPriceContainer>
                    </DiscountAdaptiveContainer>
                  </DiscountRowView>
                );
              })}
              {!isLg && <Divider />}
            </PromoListView>
          </>
        )}
        {isLg && <VerticalDivider />}
      </>
    );
  }, [
    animatedPosition,
    isLg,
    localeTag,
    product,
    subscription,
    subscriptionOptionAvailable,
    theme,
    toggleVisibleDiscount,
    visibleDiscount,
    intFormatter,
  ]);
  const pricePerMount =
    product &&
    ((product.uiPricePerMonth &&
      `~${intFormatter(product.uiPricePerMonth)} ${product.currency} / 1 ${
        strings['common.shortMonth']
      }`) ||
      'product.helperPrice');
  return (
    <RootView style={{paddingBottom}}>
      {renderDiscounts()}
      {product ? (
        <>
          <TotalPriceBtnAndGift
            hideGift={hideGift}
            btnText={`${submitProductText} ${templates[
              'totalPrice.productTitlePrice'
            ]({
              price: intFormatter(product.uiPrice),
              currency: product.currency,
              interval: product.interval,
            })}`}
            onPress={onMainOfferPurchasePress}
            subtitle={pricePerMount}
          />
        </>
      ) : null}

      {subscription ? (
        <TotalPriceSubscription
          hideGift={hideGift}
          subscription={subscription}
          onAdditionalOfferPurchasePress={onAdditionalOfferPurchasePress}
          onManagePress={onManagePress}
          onImprovePress={onImprovePress}
          submitSubscriptionText={submitSubscriptionText}
        />
      ) : null}
    </RootView>
  );
});

const ChevronDownIcon = sized(ChevronDownSvg, 12, 6);

const RootView = variance(View)((theme) => ({
  root: {
    marginTop: -20,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12,
        borderWidth: 1,
        backgroundColor: undefined,
        marginTop: 0,
        borderColor: theme.colors.uiAdditional1,
        borderTopWidth: 0,
        flexDirection: 'row',
        marginBottom: 20,
        alignItems: 'center',
        ...Platform.select({
          web: {
            marginBottom: 0,
          },
        }),
      },
    }),
  },
}));

const DiscountText = variance(Paragraph)((theme) => ({
  root: {
    color: theme.colors.uiSecondary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {marginLeft: 4},
    }),
  },
  last: {
    color: theme.colors.primaryNew,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {fontSize: 14, marginLeft: 12},
    }),
  },
  cross: {
    textDecorationLine: 'line-through',
  },
}));

const DiscountHeaderView = variance(View)((theme) => ({
  root: {
    borderTopRightRadius: 24,
    borderTopLeftRadius: 24,
    backgroundColor: theme.colors.backgroundGround,
  },
}));

const DiscountAdaptiveContainer = variance(View)((theme) => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    flex: 1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: undefined,
        flexDirection: 'row-reverse',
      },
    }),
  },
}));

const SafeMessagePressable = variance(Pressable)(() => ({
  root: {
    paddingHorizontal: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 44,
  },
}));

const PromoListView = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.colors.backgroundGround,
    marginTop: -1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        backgroundColor: undefined,
        marginTop: 0,
        flex: 4,
      },
    }),
  },
  withThird: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: 4,
      },
    }),
  },
}));

const DiscountRowView = variance(View)(() => ({
  root: {
    paddingHorizontal: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  offsetTop: {
    marginTop: 10,
  },
}));

const DiscountTitleContainer = variance(View)((theme) => ({
  root: {
    flexDirection: 'row',
    maxWidth: '50%',
    alignItems: 'center',
    flex: 1,
    flexShrink: 0,

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: undefined,
        maxWidth: 'auto',
        marginRight: 15,
      },
    }),
  },
  withThird: {},
}));

const DiscountPriceContainer = variance(View)((theme) => ({
  root: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row',
      },
    }),
  },
  withThird: {
    justifyContent: 'flex-start',
    width: '100%',
    marginLeft: -2,
  },
}));

const DiscountPercentView = variance(View)((theme) => ({
  root: {
    height: 20,
    width: 38,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: 6,
    backgroundColor: theme.colors.primarySuccess,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row',
        marginLeft: 24,
        marginRight: 0,
      },
    }),
  },
  offset: {
    marginLeft: 'auto',
    marginRight: 8,
  },
  secondary: {
    backgroundColor: theme.colors.primaryNew,
  },
}));

const DiscountPercentText = variance(Paragraph)((theme) => ({
  root: {
    color: theme.colors.primaryWhite,
    lineHeight: 15,
  },
}));

export const useRotatedChevron = (visible: boolean) =>
  useAnimatedStyle(() => ({
    width: 12,
    height: 6,
    transform: [
      {
        rotate: withSpring(visible ? '180deg' : '0deg', {
          damping: 100,
          stiffness: 90,
        }),
      },
    ],
  }));
