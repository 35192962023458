import {observer} from 'mobx-react-lite';
import React from 'react';
import ColoredBanner, {
  ShadowColor,
  DefaultButton,
  MONEY_CASE_ICON,
  YELLOW_BACKGROUND,
} from '../../molecules/ColoredBanner';
import {useStrings} from '../../../Root/hooks';
import {ViewProps, StyleSheet} from 'react-native';

export type UnlockThePossibilitiesOfMiningBannerProps = ViewProps & {
  onButtonPress: () => void;
};

export default observer(function UnlockThePossibilitiesOfMiningBanner({
  onButtonPress,
  testID,
  ...rest
}: UnlockThePossibilitiesOfMiningBannerProps) {
  const strings = useStrings();
  return (
    <ColoredBanner
      shadowColor={ShadowColor.Orange}
      title={strings['banner.unlockThePossibilitiesOfMining.title']}
      titleStyle={styles.title}
      icon={MONEY_CASE_ICON}
      background={YELLOW_BACKGROUND}
      iconProps={{
        style: {
          transform: [{scale: 1.5}],
        },
      }}
      actions={[
        <DefaultButton
          testID={testID}
          onPress={onButtonPress}
          title={strings['banner.unlockThePossibilitiesOfMining.button']}
        />,
      ]}
      {...rest}
    />
  );
});

const styles = StyleSheet.create({
  title: {
    maxWidth: 290,
  },
});
