import React from 'react';
import {observer} from 'mobx-react-lite';
import {View, StyleSheet} from 'react-native';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {useForcedContext} from '../context';
import {WorkerDetailsContext} from './context';
import CalendarSvg from '../assets/svg/colorless/schedule.svg';
import {sized} from '../Svg';
import useGetIsLarge from '../DashboardScreen/hooks/useGetIsLarge';
import {expr} from 'mobx-utils';
import {useStrings} from '../Root/hooks';
import SettingsSvg from '../assets/svg/colorless/settings.svg';
import RenewSvg from '../assets/svg/colorless/renewIcon.svg';
import TrashSvg from '../assets/svg/colorless/trash.svg';
import useWorkerStatuses from '../DashboardScreen/hooks/useWorkerStatuses';
import {WorkerEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {ReadonlyDeep} from 'type-fest';

export type ButtonListProps = {
  removeWorker: () => void;
  worker: ReadonlyDeep<WorkerEntity>;
};

export default observer(function ButtonList({
  removeWorker,
  worker,
}: ButtonListProps) {
  const getIsLarge = useGetIsLarge();
  const isLarge = expr(() => getIsLarge());
  const strings = useStrings();
  const {expired, offline} = useWorkerStatuses(worker);
  const visibleRemoveButton = offline || expired;

  const {
    goToScheduler,
    goToManageSubscription,
    goToRenew,
    visibleRenewButton,
    visibleManageButton,
  } = useForcedContext(WorkerDetailsContext);
  return (
    <View style={[styles.root, isLarge && styles.rootLarge]}>
      {isLarge && visibleRemoveButton && (
        <AppButton
          variant={ButtonVariant.Text}
          Icon={TrashIcon}
          onPress={removeWorker}
          style={[styles.button, styles.rightOffset]}>
          {strings['deleteWorker.button']}
        </AppButton>
      )}

      <AppButton
        Icon={CalendarIcon}
        onPress={goToScheduler}
        style={[
          styles.button,
          isLarge && styles.leftOffset,
          (visibleManageButton || visibleRenewButton) && styles.bottomOffset,
        ]}>
        {strings['workerDetails.scheduleButton']}
      </AppButton>
      {visibleManageButton ? (
        <AppButton
          variant={ButtonVariant.Primary}
          Icon={SettingsIcon}
          onPress={goToManageSubscription}
          style={[styles.button, isLarge && styles.leftOffset]}>
          {strings['menu.manageSubscription']}
        </AppButton>
      ) : visibleRenewButton ? (
        <AppButton
          variant={ButtonVariant.GreenGradient}
          Icon={RenewIcon}
          onPress={goToRenew}
          style={[styles.button, isLarge && styles.leftOffset]}>
          {strings['renewAccess.buttonRenewAccess']}
        </AppButton>
      ) : null}
      {!isLarge && visibleRemoveButton && (
        <AppButton
          variant={ButtonVariant.Text}
          Icon={TrashIcon}
          onPress={removeWorker}
          style={[styles.button, styles.topOffset]}>
          {strings['deleteWorker.button']}
        </AppButton>
      )}
    </View>
  );
});


const CalendarIcon = sized(CalendarSvg, 14);
const SettingsIcon = sized(SettingsSvg, 18);
const RenewIcon = sized(RenewSvg, 18);
const TrashIcon = sized(TrashSvg, 18);

const styles = StyleSheet.create({
  root: {
    paddingHorizontal: 16,
    paddingTop: 12.5,
    paddingBottom: 16,
  },
  rootLarge: {
    flexDirection: 'row',
    paddingBottom: 12.5,
  },
  button: {
    flex: 1,
  },
  bottomOffset: {
    marginBottom: 9,
  },
  rightOffset: {
    marginRight: 9,
  },
  leftOffset: {
    marginLeft: 9,
  },
  topOffset: {
    marginTop: 9,
  },
});
