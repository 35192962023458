import React from 'react';
import {observer} from 'mobx-react-lite';
import {useForcedContext} from '../../context';
import {DashboardContext} from '../context';
import {View} from 'react-native';
import {variance} from '../../styling';
import {useRoot} from '../../Root/hooks';
import {BannerType} from '../../Dashboard';
import ReactivateCard from './ReactivateCard';
import AffiliateBalanceBanner, {
  AFFILIATE_BALANCE_BANNER_HEIGHT,
} from './AffiliateBalanceBanner';
import {AccountType} from '../../Auth';
import {expr} from 'mobx-utils';

// TODO: Should be rewritten - add gap or other kind of indents to calculation
const HACKED_AFFILIATE_BALANCE_BANNER_HEIGHT =
  AFFILIATE_BALANCE_BANNER_HEIGHT + 16;

const REACTIVATE_CARD_HEIGHT = 170;

export default observer(function HeaderPromoBannerSection() {
  const {goToPoolActivator, goToBonusProgram, goToAffiliate, shareBalance} =
    useForcedContext(DashboardContext);
  const {auth} = useRoot();
  const height = useHeaderPromoBannerHeight();
  const isTemporary = expr(() => auth.accountType === AccountType.Temporary);
  const {
    dashboardService: {bannersState},
    hshAffiliate,
  } = useRoot();

  return (
    <RootView style={{height}}>
      {/*just to hack the padding*/}
      <View style={{height: HACKED_AFFILIATE_BALANCE_BANNER_HEIGHT}}>
        <AffiliateBalanceBanner
          balance={hshAffiliate.balance}
          shareLink={shareBalance}
          onLeaveToAffiliatePress={goToAffiliate}
          onDetailsPress={goToBonusProgram}
          isTemporary={isTemporary}
        />
      </View>
      {bannersState.targetBanner === BannerType.PoolMiner ? (
        <View style={{height: REACTIVATE_CARD_HEIGHT}}>
          <ReactivateCard goToPoolActivator={goToPoolActivator} />
        </View>
      ) : null}
    </RootView>
  );
});

export const useHeaderPromoBannerHeight = () => {
  const {
    dashboardService: {bannersState},
  } = useRoot();
  let summary = 0;
  if (bannersState.targetBanner === BannerType.PoolMiner) {
    summary += REACTIVATE_CARD_HEIGHT;
  }
  summary += HACKED_AFFILIATE_BALANCE_BANNER_HEIGHT;
  return summary;
};

const RootView = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.colors.backgroundGround,
    paddingVertical: 18,
    paddingHorizontal: 16,
  },
}));
