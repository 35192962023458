import React, {PropsWithChildren} from 'react';
import {observer} from 'mobx-react-lite';
import {TouchableOpacity, TouchableOpacityProps, View} from 'react-native';
import Typography from '../../Typography';
import {AppRouteName} from '../../../Navigation/AppRouteName';
import {StackElementKey} from '../../../InteractiveTutorial';
import useTutorialMeasure from '../../../InteractiveTutorial/useTutorialMeasure';
import {createStylesHook} from '../../../styling';

export type AsideButtonProps = TouchableOpacityProps & {
  renderIcon: () => React.ReactNode;
  title?: string;
  isExpanded: boolean;
  isFocused?: boolean;
  disabled?: boolean;
  routeName?: AppRouteName;
};

export default observer(function AsideButton(props: AsideButtonProps) {
  const {routeName, ...rest} = props;
  const tutorialKey = routeName && ROUTE_NAME_BY_TUTORIAL_KEY.get(routeName);
  if (tutorialKey !== undefined) {
    return (
      <TutorialTabButton
        routeName={routeName}
        tutorialKey={tutorialKey}
        {...rest}
      />
    );
  }
  return <TabButton routeName={routeName} {...rest} />;
});

type TutorialTabButtonProps = AsideButtonProps & {
  tutorialKey: StackElementKey;
};
const TutorialTabButton = observer(
  (props: PropsWithChildren<TutorialTabButtonProps>) => {
    const {tutorialKey, ...rest} = props;
    const [ref, onLayout, getForceKey] = useTutorialMeasure<View>(tutorialKey);
    return (
      <TabButton
        buttonRef={ref}
        onLayout={onLayout}
        key={getForceKey()}
        {...rest}
      />
    );
  },
);

type TabButtonProps = AsideButtonProps &
  TouchableOpacityProps & {
    buttonRef?: React.RefObject<View>;
  };
const TabButton = observer((props: PropsWithChildren<TabButtonProps>) => {
  const {
    isFocused,
    title,
    disabled,
    isExpanded,
    buttonRef,
    renderIcon,
    ...rest
  } = props;
  const icon = renderIcon();
  const styles = useStyles();
  return (
    <TouchableOpacity
      ref={buttonRef}
      accessibilityLabel={title}
      accessibilityRole="button"
      accessibilityState={isFocused ? {selected: true} : {}}
      disabled={disabled}
      style={[styles.routeButton, isExpanded && styles.expandedRouteButton]}
      activeOpacity={0.9}
      {...rest}>
      {icon && <View style={styles.iconView}>{icon}</View>}
      {isExpanded && (
        <View style={styles.routeButtonTitleView}>
          <Typography
            numberOfLines={2}
            adjustsFontSizeToFit
            style={styles.titleText}
            type="body"
            weight="600">
            {title}
          </Typography>
        </View>
      )}
    </TouchableOpacity>
  );
});

export const ROUTE_NAME_BY_TUTORIAL_KEY = new Map<
  AppRouteName,
  StackElementKey
>([
  ['Statistics', 'TAB_BAR_STATISTICS'],
  ['MockAddWorker', 'TAB_BAR_ADD_WORKER'],
  ['Notifications', 'TAB_BAR_NOTIFICATIONS'],
]);

const useStyles = createStylesHook((theme) => ({
  routeButton: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 24,
    ...theme.mediaQuery({
      1280: {
        paddingVertical: 20,
      },
    }),
  },
  expandedRouteButton: {},
  iconView: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
  },
  routeButtonTitleView: {
    paddingLeft: 20,
    flex: 1,
  },
  titleText: {
    fontSize: 14,
    lineHeight: 18,
  },
}));
