import {observer} from 'mobx-react-lite';
import React, {forwardRef, useState} from 'react';
import {useStyles} from '../styling';
import LanguageList from './LanguageList';
import {ScrollView, View} from 'react-native';
import {translateBreakpointToPixels} from '../WindowDimensions/useDimensions';
import {
  AdaptiveModal,
  AdaptiveModalRef,
  ModalWidth,
} from '../components/templates/AdaptiveModal';
import {Locale} from '../Preferences';
import {Typography} from '../components';
import {useRoot, useStrings} from '../Root/hooks';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

const ADAPTIVE_BREAKPOINT = 'sm';
const BREAKPOINT_PX = translateBreakpointToPixels(ADAPTIVE_BREAKPOINT);

export type LanguageModalScreenProps = {
  onModalClosed: () => void;
  onSubmit: (locale: Locale) => void;
};

export default observer<LanguageModalScreenProps, AdaptiveModalRef>(
  forwardRef(function LanguageModalScreen({onModalClosed, onSubmit}, ref) {
    const {translation} = useRoot();
    const strings = useStrings();
    const styles = useStyles((theme) => ({
      header: {
        paddingTop: 16,
        paddingHorizontal: 16,
        paddingBottom: 5,
      },
      title: {
        color: theme.colors.uiSecondary,
      },
      container: {
        paddingHorizontal: 16,
        paddingVertical: 5,
        ...theme.mediaQuery({
          [BREAKPOINT_PX]: {
            width: 375,
          },
        }),
      },
      buttonView: {
        paddingHorizontal: 16,
        paddingTop: 10,
      },
    }));
    const [selectedLanguage, setSelectedLanguage] = useState(
      translation.locale,
    );
    const submit = () => onSubmit(selectedLanguage);
    const insets = useSafeAreaInsets();
    const paddingBottom = Math.max(insets.bottom, 16);
    return (
      <AdaptiveModal
        modalWidth={ModalWidth.Small}
        ref={ref}
        enableContentAutoHeight
        onModalClosed={onModalClosed}
        adaptiveBreakpoint={ADAPTIVE_BREAKPOINT}>
        <View style={styles.header}>
          <Typography
            type="header"
            size="h4"
            weight="bold"
            style={styles.title}>
            {strings['menu.language']}
          </Typography>
        </View>
        <ScrollView bounces={false} contentContainerStyle={styles.container}>
          <LanguageList
            selectedLanguage={selectedLanguage}
            onChange={setSelectedLanguage}
          />
        </ScrollView>
        <View style={[styles.buttonView, {paddingBottom}]}>
          <AppButton variant={ButtonVariant.Primary} onPress={submit} testID="modal_language_select_btn">
            {strings['action.select']}
          </AppButton>
        </View>
      </AdaptiveModal>
    );
  }),
);
