import React, {useMemo} from 'react';
import {StyleSheet, Image, View, RefreshControl} from 'react-native';
import {observer} from 'mobx-react-lite';
import {ScrollView} from 'react-native-gesture-handler';
import {variance} from '../styling';
import AffiliateDetailBalance from '../components/organisms/banner/AffiliateBalanceBanner/AffiliateDetailBalance';
import {Typography} from '../components';
import {useStrings} from '../Root/hooks';
import {MD_BREAKPOINT} from '../WindowDimensions/useDimensions';

export type BonusProgramScreenProps = {
  goToAffiliate: () => void;
  balance: string;
  shareLink: () => void;
  isRefreshing: boolean;
  refresh: () => void;
};

export default observer(function BonusProgramScreen(
  props: BonusProgramScreenProps,
) {
  const {goToAffiliate, balance, shareLink, isRefreshing, refresh} = props;
  const strings = useStrings();
  const refreshControl = useMemo(
    () => <RefreshControl refreshing={isRefreshing} onRefresh={refresh} />,
    [isRefreshing, refresh],
  );
  return (
    <Container refreshControl={refreshControl}>
      <Wrapper>
        <ImageWrapper>
          <Image
            source={require('../assets/bonusProgram.png')}
            style={styles.image}
          />
        </ImageWrapper>
        <Description type="paragraph">
          {strings['bonusProgram.desc']}
        </Description>
        <AffiliateDetailBalance
          balance={balance}
          shareLink={shareLink}
          goToAffiliate={goToAffiliate}
        />
      </Wrapper>
    </Container>
  );
});

const styles = StyleSheet.create({
  image: {
    aspectRatio: 5 / 3,
    resizeMode: 'cover',
    maxWidth: 350,
    flexGrow: 1,
  },
});

const Description = variance(Typography)((theme) => ({
  root: {
    fontSize: 16,
    color: theme.colors.primaryUIDirtyBlue,
    textAlign: 'center',
    lineHeight: 24,
  },
}));
const Container = variance(ScrollView)(() => ({
  root: {
    flexGrow: 1,
  },
}));
const Wrapper = variance(View)((theme) => ({
  root: {
    flexDirection: 'column',
    paddingVertical: 24,
    paddingHorizontal: 16,
    gap: 24,
    ...theme.mediaQuery({
      [MD_BREAKPOINT]: {
        maxWidth: 500,
        marginHorizontal: 'auto',
      },
    }),
  },
}));
const ImageWrapper = variance(View)(() => ({
  root: {flex: 1, flexDirection: 'row', justifyContent: 'center'},
}));
