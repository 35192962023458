import {observer} from 'mobx-react-lite';
import CoinSvg from '../../../../assets/svg/colored/hashcoin.svg';
import {View, Text} from 'react-native';
import AffiliateCard from './AffiliateCard';
import {createStylesHook, variance} from '../../../../styling';
import React from 'react';
import Typography, {getMonoFontStyles} from '../../../Typography';
import {sized} from '../../../../Svg';
import {AppButton, ButtonVariant} from '../../../AppButton';
import ShareSvg from '../../../../assets/svg/colorless/share.svg';
import ArrowSvg from '../../../../assets/svg/colorless/arrowInSquare.svg';
import {useRoot, useStrings} from '../../../../Root/hooks';
import {AccountType} from '../../../../Auth';
import {expr} from 'mobx-utils';

export type AffiliateDetailBalanceProps = {
  balance: string;
  shareLink: () => void;
  goToAffiliate: () => void;
};
export default observer(function AffiliateDetailBalance(
  props: AffiliateDetailBalanceProps,
) {
  const {balance, shareLink, goToAffiliate} = props;
  const {auth} = useRoot();
  const styles = useStyles();
  const strings = useStrings();
  const isTemporary = expr(() => auth.accountType === AccountType.Temporary);
  return (
    <AffiliateCard>
      <TopContainer>
        <TitleWrapper>
          <Title type="body" weight="600">
            {strings['bonusBalance.banner.title']}
          </Title>
          <BalanceWrapper>
            <CoinIcon />
            <Balance
              numberOfLines={1}
              adjustsFontSizeToFit
              minimumFontScale={0.8}>
              {balance} HSH
            </Balance>
          </BalanceWrapper>
        </TitleWrapper>
        {isTemporary ? null : (
          <AppButton
            Icon={ArrowIcon}
            variant={ButtonVariant.White}
            onPress={goToAffiliate}
          />
        )}
      </TopContainer>
      <AppButton
        Icon={ShareIcon}
        variant={ButtonVariant.Primary}
        onPress={shareLink}
        style={styles.share}>
        {strings['bonusBalance.banner.shareButton']}
      </AppButton>
    </AffiliateCard>
  );
});

const CoinIcon = sized(CoinSvg, 24, 24);
const ShareIcon = sized(ShareSvg, 20, 20);
const ArrowIcon = sized(ArrowSvg, 20, 20);
const useStyles = createStylesHook(() => ({
  share: {
    flexGrow: 1,
    marginTop: 16,
  },
}));

const Title = variance(Typography)((theme) => ({
  root: {
    fontSize: 15,
    lineHeight: 20,
    color: theme.colors.primaryWhite,
  },
}));

const TitleWrapper = variance(View)(() => ({
  root: {
    gap: 8,
    flex: 1,
  },
}));
const BalanceWrapper = variance(View)(() => ({
  root: {flexDirection: 'row', alignItems: 'center', gap: 8},
}));
const Balance = variance(Text)((theme) => ({
  root: {
    ...getMonoFontStyles('700'),
    fontSize: 23,
    lineHeight: 24,
    color: theme.colors.primaryWhite,
    letterSpacing: -0.64,
  },
}));

const TopContainer = variance(View)(() => ({
  root: {
    justifyContent: 'space-between',
    gap: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
